import axios from 'axios';
import { getMeaningfulErrorMessage } from '../../redux/utils';

/** Fetch a single course from the server */
export const fetchCourseFromServer = async (payload) => {
  if (!payload.hasOwnProperty('id')) {
    throw new Error(
      'Missing Parameter: fetchCourseFromServer requires that the payload contain the <id> for the course you want to fetch'
    );
  }

  try {
    const response = await axios.get(`/api/courses/${payload.id}`);
    return response?.data;
  } catch (error) {
    throw new Error(getMeaningfulErrorMessage(error, 'Error fetching course'));
  }
};

// TODO add a function that allows users to fetch courses by location.
/** Fetch all courses from the server */
export const fetchCoursesFromServer = async () => {
  try {
    const response = await axios.get('/api/courses');
    return response?.data;
  } catch (error) {
    throw new Error(getMeaningfulErrorMessage(error, 'Error fetching courses'));
  }
};

/** Fetch all courses from the server that the user should be able to view */
export const fetchMyCoursesFromServer = async () => {
  try {
    const response = await axios.get('/api/mycourses');
    return response?.data;
  } catch (error) {
    throw new Error(getMeaningfulErrorMessage(error, 'Error fetching mycourses'));
  }
};

/** Fetch a single course session from the server */
export const fetchCourseSessionFromServer = async (payload) => {
  if (!payload.hasOwnProperty('courseId') || !payload.hasOwnProperty('courseSessionId')) {
    throw new Error(
      'Missing Parameter: fetchCourseSessionFromServer requires that the payload contain the <courseId> and the <courseSessionId> for the session you want to fetch'
    );
  }

  try {
    const response = await axios.get(
      `/api/courses/${payload.courseId}/sessions/${payload.courseSessionId}`
    );
    return response.data;
  } catch (error) {
    throw new Error(getMeaningfulErrorMessage(error, 'Error fetching course sessions'));
  }
};

/** Fetch a list of all of the course sessions for a given course.
 * Each courseSession in the response contains a list of all related
 * courseSessionEvents. */
export const fetchCourseSessionsFromServer = async (payload) => {
  if (!payload.hasOwnProperty('courseId')) {
    throw new Error(
      'Missing Parameter: fetchCourseSessionsFromServer requires that the payload contain the <courseId> for the sessions you want to fetch'
    );
  }

  try {
    const response = await axios.get(`/api/courses/${payload.courseId}/sessions`);
    return { data: response?.data, courseId: payload.courseId };
  } catch (error) {
    throw new Error(getMeaningfulErrorMessage(error, 'Error fetching course sessions'));
  }
};

/*** Fetch a list of all the course sessions that a student owns a ticket for */
export const fetchMyCourseSessionsFromServer = async () => {
  try {
    const response = await axios.get(`/api/mysessions`);
    return response?.data;
  } catch (error) {
    throw new Error(getMeaningfulErrorMessage(error, 'Error fetching my course sessions'));
  }
};

/** Each question in the response contains its id, order, text, and either:
 * a) the associated course
 * or b) the associated course session */
export const fetchPostCheckoutQuestionsFromServer = async (payload) => {
  if (!payload.hasOwnProperty('courseId')) {
    throw new Error(
      'Missing Parameter: fetchPostCheckoutQuestionsFromServer requires that the payload contain the <courseId> for the questions you want to fetch'
    );
  }

  try {
    const response = await axios.get(`/api/courses/${payload.courseId}/questions`);
    return response?.data;
  } catch (error) {
    throw new Error(getMeaningfulErrorMessage(error, 'Error fetching course questions'));
  }
};

/** Fetch course session materials from the server */
export const fetchCourseSessionMaterialsFromServer = async (payload) => {
  if (!payload.hasOwnProperty('id') || !payload.hasOwnProperty('email')) {
    throw new Error(
      'Missing Parameter: fetchCourseSessionMaterialsFromServer requires that the payload contain the materials <id> and an <email> that will be used to authenticate the request'
    );
  }

  try {
    const response = await axios.get(`/api/course-session-materials/${payload.id}`, {
      headers: { Authorization: payload.email },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      getMeaningfulErrorMessage(
        error,
        'Your request could not be completed. Please make sure the information you have entered is correct.'
      )
    );
  }
};
