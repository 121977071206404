import { toast } from 'react-toastify';
import { printDurationPretty } from '../assets/utils/utils';

// apiActionFactory(action, apiCall, errorAction) returns an action creator that
// will 1) dispatch <action>, 2) execute the <apiCall>, then 3) dispatch <errorAction>
// if the api call fails.
// The fourth, optional parameter, will change the order of these actions.
// If <blocking> is true, then the action creator will instead
// 1) execute the <apiCall>, then on success will 2a) dispatch <action>
// and on failure will 2b) dispatch <errorAction>
export const apiActionFactory = (
  action,
  apiCall,
  errorAction,
  blocking = false,
  showErrorToast = true
) => {
  if (!action || !apiCall || !errorAction) {
    throw new Error(
      'Improper use of `apiActionFactory`: <action> <apiCall>, and <errorAction> must be defined.'
    );
  }

  return (payload) => {
    return async (dispatch) => {
      try {
        // Dispatch the action before making the API call if <blocking> is set to false
        if (!blocking) {
          dispatch(action(payload));
        }

        // Executes the api call, and dispatches the <errorAction> in the case of failure.
        const data = await apiCall(payload);

        if (data) {
          // If <blocking> was set to true, then we need to dispatch the action now.
          if (blocking) {
            dispatch(action(data));
          }
          return data;
        }
        return null;
      } catch (error) {
        if (showErrorToast) {
          toast.error(error.message);
        }
        dispatch(errorAction({ message: error.message }));
      }
    };
  };
};

export const getMeaningfulErrorMessage = (error, defaultMessage) => {
  switch (error?.response?.status) {
    case 401:
      return 'Error: Unauthorized. You must be logged in to use this feature. Please refresh the page and try again.';
    case 403:
      return "Error: Forbidden. You don't have permission to carry out this action.";
    case 404:
      return '404 error. Resource not found.';
    case 429:
      const retryDuration = error.response.headers['retry-after'];
      const retryMessage = retryDuration
        ? `in ${printDurationPretty(parseInt(retryDuration) * 1000, false, false, true)}`
        : 'later';
      return `Too many requests. Please try again ${retryMessage}.`;
    case 500:
      return 'Error contacting the server. You may need to refresh the page to fetch the latest data.';
    case 501:
      return 'Error: This feature is not yet implemented.';
    case 408:
      return 'Error: Request timeout.';
    case 502:
    case 504:
      return 'Error: Bad gateway. Please try again.';
    case 503:
      return 'Error: This service is currently unavailable. Please try again in a moment.';
    default:
      return error.response?.data?.non_field_errors || error.response?.statusText || defaultMessage;
  }
};
