import axios from 'axios';
import { map } from 'lodash';
import { getMeaningfulErrorMessage } from '../../redux/utils';

// Fetch a single course from the server
export const obtainTicketLocksFromServer = async (payload) => {
  if (!payload.hasOwnProperty('ticketsInCart')) {
    throw new Error(
      'Missing Parameter: obtainTicketLocksFromServer requires that the payload contain a list of the <ticketsInCart>'
    );
  }

  try {
    // // Fake data for testing.
    // payload.ticketLocks = [
    //   { 'id': '2b879e8f-a3bd-4359-a5b3-bfda8cf6f8c4', 'courseSessionEventId': 112, 'dateCreated': '2020-11-01T05:29:16.513Z' },
    //   { 'id': '6763f965-8fae-45a4-b68c-6b51729f370c', 'courseSessionEventId': 118, 'dateCreated': '2020-11-01T05:29:16.493Z' },
    //   { 'id': '31a77939-e32c-4b99-ac30-bed4d64b11d4', 'courseSessionEventId': 116, 'dateCreated': '2020-11-01T05:29:16.496Z' },
    // ];
    const obtainTicketLockAPICalls = map(payload.ticketsInCart, async (ticket) => {
      return await axios.post(`/api/course-session-events/${ticket.courseSessionEventId}/lock`);
    });
    const obtainTicketLockResponses = await Promise.all(obtainTicketLockAPICalls);
    return map(obtainTicketLockResponses, (response, i) => {
      // Annotate the responses with the `subscription` boolean and the courseId.
      return {
        ...response.data,
        subscription: payload.ticketsInCart[i].subscription,
        course: payload.ticketsInCart[i].courseId,
      };
    });
  } catch (error) {
    // TODO If we have failed to obtain any of the ticket locks,
    // then we should probably release all of the ticket locks here.
    // (We'll probably have to use Promise.allSettled above.)
    throw new Error(getMeaningfulErrorMessage(error, 'Error obtaining tickets. Please try again.'));
  }
};

export const releaseTicketLocksFromServer = async (payload) => {
  //TODO: implement
  return payload;
};

export const fetchTicketsFromServer = async (payload) => {
  if (!payload?.hasOwnProperty('courseSessionEventId')) {
    throw new Error(
      'Missing Parameter: fetchTicketsFromServer requires that the payload contain a list of the <courseSessionEventId>'
    );
  }

  try {
    const response = await axios.get(
      `/api/course-session-events/${payload.courseSessionEventId}/tickets`
    );
    return response.data;
  } catch (error) {
    throw new Error(
      getMeaningfulErrorMessage(error, 'Error fetching ticket data. Please refresh the page.')
    );
  }
};

export const fetchPostCheckoutQuestionResponsesFromServer = async (payload) => {
  if (!payload?.hasOwnProperty('courseSessionEventId')) {
    throw new Error(
      'Missing Parameter: fetchPostCheckoutQuestionResponsesFromServer requires that the payload contain a list of the <courseSessionEventId>'
    );
  }

  try {
    const response = await axios.get(
      `/api/course-session-events/${payload.courseSessionEventId}/post-checkout-question-responses`
    );
    return response.data;
  } catch (error) {
    throw new Error(
      getMeaningfulErrorMessage(
        error,
        'Error fetching supplementary question responses. Please refresh the page.'
      )
    );
  }
};
