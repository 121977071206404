import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { toast } from 'react-toastify';
import { filter, find, forEach, isEmpty, keys, map, toInteger } from 'lodash';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPostCheckoutQuestions } from '../../../redux/features/courses/coursesSlice';
import { getPostCheckoutQuestions } from '../../../redux/features/courses/coursesSelector';
import {
  getCourseSessionsForPurchasedTickets,
  getRecursivelyFilteredCoursesForPurchasedTickets,
  getPostCheckoutQuestionsVerificationHash,
} from '../../../redux/features/cart/cartSelector';
import { trackCheckoutCompleteEvent } from '../../../assets/utils/GoogleAnalytics';
import { ErrorBoundary } from '@sentry/react';
import ErrorBoundaryFallback from '../../common/ErrorBoundaryFallback';
import styles from './PostCheckoutForm.module.scss';

const PostCheckoutForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [submittingAnswers, setSubmittingAnswers] = useState(false);
  const [postCheckoutQuestionsHaveBeenFetched, setPostCheckoutQuestionsHaveBeenFetched] = useState(
    false
  );
  const postCheckoutQuestions = useSelector(getPostCheckoutQuestions);
  const postCheckoutQuestionsVerificationHash = useSelector(
    getPostCheckoutQuestionsVerificationHash
  );
  const coursesPurchased = useSelector(getRecursivelyFilteredCoursesForPurchasedTickets);
  const courseSessionsPurchased = useSelector(getCourseSessionsForPurchasedTickets);

  useEffect(() => {
    // TODO for some reason this effect hook keeps looping.
    // postCheckoutQuestionsHaveBeenFetched is a temporary fix, but
    // I need to get to the root of the problem in case it's leading
    // to bugs elsewhere. (Idea: I think the bug may be occurring when
    // the payment doesn't go through, but idk why)
    if (!postCheckoutQuestionsHaveBeenFetched) {
      setPostCheckoutQuestionsHaveBeenFetched(true);
      forEach(coursesPurchased, (coursePurchased) => {
        dispatch(fetchPostCheckoutQuestions({ courseId: coursePurchased.id }));
      });
    }
  }, [coursesPurchased, postCheckoutQuestionsHaveBeenFetched, dispatch]);

  // Only show questions for sessions that the user is attending.
  const postCheckoutQuestionsFiltered = useMemo(() => {
    return filter(postCheckoutQuestions, (question) => {
      // If the question is intended for a specific course session, then
      // only show it if the user has purchased a ticket to that course session.
      if (question.courseSession) {
        return find(courseSessionsPurchased, ['id', question.courseSession]);
      }
      // Otherwise, only show it if the user has purchased a ticket to the course.
      return find(coursesPurchased, ['id', question.course]);
    });
  }, [postCheckoutQuestions, coursesPurchased, courseSessionsPurchased]);

  const postCheckoutQuestionsValidationSchema = useMemo(() => {
    // exampleSchema = {
    //   id1: Yup.string().trim(),
    //   id2: Yup.string().trim(),
    //   etc...
    // }
    const schema = {};
    forEach(postCheckoutQuestionsFiltered, (question) => {
      schema[question.id] = Yup.string().trim();
    });
    return schema;
  }, [postCheckoutQuestionsFiltered]);

  const initialFormValues = useMemo(() => {
    // initialFormValues = {
    //   id1: '',
    //   id2: '',
    //   etc...
    // }
    const initialValues = {};
    forEach(postCheckoutQuestionsFiltered, (question) => {
      initialValues[question.id] = '';
    });
    return initialValues;
  }, [postCheckoutQuestionsFiltered]);

  const submitAnswers = async (data) => {
    setSubmittingAnswers(true);

    const questionIds = keys(data);
    const questionResponses = map(questionIds, (questionId) => {
      return {
        question: toInteger(questionId),
        text: data[questionId],
      };
    });

    // Exclude blank responses.
    const questionResponsesFiltered = filter(questionResponses, 'text');

    // Make sure the user has answered at least one question.
    if (questionResponsesFiltered.length === 0) {
      toast.error('Please answer at least one question.');
      setSubmittingAnswers(false);
      return;
    }

    try {
      // Submit the answers to the server!
      await axios.post('/api/post-checkout-questions', {
        postCheckoutQuestionsVerificationHash,
        responses: questionResponsesFiltered,
      });
      history.push('/checkout/complete');
    } catch (error) {
      toast.error(error.response?.statusText || error.message);
      setSubmittingAnswers(false);
      return;
    }
  };

  return (
    <ErrorBoundary fallback={ErrorBoundaryFallback()}>
      <Row className="align-items-center mt-2">
        {/* <Col xs={2} sm={1} md={2} className={`text-right ${styles.exclamationIcon}`}>
        <FontAwesomeIcon icon={faExclamationCircle} className='mr-3' />
      </Col> */}
        <Col xs={12} className={`${styles.instructionsText} mt-2 mt-lg-4`}>
          {/* <FontAwesomeIcon icon={faExclamationCircle} className='mr-3' /> */}
          Please answer a few questions to help us get to know you better.
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {!isEmpty(initialFormValues) && (
            <Formik
              initialValues={initialFormValues}
              validationSchema={Yup.object().shape(postCheckoutQuestionsValidationSchema)}
              onSubmit={submitAnswers}
            >
              {({ errors, touched }) => (
                <Form>
                  {postCheckoutQuestionsFiltered.map((postCheckoutQuestion, i) => (
                    <div key={postCheckoutQuestion.id}>
                      <label
                        htmlFor={`post-checkout-question-input-${postCheckoutQuestion.id}`}
                        className={`${styles.postCheckoutQuestionLabel} mt-3`}
                      >
                        {i + 1}. {postCheckoutQuestion.text}
                      </label>
                      <Field
                        name={postCheckoutQuestion.id}
                        type="text"
                        id={`post-checkout-question-input-${postCheckoutQuestion.id}`}
                        className="form-control askme-input-terciary"
                        disabled={submittingAnswers}
                      />
                      {errors[postCheckoutQuestion.id] && touched[postCheckoutQuestion.id] && (
                        <div className="askme-input-error">{errors[postCheckoutQuestion.id]}</div>
                      )}
                    </div>
                  ))}
                  <div className="text-center w-100 mb-2">
                    <button
                      type="submit"
                      id="place-order-btn"
                      className={`${styles.submitAnswersBtn} askme-btn-terciary thick`}
                      disabled={submittingAnswers}
                      onClick={() => trackCheckoutCompleteEvent('Clicked Submit Answers')}
                    >
                      {submittingAnswers && (
                        <div className="spinner-border mr-2" role="status"></div>
                      )}
                      {submittingAnswers ? 'Submitting' : 'Submit Answers'}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </Col>
      </Row>
    </ErrorBoundary>
  );
};

export default PostCheckoutForm;
