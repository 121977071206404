import React, { useMemo } from 'react';
import { Breadcrumb, Col, Row } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import { find } from 'lodash';
import { useSelector } from 'react-redux';
import {
  getActiveCourse,
  getCourseSessionEventsForActiveCourse,
} from '../../../redux/features/courses/coursesSelector';
import { printDateTimePretty } from '../../../assets/utils/utils';
import styles from './DashboardBreadcrumbs.module.scss';

const DashboardBreadcrumbs = () => {
  const location = useLocation();
  const { activeCourseId, activeCourseSessionEventId } = useParams();
  const activeCourse = useSelector(getActiveCourse);
  const courseSessionEventsForActiveCourse = useSelector(getCourseSessionEventsForActiveCourse);

  const activeCourseSessionEventIdInt = useMemo(() => {
    return activeCourseSessionEventId ? parseInt(activeCourseSessionEventId) : undefined;
  }, [activeCourseSessionEventId]);

  const activeCourseSessionEvent = useMemo(() => {
    return find(courseSessionEventsForActiveCourse, ['id', activeCourseSessionEventIdInt]);
  }, [activeCourseSessionEventIdInt, courseSessionEventsForActiveCourse]);

  return (
    <Row>
      <Col xs={12}>
        <Breadcrumb className={styles.breadcrumb}>
          {/* All Pages */}
          {location.pathname.startsWith('/dashboard/courses') && (
            <Breadcrumb.Item
              active={!activeCourseId}
              linkAs={Link}
              linkProps={{ to: '/dashboard/courses' }}
            >
              Courses
            </Breadcrumb.Item>
          )}

          {/* Course Session Events Page & Course Session Event Detail Page */}
          {activeCourseId && (
            <>
              <Breadcrumb.Item
                active={!activeCourseSessionEventId}
                linkAs={Link}
                linkProps={{ to: `/dashboard/courses/${activeCourseId}` }}
              >
                {activeCourse?.name || activeCourseId}
              </Breadcrumb.Item>
            </>
          )}

          {/* Course Session Event Detail Page */}
          {activeCourseSessionEventId && (
            <>
              <Breadcrumb.Item active>
                {activeCourseSessionEvent?.courseSession?.name ? (
                  <>
                    {activeCourseSessionEvent.courseSession?.name} (
                    {printDateTimePretty(activeCourseSessionEvent.date)})
                  </>
                ) : (
                  activeCourseSessionEventId
                )}
              </Breadcrumb.Item>
            </>
          )}
        </Breadcrumb>
      </Col>
    </Row>
  );
};

export default DashboardBreadcrumbs;
