import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
// import { useHistory, useLocation } from 'react-router';
// import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getUser } from '../../redux/features/user/userSelector';
import { getTicketsInCart } from '../../redux/features/cart/cartSelector';
import { trackNavbarEvent } from '../../assets/utils/GoogleAnalytics';
import { executeOnEnter, scrollWithOffset } from '../../assets/utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { useNavbarIsFixed } from '../../assets/utils/CustomHooks';
import ModernImage, { getModernImageSrc } from '../common/ModernImage';
import UserDropdown from './navbar/UserDropdown';
import styles from './Navbar.module.scss';
import './Navbar.module.scss';
import { Button } from 'react-bootstrap';

const AskMeLogo = getModernImageSrc('./home/AskMeLogo', false);

const AskmeNavbar = ({ isAuthenticated }) => {
  // const dispatch = useDispatch();
  // const history = useHistory();
  // const location = useLocation();
  const currentUser = useSelector(getUser);
  const ticketsInCart = useSelector(getTicketsInCart);
  // const [isInitiatingCheckout, setIsInitiatingCheckout] = useState(false);
  const [isViewingUserDropdown, setIsViewingUserDropdown] = useState(false);
  const navbarIsFixed = useNavbarIsFixed();

  return (
    <>
      <nav data-testid="navbar" className={`${styles.container} ${navbarIsFixed && 'fixed-top'}`}>
        {/* Left side */}
        <Link className={styles.logoContainer} to="/index">
          <ModernImage
            className={styles.logoIcon}
            src={AskMeLogo}
            alt="The AskMe Logo"
            onClick={() => trackNavbarEvent('Clicked Logo Icon')}
          />
          <span className={styles.logoText} onClick={() => trackNavbarEvent('Clicked Logo Text')}>
            AskMe Learning
          </span>
        </Link>

        {/* Right side */}
        <div className={styles.rightSideContainer}>
          {/* Links */}
          <HashLink
            to={{ pathname: '/index', hash: '#our-values' }}
            scroll={scrollWithOffset()}
            className={`${styles.aboutBtn} d-inline-block ${
              ticketsInCart?.length > 0 && !isAuthenticated ? styles.removeOnTinyScreen : ''
            }`}
          >
            About
          </HashLink>

          {/* User Icon (if signed in) */}
          {isAuthenticated && (
            <span
              className={`${styles.userInfoContainer} ${ticketsInCart?.length > 0 && 'mr-4'}`}
              onClick={() => setIsViewingUserDropdown(true)}
              onKeyPress={(event) =>
                executeOnEnter(event, () => {
                  setIsViewingUserDropdown(!isViewingUserDropdown);
                })
              }
              tabIndex="0"
              aria-label="User dropdown menu"
            >
              {currentUser.firstName && (
                <span
                  className={styles.userName}
                  onClick={() => trackNavbarEvent('Clicked User Name')}
                >
                  {currentUser.firstName}
                </span>
              )}
              <FontAwesomeIcon
                className={styles.userIcon}
                onClick={() => trackNavbarEvent('Clicked User Icon')}
                icon={faUserCircle}
                aria-hidden={false}
              />
            </span>
          )}

          {/* Sign-in button */}
          {!isAuthenticated && true && (
            <Link to="/signin">
              <Button
                variant="outline-primary"
                id="signInButton"
                className={`${styles.signInButton}`}
              >
                Sign In
              </Button>
            </Link>
          )}

          {/* Cart Icon */}
          {ticketsInCart?.length > 0 && (
            <span
              className={`fa-layers fa-fw ${styles.cartIconContainer}`}
              onClick={() => {
                toast.info(
                  'To check out, please use the "Checkout" button on the individual Course pages.'
                );
                // Disabled for now. I think the Cart icon needs to actually go to
                // a dedicated "Cart" page. Or it needs to prompt users with a
                // modal that says "Go to the checkout page now?"
                // if (!isInitiatingCheckout && location.pathname !== '/checkout') {
                //   initiateCheckout(dispatch, history, ticketsInCart, setIsInitiatingCheckout);
                // }
              }}
            >
              <FontAwesomeIcon icon={faShoppingCart} className={styles.cartIcon} />
              <span className={`fa-layers-text ${styles.cartIconNumber}`}>
                {ticketsInCart.length}
              </span>
            </span>
          )}
        </div>
      </nav>
      {isViewingUserDropdown && <UserDropdown onSelect={() => setIsViewingUserDropdown(false)} />}
    </>
  );
};

export default AskmeNavbar;
