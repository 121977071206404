// Based on Jason Bellamy's npm package react-mailto: https://github.com/jasonbellamy/react-mailto
import React from 'react';

export const createMailToLink = (emailUser, emailHost) => {
  let link = `mailto:${emailUser}@${emailHost}`;
  return link;
};

function MailTo({ emailUser, emailHost, children, obfuscate = true, ...rest }) {
  function handleClick(event) {
    event.preventDefault();
    window.location.href = createMailToLink(emailUser, emailHost);
  }

  if (obfuscate) {
    // Email obfuscated
    return (
      <a onClick={handleClick} href="mailto:obfuscated" {...rest}>
        {children}
      </a>
    );
  }

  // Email not obfuscated
  return (
    <a href={createMailToLink(emailUser, emailHost)} {...rest}>
      {children}
    </a>
  );
}

export default MailTo;
