import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { first } from 'lodash';
import { useQueryParam, StringParam } from 'use-query-params';
import { getMeaningfulErrorMessage } from '../../redux/utils';
import { fetchCourseFromServer, fetchCourseSessionFromServer } from '../../api/courses/CoursesAPI';
import ErrorPage from './ErrorPage';
import DownloadMaterialsForm from './course-session-materials/DownloadMaterialsForm';
import styles from './CourseSessionMaterials.module.scss';

const CourseSessionMaterials = () => {
  const { activeCourseId, activeCourseSessionId } = useParams();
  const [courseSession, setCourseSession] = useState(null);
  const [course, setCourse] = useState(null);
  const [fetchCourseSessionError, setFetchCourseSessionError] = useState(false);
  const [urlQueryPwd] = useQueryParam('pwd', StringParam);

  useEffect(() => {
    async function fetchCourseSession(activeCourseId, activeCourseSessionId) {
      try {
        const responses = await Promise.all([
          fetchCourseFromServer({ id: activeCourseId }),
          fetchCourseSessionFromServer({
            courseId: activeCourseId,
            courseSessionId: activeCourseSessionId,
          }),
        ]);

        // First, check that the uuid provided in the URL query is correct.
        // TODO eventually, we might want to allow people to download multiple course material
        // PDFs rather than just link to a zipped file.
        const courseSessionMaterialsId = first(responses[1].courseSessionMaterials);
        if (urlQueryPwd !== courseSessionMaterialsId) {
          // Note that we don't actually show this error to the user.
          toast.error('Link verification failed. Please make sure this URL is correct.');
          setFetchCourseSessionError(true);
          return;
        }

        setCourse(responses[0]);
        setCourseSession(responses[1]);
      } catch (error) {
        setFetchCourseSessionError(true);
        toast.error(getMeaningfulErrorMessage(error, 'Error fetching course information.'));
      }
    }

    if (activeCourseId && activeCourseSessionId) {
      fetchCourseSession(activeCourseId, activeCourseSessionId);
    }
  }, [activeCourseId, activeCourseSessionId, urlQueryPwd]);

  return (
    <>
      <Helmet>
        <title>AskMe Learning | Materials</title>
      </Helmet>
      {courseSession && course ? (
        <Container fluid className={`min-height-page-container ${styles.container}`}>
          <Row as="header">
            <Col xs={12} md={{ span: 10, offset: 1 }}>
              <h1 className={styles.pageTitle}>Materials for "{courseSession.name}"</h1>
              <h2 className={styles.courseTitle}>{course.name}</h2>
            </Col>
          </Row>
          <Row as="main">
            <Col xs={12} md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
              <DownloadMaterialsForm
                courseSessionMaterialsId={first(courseSession.courseSessionMaterials)}
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          {fetchCourseSessionError ? (
            <ErrorPage message="Link verification failed." />
          ) : (
            <Container fluid className={`min-height-page-container ${styles.container}`}>
              <Row className={styles.loadingContainer}>
                <Col>
                  <h2>
                    <span
                      className={`spinner-border mr-3 ${styles.loadingSpinner}`}
                      role="status"
                    ></span>
                    Loading...
                  </h2>
                </Col>
              </Row>
            </Container>
          )}
        </>
      )}
    </>
  );
};

export default CourseSessionMaterials;
