import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import { filter, map, reduce, reject, round } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import QuestionIcon from '../../common/QuestionIcon';
import { trackCourseEvent } from '../../../assets/utils/GoogleAnalytics';
import { courseSessionEventIsOpen } from '../../../assets/utils/utils';
import { getTicketsInCart } from '../../../redux/features/cart/cartSelector';
import { addTicketToCart, removeTicketFromCart } from '../../../redux/features/cart/cartSlice';
import styles from './SubscribeOrBundleButtonGroup.module.scss';

const removeTicketsFromCart = (dispatch, ticketsToRemove) => {
  map(ticketsToRemove, (ticket) => {
    dispatch(removeTicketFromCart({ courseSessionEventId: ticket.courseSessionEventId }));
  });
};

/**
 * Adds all of the provided events to the cart.
 *
 * Note that this method won't remove any events from the cart,
 * so it can add events to the cart twice.
 * To add all events to the cart only once, first call `removeTicketsFromCart`.
 *
 * @param {*} dispatch
 * @param {*} eventsToAdd
 * @param {*} subscription
 */
const addEventsToCart = (dispatch, course, eventsToAdd, subscription = false) => {
  // Now, add all of the selected events into the cart.
  map(eventsToAdd, (event, i) => {
    if (courseSessionEventIsOpen(event)) {
      dispatch(
        addTicketToCart({
          courseId: course.id,
          courseSessionId: event.courseSession,
          courseSessionEventId: event.id,
          subscription,
        })
      );
    }
  });
};

/**
 * Checks to see if the user has tickets for all of the given course sessions.
 *
 * @param {*} course
 * @param {*} courseSessions
 * @param {*} tickets
 * @returns True if the list of tickets includes a ticket to each courseSession in courseSessions.
 */
const userHasTicketsToAllSessions = (course, courseSessions, tickets) => {
  // First, get the number of sessions in this course.
  const totalNumberOfSessions = courseSessions?.length;
  // Then get all of the sessions for which a ticket has been added
  // to the cart (excluding tickets that are part of a subscription).
  const sessionsAddedToCart = filter(tickets, (ticket) => {
    return ticket.courseId === course?.id;
  });
  const numberOfSessionsAddedToCart = sessionsAddedToCart?.length;

  return totalNumberOfSessions === numberOfSessionsAddedToCart;
};

export default function SubscribeOrBundleButtonGroup({
  course,
  courseSessions,
  selectedCourseSessionEvents,
  btnIsDisabled = false,
}) {
  const dispatch = useDispatch();
  const ticketsInCart = useSelector(getTicketsInCart);

  const ticketsInCartForThisCourse = useMemo(() => {
    return filter(ticketsInCart || [], ['courseId', course?.id]);
  }, [ticketsInCart, course]);

  const ticketsInCartForSubscriptions = useMemo(() => {
    return filter(ticketsInCartForThisCourse || [], 'subscription');
  }, [ticketsInCartForThisCourse]);

  const ticketsInCartExcludingSubscriptions = useMemo(() => {
    return reject(ticketsInCartForThisCourse || [], 'subscription');
  }, [ticketsInCartForThisCourse]);

  // Keep track of whether all selected events are
  // open so that we can either display or hide
  // the bundle button.
  const allSelectedEventsAreOpen = useMemo(() => {
    return reduce(
      selectedCourseSessionEvents,
      (acc, event) => acc && courseSessionEventIsOpen(event),
      true
    );
  }, [selectedCourseSessionEvents]);

  // Keep track of whether all events are added to the cart
  // as part of a subscription
  // so that we can display the correct version of the
  // `[Subscribe / Subscription added] for $50 / week` button.
  const courseSubscriptionIsAddedToCart = useMemo(() => {
    return userHasTicketsToAllSessions(course, courseSessions, ticketsInCartForSubscriptions);
  }, [course, courseSessions, ticketsInCartForSubscriptions]);

  // Keep track of whether all events are added to the cart
  // as individual or bundled purchases
  // so that we can display the correct version of the
  // `[Add / Added] all sessions to cart` button.
  const courseBundleIsAddedToCart = useMemo(() => {
    return userHasTicketsToAllSessions(course, courseSessions, ticketsInCartExcludingSubscriptions);
  }, [course, courseSessions, ticketsInCartExcludingSubscriptions]);

  // Hide the "Save by Bundling" button if not all selected events are open or
  // if there are fewer than two sessions.
  // Note that we do use a <Col> to add some vertical spacing between the
  // session cards and the Checkout button / etc.
  if (!allSelectedEventsAreOpen || !courseSessions?.length || courseSessions.length === 1) {
    // return <Col xs={12} className={styles.blankContainer}></Col>;
    return null;
  }

  return (
    <>
      {/* Only show the subscribe button if subscriptions are available */}
      {course?.subscriptionProducts?.length > 0 && (
        <>
          {/* Subscribe Button */}
          <Col xs={12} className={styles.subscribeContainer}>
            <h2 className="mt-1">
              Pay weekly
              <QuestionIcon content="Cancel any time after the first week!" />
            </h2>
          </Col>

          <Col xs={12} className="mt-3 text-center">
            {courseSubscriptionIsAddedToCart ? (
              <button
                type="button"
                className={`${styles.subscribeButton} askme-btn d-inline-block`}
                onClick={() => {
                  trackCourseEvent('Removed Subscription from Cart', course.name);
                  removeTicketsFromCart(dispatch, ticketsInCartForSubscriptions);
                }}
                disabled={btnIsDisabled}
              >
                <FontAwesomeIcon icon={faCheck} className="mr-3" />
                Added subscription to cart for $
                {round(parseFloat(course.subscriptionProducts[0].price))}/
                {course.subscriptionProducts[0].billingPeriod}
              </button>
            ) : (
              <button
                type="button"
                className={`${styles.subscribeButton} askme-btn-inverted d-inline-block`}
                onClick={() => {
                  trackCourseEvent('Added Subscription to Cart', course.name);
                  removeTicketsFromCart(dispatch, ticketsInCartForThisCourse);
                  addEventsToCart(dispatch, course, selectedCourseSessionEvents, true);
                }}
                disabled={btnIsDisabled}
              >
                <FontAwesomeIcon icon={faPlus} className="mr-3" />
                Add subscription to cart for $
                {round(parseFloat(course.subscriptionProducts[0].price))}/
                {course.subscriptionProducts[0].billingPeriod}
              </button>
            )}
          </Col>
        </>
      )}

      {/* Bundle Button */}
      <Col
        xs={12}
        className={`${styles.bundleContainer} ${
          !course?.subscriptionProducts?.length && styles.extraMarginTop
        }`}
      >
        <h2 className="mt-1">
          {course?.subscriptionProducts?.length > 0 ? 'Pay upfront and save' : 'Save by bundling'}
          <QuestionIcon content="Credit card companies charge a small fee for each transaction. If you bundle your ticket purchases, we'll pay less in fees, and we'll pass the savings on to you!" />
        </h2>
      </Col>

      <Col xs={12} className="mt-3 text-center">
        {courseBundleIsAddedToCart ? (
          <button
            type="button"
            className={`${styles.bundleButton} askme-btn d-inline-block`}
            onClick={() => {
              trackCourseEvent('Removed All Course Session Events from Cart', course.name);
              removeTicketsFromCart(dispatch, ticketsInCartExcludingSubscriptions);
            }}
            disabled={btnIsDisabled}
          >
            <FontAwesomeIcon icon={faCheck} className="mr-3" />
            Added {courseSessions.length === 2 ? 'both' : 'all'} sessions to cart for $
            {round(parseFloat(course?.bundlePrice))}
          </button>
        ) : (
          <button
            type="button"
            className={`${styles.bundleButton} askme-btn-inverted d-inline-block`}
            onClick={() => {
              trackCourseEvent('Added All Course Session Events to Cart', course.name);
              removeTicketsFromCart(dispatch, ticketsInCartForThisCourse);
              addEventsToCart(dispatch, course, selectedCourseSessionEvents);
            }}
            disabled={btnIsDisabled}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-3" />
            Add {courseSessions.length === 2 ? 'both' : 'all'} sessions to cart for $
            {round(parseFloat(course?.bundlePrice))}
          </button>
        )}
      </Col>
    </>
  );
}
