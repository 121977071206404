import React from 'react';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import styles from './CheckoutButton.module.scss';

export default function CheckoutButton({
  subscriptionButtonIsVisible,
  initiateCheckout,
  buttonIsDisabled,
}) {
  return (
    <Col xs={12} className={`mb-5 text-center ${subscriptionButtonIsVisible ? 'mt-5' : 'mt-4'}`}>
      <button
        className={`${styles.checkoutBtn} askme-btn-terciary thick d-inline-block mb-4`}
        onClick={initiateCheckout}
        disabled={buttonIsDisabled}
      >
        {buttonIsDisabled && <div className="spinner-border mr-3" role="status"></div>}
        Checkout
        <FontAwesomeIcon icon={faLongArrowAltRight} className="ml-3" />
      </button>
    </Col>
  );
}
