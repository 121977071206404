import { createSelector } from '@reduxjs/toolkit';
import { map, filter, find, flatMap } from 'lodash';
import { getCourses, getCourseSessions } from '../courses/coursesSelector';

const filterCoursesByTickets = (courses, tickets) =>
  filter(courses, (course) => {
    // Just filter out any courses that aren't in the cart.
    return find(tickets, (ticket) => ticket.courseId === course.id);
  });

/**
 * Recursively filters the Courses list so that only courses,
 * sessions, and events that are in the user's cart remain.
 *
 * Additionally, this function annotates each course with the
 * `purchasedViaSubscription` field, which will be True iff the
 * user is purchasing this Course as part of a subscription.
 */
const filterNestedCourseDataByTickets = (courses, tickets) =>
  map(courses, (course) => {
    // First, filter out sessions that aren't in the cart.
    const filteredCourseSessions = filter(course.courseSessions, (session) => {
      return find(tickets, (ticket) => ticket.courseSessionId === session.id);
    });

    // Then, filter out events that aren't in the cart.
    const filteredCourseSessionsWithFilteredEvents = map(filteredCourseSessions, (session) => {
      const filteredCourseSessionEvents = filter(session.courseSessionEvents, (event) => {
        return find(tickets, (ticket) => ticket.courseSessionEventId === event.id);
      });
      return { ...session, courseSessionEvents: filteredCourseSessionEvents };
    });

    // Then, check to see if this Course is being purchased via a subscription.
    // If it is, we'll annotate the returned course object.
    const firstTicketForCourse = find(tickets, ['courseId', course.id]);
    const purchasedViaSubscription = firstTicketForCourse && firstTicketForCourse.subscription;

    // Finally, return the filtered course data.
    return {
      ...course,
      courseSessions: filteredCourseSessionsWithFilteredEvents,
      purchasedViaSubscription,
    };
  });

export const getTicketsInCart = (state) => state.cart.tickets;
export const getTicketLocks = (state) => state.cart.ticketLocks;

export const getAppliedCoupons = (state) => state.cart.appliedCoupons || [];

export const getCheckoutFormData = (state) => state.cart.checkoutFormData;
export const getPostCheckoutQuestionsVerificationHash = (state) =>
  state.cart.postCheckoutQuestionsVerificationHash;
export const getPurchasedTickets = (state) => state.cart.purchasedTickets;

const getRawCoursesForTicketsInCart = createSelector(
  [getCourses, getTicketsInCart],
  filterCoursesByTickets
);

// This selector returns a list of all courses that the user has added to
// the cart. It also filters the nested `courseSessions` and `courseSessionEvents`
// so that they too contain only those course sessions which the user has added to
// the cart.
export const getFilteredCoursesForTicketsInCart = createSelector(
  [getRawCoursesForTicketsInCart, getTicketsInCart],
  filterNestedCourseDataByTickets
);

const getRawCoursesForPurchasedTickets = createSelector(
  [getCourses, getPurchasedTickets],
  filterCoursesByTickets
);

// This selector returns a list of all courses that the user has purchased.
// It also filters the nested `courseSessions` and `courseSessionEvents`
// so that they too contain only those course sessions which the user has purchased.
export const getRecursivelyFilteredCoursesForPurchasedTickets = createSelector(
  [getRawCoursesForPurchasedTickets, getPurchasedTickets],
  filterNestedCourseDataByTickets
);

export const getCourseSessionsForPurchasedTickets = createSelector(
  [getRecursivelyFilteredCoursesForPurchasedTickets, getCourseSessions],
  (coursesPurchased, courseSessions) => {
    // Get a list of all of the course sessions in the user's
    // cart. Note that since this list comes from the `courseSessions`
    // lists nested within the `courses` list, the resulting list
    // doesn't contain all of the course session information.
    const courseSessionsWithoutLocationInfo = flatMap(coursesPurchased, 'courseSessions');

    // Now, we need to get the full data for the course sessions, so we'll
    // filter the full `courseSessions` array so that it contains just
    // the course sessions that we obtained above.
    const courseSessionsWithLocationInfo = map(
      courseSessionsWithoutLocationInfo,
      (courseSessionWithoutLocationInfo) => {
        const fullCourseSessionObject = find(courseSessions, [
          'id',
          courseSessionWithoutLocationInfo.id,
        ]);
        return { ...courseSessionWithoutLocationInfo, ...fullCourseSessionObject };
      }
    );

    return courseSessionsWithLocationInfo;
  }
);
