import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ModernImage, { getModernImageSrc } from '../../common/ModernImage';
import styles from './Welcome.module.scss';
// import homeStyles from '../Home.module.scss';

// TODO We don't actually have permission to use this image.
// We either need to take a photo of Askey or commision
// a professional design.
const splashImage = getModernImageSrc('./home/');

// TODO add a picture (probably as a "position: relative;" element)
function Welcome() {
  return (
    <Row as="section" className={`${styles.container} align-items-center`}>
      <Col xs={12} md={{ span: 10, offset: 1 }}>
        <h1 className={styles.tagLine}>Personalized Learning For All</h1>
        <Row>
          <Col xs={12} md={8}>
            <p className={styles.elevatorPitch}>
              We provide creative, engaging, and personalized classes with award-winning teachers.
              Find your class today!
            </p>
          </Col>
        </Row>
        <ModernImage src={splashImage} />
      </Col>
    </Row>
  );
}

export default Welcome;
