import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './BrowseBySubjectBox.module.scss';

// TODO write a test for this function.
export function getEncodedString(query) {
  if (window) {
    return encodeURIComponent(query);
  }
  return '';
}

function BrowseBySubjectBox({ subject }) {
  if (!subject) return null;

  return (
    <Link
      to={
        subject.queryString
          ? `/courses?subject=${getEncodedString(subject.queryString)}`
          : '/courses'
      }
      className={`shadow ${styles.container}`}
    >
      <FontAwesomeIcon icon={[subject.iconStyle || 'fas', subject.icon]} className={styles.icon} />
      <span className={styles.subjectName}>{subject.name}</span>
    </Link>
  );
}

export default BrowseBySubjectBox;
