import React, { useMemo, useState } from 'react';
import { Button, Table, Modal, Form, Row, Col } from 'react-bootstrap';
import dashboardStyles from './DashboardStyles.module.scss';

const DEFAULT_HEADERS = [
  { id: 'firstName', text: 'First Name' },
  { id: 'lastName', text: 'Last Name' },
  { id: 'email', text: 'Email' },
];

const DEFAULT_HEADERS_AFTER_QUESTIONS = [
  {
    id: 'refund',
    text: 'Refund for this Session',
  },
];

const CourseSessionEventTable = ({ tickets, postCheckoutQuestions }) => {
  // Add the post-checkout questions to the list of headers.
  const headers = useMemo(() => {
    return [...DEFAULT_HEADERS, ...postCheckoutQuestions, ...DEFAULT_HEADERS_AFTER_QUESTIONS];
  }, [postCheckoutQuestions]);

  console.log('Headers: ', headers);

  const [showConfirmRefundMoal, setShowConfirmRefundMoal] = useState(false);
  const handleClose = () => setShowConfirmRefundMoal(false);
  const handleShow = () => setShowConfirmRefundMoal(true);
  const [ticketObjectToRefund, setTicketObjectToRefund] = useState({});

  const [currentValueOfConfirmInput, setCurrentValueOfConfirmInput] = useState('');

  function handleNameConfirmationInputChange(event) {
    console.log(event);

    setCurrentValueOfConfirmInput(event.target.value);

    console.log(event.target.value, getFullNameOfStudentBeingRefunded());
  }

  function doesConfirmInputMatchName() {
    return (
      currentValueOfConfirmInput.toLowerCase() === getFullNameOfStudentBeingRefunded().toLowerCase()
    );
  }

  function openRefundModalForTicketId(ticketId) {
    setTicketObjectToRefund(tickets.filter((e) => e.id === ticketId)[0] || {});
    setCurrentValueOfConfirmInput('');
    console.log(ticketObjectToRefund);
    setShowConfirmRefundMoal(true);
  }

  function getFullNameOfStudentBeingRefunded() {
    if (!!ticketObjectToRefund) {
      return ticketObjectToRefund.studentFirstName + ' ' + ticketObjectToRefund.studentLastName;
    } else {
      return 'ERROR';
    }
  }

  function handleSubmitRefundModal(event) {
    event.preventDefault();
    console.log('Handling submit');
    console.log(event.currentTarget);
    if (doesConfirmInputMatchName()) {
      alert('We would now issue a refund, if this was enabled');
      //TODO: Plug this into the backend refund route
    }
  }

  return (
    <>
      <Modal.Dialog
        centered
        className={`
          ${dashboardStyles.confirmModal} 
          ${showConfirmRefundMoal ? dashboardStyles.visible : dashboardStyles.hidden}
        `}
      >
        <Modal.Header closeButton onClick={handleClose}>
          <Modal.Title>Confirm Refund</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to refund{' '}
            <b>
              {getFullNameOfStudentBeingRefunded()}
              's
            </b>{' '}
            {/* The bracketed space is necessary here so that prettier 
            doesn't remove an 'unnecessary' space that we actually need */}
            ticket?
          </p>
          {/* </Modal.Body>
        <Modal.Footer> */}
          <p>
            Please type the student's name, {getFullNameOfStudentBeingRefunded() + ', '}
            to confirm
          </p>
          <Form onSubmit={handleSubmitRefundModal}>
            {/* <Row> */}
            {/* <Col xs="8" style={{ paddingRight: 0 }}> */}
            <Form.Text id="confirmRefundByNameInput" value={currentValueOfConfirmInput} />
            <Form.Control
              placeholder="Type the student's name to confirm"
              aria-label="Confirm student name"
              onChange={handleNameConfirmationInputChange}
              value={currentValueOfConfirmInput}
            />
            {/* </Col> */}
            {/* <Col xs="4"> */}
            <Button
              variant={`${!doesConfirmInputMatchName() ? 'outline-' : ''}primary`}
              type="submit"
              disabled={!doesConfirmInputMatchName()}
              id={dashboardStyles.refundModalSubmitButton}
              // onClick={handleSubmitRefundModal}
            >
              Issue Refund
            </Button>
            {/* <Button variant="secondary">Cancel</Button> */}
            {/* </Col> */}
            {/* </Row> */}
          </Form>
          {/* </Modal.Footer> */}
        </Modal.Body>
      </Modal.Dialog>
      <Table responsive className={dashboardStyles.table}>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={`course-session-event-table-header-${header.id}`}>{header.text}</th>
            ))}
          </tr>
        </thead>

        <tbody>
          {tickets ? (
            <>
              {tickets.map((ticket) => {
                return (
                  <tr key={`course-session-event-row-${ticket.id}`}>
                    {/* First Name */}
                    <td>{ticket.studentFirstName}</td>
                    {/* Last Name */}
                    <td>{ticket.studentLastName}</td>
                    {/* Email */}
                    <td>{ticket.email}</td>
                    {/* Question Responses */}
                    {postCheckoutQuestions.map((question) => {
                      return (
                        <td key={`post-checkout-question-response-${ticket.id}-${question.id}`}>
                          {ticket.postCheckoutQuestionResponseDictionary &&
                          ticket.postCheckoutQuestionResponseDictionary[question.id]
                            ? ticket.postCheckoutQuestionResponseDictionary[question.id].text || ''
                            : ''}
                        </td>
                      );
                    })}
                    {/* Refund Session Button */}
                    <td>
                      <Button onClick={() => openRefundModalForTicketId(ticket.id)}>
                        Refund (Currently Disabled)
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </>
          ) : (
            <tr>
              <td
                colSpan={headers.length}
                className={`py-4 text-center ${dashboardStyles.emptyRowContainer}`}
              >
                No tickets found for this event.
                {/* Added for spacing */}
                <div className="mt-1"></div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
};

export default CourseSessionEventTable;
