import React from 'react';
import ModernImage from '../../../common/ModernImage';
import styles from './WhoWeAreBio.module.scss';

function WhoWeAreBio({ name, title, photo, bio = '', className = '', ...props }) {
  if (!name || !photo) throw Error('WhoWeAreBio is missing a required attribute.');

  return (
    <div className={`${styles.container} shadow-sm ${className}`} {...props}>
      <ModernImage className={styles.photo} src={photo} roundedCircle />
      <div className={styles.textContainer}>
        <h3 className={`${styles.headline} ${!bio && 'mb-0'}`}>
          {name}
          <span className={styles.titleContainer}>{title && <>{title}</>}</span>
        </h3>
        {bio && <p className={styles.description}>{bio}</p>}
      </div>
    </div>
  );
}

export default WhoWeAreBio;
