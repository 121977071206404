import React, { useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { filter, forEach, reject, throttle } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import * as Sentry from '@sentry/react';
import { ReactComponent as StripeBadge } from '../../../assets/imgs/checkout/StripeBadgeBlurple.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import {
  setPostCheckoutQuestionsVerificationHash,
  markTicketsInCartAsPurchased,
  setCheckoutFormData,
  applyCoupon,
  clearCoupons,
} from '../../../redux/features/cart/cartSlice';
import {
  getAppliedCoupons,
  getFilteredCoursesForTicketsInCart,
  getTicketLocks,
} from '../../../redux/features/cart/cartSelector';
import { purchaseTicketsOutright, purchaseSubscriptions } from '../../../api/stripe/StripeAPI';
import { trackCheckoutEvent } from '../../../assets/utils/GoogleAnalytics';
import { ErrorBoundary } from '@sentry/react';
import ErrorBoundaryFallback from '../../common/ErrorBoundaryFallback';
import QuestionIcon from '../../common/QuestionIcon';
import styles from './CheckoutForm.module.scss';
import './Stripe.scss';
import axios from 'axios';

// Default test card: 4242424242424242
// 3D secure test card: 4000000000003220
const CARD_ELEMENT_OPTIONS = {
  // style: {
  //   base: {
  //     color: "#32325d",
  //     fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
  //     fontSmoothing: "antialiased",
  //     fontSize: "16px",
  //     "::placeholder": {
  //       color: "#aab7c4",
  //     },
  //   },
  //   invalid: {
  //     color: "#fa755a",
  //     iconColor: "#fa755a",
  //   },
  // },
};

const INITIAL_FORM_VALUES =
  process.env.NODE_ENV === 'development'
    ? {
      studentFirstName: 'John',
      studentLastName: 'Smith',
      parentEmail: 'example@askmetutoring.org',
    }
    : {
      studentFirstName: '',
      studentLastName: '',
      parentEmail: '',
    };

const trackTypedStudentFirstName = () => trackCheckoutEvent('Typed Student First Name');
const trackTypedStudentFirstNameThrottled = throttle(trackTypedStudentFirstName, 60000, {
  trailing: false,
});

const trackTypedStudentLastName = () => trackCheckoutEvent('Typed Student Last Name');
const trackTypedStudentLastNameThrottled = throttle(trackTypedStudentLastName, 60000, {
  trailing: false,
});

const trackTypedParentEmail = () => trackCheckoutEvent('Typed Parent Email');
const trackTypedParentEmailThrottled = throttle(trackTypedParentEmail, 60000, { trailing: false });

const trackTypedCardInformation = () => trackCheckoutEvent('Typed Card Information');
const trackTypedCardInformationThrottled = throttle(trackTypedCardInformation, 60000, {
  trailing: false,
});

/**
 * Returns the stripePriceIds for every course that the user is trying to subscribe to.
 *
 * @param {*} coursesInCart A list of the courses for which to return the subscription price IDs. Each course that is being purchased via a subscription should be annotated with the boolean `purchasedViaSubscription`.
 * @returns A map of courseIds to stripePriceIds.
 */
const getSubscriptionProductPriceIds = (coursesInCart) => {
  const coursesPurchasedViaSubscription = filter(coursesInCart, 'purchasedViaSubscription');
  const result = new Map();
  forEach(coursesPurchasedViaSubscription, (course) => {
    if (!course.subscriptionProducts?.length) {
      throw Error('Attempted to subscribe to a course that does not support subscriptions');
    }
    if (course.subscriptionProducts.length > 1) {
      throw Error(
        'More than one SubscriptionProduct exists for this Course. Please remove all but one of these SubscriptionProducts from the database, or update the code in `components/pages/checkout/CheckoutForm.js` so that it can handle multiple subscription products.'
      );
    }
    result.set(`${course.id}`, course.subscriptionProducts[0].stripePriceId);
  });
  return result;
};

const CheckoutForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const ticketLocks = useSelector(getTicketLocks);
  const appliedCoupons = useSelector(getAppliedCoupons);
  const coursesInCart = useSelector(getFilteredCoursesForTicketsInCart);
  const [placingOrder, setPlacingOrder] = useState(false);
  const [cardInputIsComplete, setCardInputIsComplete] = useState(false);

  function showCheckoutComplete(data) {

    data.appliedCoupons = appliedCoupons;
    console.log("Showing checkout complete with: ", data);
    // Set the parentEmail, studentFirstName, and studentLastName in Redux
    // so that the CheckoutComplete page has access to the user's info.
    dispatch(setCheckoutFormData(data));

    // Change the tickets from being `in the cart` to `purchased`.
    dispatch(markTicketsInCartAsPurchased());

    dispatch(clearCoupons())

    // Take the user to the order confirmation page.
    // There's a risk of the customer closing the window before callback
    // execution, so we need to set up a webhook on the server to listen for the
    // payment_intent.succeeded event. Upon receiving the event, the webhook
    // will update the database, etc...
    // TODO FIXME only add 'with-questions' if the course has been configured with questions.
    history.push('/checkout/complete/with-questions');
  }

  function tryApplyCoupon() {
    let couponCode = document.getElementById("couponCodeInput").value;
    console.log(couponCode);
    if (couponCode === "") return;
    axios.get("/api/coupons/verify/" + couponCode).then(response => {
      console.log(response);
      let alreadyApplied = false;
      for (let coupon of appliedCoupons) {
        if (coupon.code === couponCode) alreadyApplied = true;
      }
      if (alreadyApplied) {
        toast.warn("You have already applied this coupon")
      } else {
        // The API returns the coupon object in its response
        dispatch(applyCoupon(response.data.object))
        toast.success("Applied coupon " + couponCode)
        console.log(appliedCoupons);
      }

      document.getElementById("couponCodeInput").value = "";
    }).catch(errorResponse => {
      console.log(errorResponse);
      toast.error(errorResponse.response.data);
    })
  }

  const placeOrder = async (data) => {
    setPlacingOrder(true);

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet, so we'll disable
      // form submission until Stripe.js has loaded.
      toast.error('Error: Stripe has not yet loaded');
      setPlacingOrder(false);
      return;
    }

    if (!ticketLocks?.length) {
      toast.error(
        'Error: Tickets not found. Please re-add the tickets to your cart and try again.'
      );
      setPlacingOrder(false);
      return;
    }

    if (!cardInputIsComplete) {
      toast.error('Error: Card information is incomplete or invalid.');
      setPlacingOrder(false);
      return;
    }

    // Create a verification hash that we will use to submit
    // our responses to the post-checkout questions.
    const postCheckoutQuestionsVerificationHash = uuidv4();
    dispatch(setPostCheckoutQuestionsVerificationHash({ postCheckoutQuestionsVerificationHash }));

    // Grab the priceIds for the courses that the user is trying to subscribe to.
    // subscriptionProductPriceIds will be a map (courseId => priceId).
    const subscriptionProductPriceIds = getSubscriptionProductPriceIds(coursesInCart);
    const userIsPurchasingSubscription = subscriptionProductPriceIds.size > 0;

    // Then, get a reference to the mounted CardElement. Elements knows how
    // to find our CardElement because there can only ever be one of
    // each type of element.
    // We'll use the cardElement to create the PaymentMethod for subscriptions
    // and / or one-off payments.
    const cardElement = elements.getElement(CardElement);

    // Then, figure out which tickets the user is subscribing to / buying outright.
    const ticketLocksSubscribedTo = filter(ticketLocks, 'subscription');
    const ticketLocksPurchasedOutright = reject(ticketLocks, 'subscription');

    // Now, if the user is purchasing one or more subscriptions, let's execute that / those purchase(s).
    let paymentMethodForSubscriptions;
    let customer;
    if (userIsPurchasingSubscription) {
      try {
        [paymentMethodForSubscriptions, customer] = await purchaseSubscriptions(
          dispatch,
          stripe,
          cardElement,
          ticketLocksSubscribedTo,
          subscriptionProductPriceIds,
          appliedCoupons,
          data.parentEmail,
          data.studentFirstName,
          data.studentLastName,
          postCheckoutQuestionsVerificationHash
          // {
          //   card: cardElement,
          //   billing_details: {
          //     email: data.parentEmail,
          //   },
          // },
          // {
          //   email: data.parentEmail,
          //   studentFirstName: data.studentFirstName,
          //   studentLastName: data.studentLastName,
          //   postCheckoutQuestionsVerificationHash,
          // }
        );
      } catch (error) {
        toast.error(error.message);
        setPlacingOrder(false);
        return;
      }
    }

    // ---- Now, we're going to place one-off orders ----- //
    // (e.g. Course bundles & individual session tickets). //

    // First, let's either grab the payment method we used for subscriptions, or
    // (if we didn't create any subscriptions), define the payment method data now.
    const paymentMethodForNonSubscriptionTickets = paymentMethodForSubscriptions?.id || {
      card: cardElement,
      billing_details: {
        email: data.parentEmail,
      },
    };

    // If we don't need to buy any tickets outright, then show the checkout complete page!
    if (!ticketLocksPurchasedOutright.length) {
      showCheckoutComplete(data);
      return;
    }

    try {
      const result = await purchaseTicketsOutright(stripe, paymentMethodForNonSubscriptionTickets, {
        ticketLocks: ticketLocksPurchasedOutright,
        appliedCoupons,
        email: data.parentEmail,
        studentFirstName: data.studentFirstName,
        studentLastName: data.studentLastName,
        postCheckoutQuestionsVerificationHash,
        stripeCustomerId: customer?.id,
      });

      // If we've gotten here, then subscriptions & one-off payments both went through.
      // All payments have been processed!
      if (result.paymentIntent.status === 'succeeded') {
        showCheckoutComplete(data);
        return;
      }
    } catch (error) {
      if (userIsPurchasingSubscription) {
        // Let's send errors that happen between subscription and
        // one-off payments to Sentry so that admins can manually
        // create these payments & figure out what went wrong.
        Sentry.captureException(
          `Failed to execute a one-off payment after creating a subscription.\nError: ${error}\nEmail: ${data.parentEmail}\nStudent First Name: ${data.studentFirstName}\nStudent Last Name: ${data.studentLastName}\nTicket Locks: ${ticketLocksPurchasedOutright}`
        );
      }
      toast.error('Error: ' + error.message);
      setPlacingOrder(false);
      return;
    }

    // TODO figure out whether we'll every reach this block, and if so,
    // what sort of error message we need to show the user.
    setPlacingOrder(false);
    toast.error("Something didn't work. Please try again");
  };

  return (
    <ErrorBoundary fallback={ErrorBoundaryFallback()}>
      <Formik
        initialValues={INITIAL_FORM_VALUES}
        validationSchema={Yup.object().shape({
          studentFirstName: Yup.string().required('This field is required').trim(),
          studentLastName: Yup.string().required('This field is required').trim(),
          parentEmail: Yup.string()
            .email('Please enter a valid email address')
            .required('This field is required'),
        })}
        onSubmit={placeOrder}
      >
        {({ errors, touched, isSubmitting, isValidating }) => (
          <Form>
            <div>
              <h2 className="mb-1">Personal Information</h2>
            </div>

            <label htmlFor="student-first-name-input" className="mt-3">
              Student First Name
            </label>
            <Field
              name="studentFirstName"
              type="text"
              id="student-first-name-input"
              className="form-control askme-input-terciary"
              disabled={placingOrder}
              onInput={trackTypedStudentFirstNameThrottled}
            />
            {errors.studentFirstName && touched.studentFirstName && (
              <div className="askme-input-error">{errors.studentFirstName}</div>
            )}

            <label htmlFor="student-last-name-input" className="mt-3">
              Student Last Name
            </label>
            <Field
              name="studentLastName"
              type="text"
              id="student-last-name-input"
              className="form-control askme-input-terciary"
              disabled={placingOrder}
              onInput={trackTypedStudentLastNameThrottled}
            />
            {errors.studentLastName && touched.studentLastName && (
              <div className="askme-input-error">{errors.studentLastName}</div>
            )}

            <label htmlFor="email-input" className="mt-3">
              Email Address
              <QuestionIcon content="We'll send the order confirmation and session instructions to this email address." />
            </label>
            <Field
              name="parentEmail"
              type="email"
              id="email-input"
              className="form-control askme-input-terciary"
              disabled={placingOrder}
              onInput={trackTypedParentEmailThrottled}
            />
            {errors.parentEmail && touched.parentEmail && (
              <div className="askme-input-error">{errors.parentEmail}</div>
            )}

            <h2 className="mt-3">Coupon Code</h2>
            <Row>
              <Col xs={8} sm={9}>
                <Field
                  type="text"
                  name="couponCode"
                  placeholder="Coupon Code"
                  className="form-control askme-input-terciary"
                  id="couponCodeInput"
                  onKeyDown={(keyEvent) => {
                    if (keyEvent.keyCode === 13) {
                      keyEvent.preventDefault()
                      tryApplyCoupon()
                    }
                  }}
                >
                </Field>
              </Col>
              <Col xs={3} sm={2} className="ml-1">
                <Button className={`float-right ml-1 ${styles.applyButton}`} variant="outline-primary" onClick={tryApplyCoupon}>
                  Apply
                  </Button>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col xs={12}>
                <h2 className="mt-4">
                  <span className={`${styles.paymentHeader} float-left`}>
                    Payment
                    {process.env.NODE_ENV === 'development' && (
                      <FontAwesomeIcon
                        icon={faCopy}
                        className={`${styles.copyIcon} cursor-pointer ml-3`}
                        onClick={() => {
                          copy('4242424242424242');
                          toast.info('"4242424242424242" copied to clipboard!');
                        }}
                        title={
                          'Copy a test card number to the clipboard (this icon is disabled in production)'
                        }
                      />
                    )}
                    {/* <FontAwesomeIcon icon={faLock} className={`${styles.lockIcon} ml-3`} /> */}
                  </span>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://stripe.com/"
                    className="clear-link-styling"
                  >
                    <StripeBadge className={`${styles.stripeBadge} float-right`} />
                  </a>
                </h2>
              </Col>
            </Row>
            <CardElement
              className="askme-input-terciary"
              options={CARD_ELEMENT_OPTIONS}
              onChange={({ complete }) => {
                setCardInputIsComplete(complete);
                trackTypedCardInformationThrottled();
              }}
            />

            <div className="text-center w-100">
              <button
                type="submit"
                id="place-order-btn"
                className={`${styles.placeOrderBtn} askme-btn-terciary thick`}
                disabled={placingOrder || !stripe}
                onClick={() => trackCheckoutEvent('Clicked Place Order')}
              >
                {placingOrder && <div className="spinner-border mr-2" role="status"></div>}
                {placingOrder ? 'Placing Order' : 'Place Order'}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ErrorBoundary>
  );
};

export default CheckoutForm;
