import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './FastFact.module.scss';

function FastFact({ icon, number, unit, className = '', ...props }) {
  if (!icon || !number || !unit) throw Error('FastFact is missing a required attribute.');

  return (
    <div className={`${styles.container} ${className}`} {...props}>
      <div className={styles.iconContainer}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <h4 className={styles.factContainer}>
        <span className={styles.number}>{number}</span> <span className={styles.unit}>{unit}</span>
      </h4>
    </div>
  );
}

export default FastFact;
