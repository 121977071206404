import { createSlice } from '@reduxjs/toolkit';
import { apiActionFactory } from '../../utils';
import {
  fetchTicketsFromServer,
  fetchPostCheckoutQuestionResponsesFromServer,
} from '../../../api/tickets/TicketsAPI';

/** Keeps track of purchased tickets for the purposes of the admin dashboard.
 *
 * For cart ticket management, see `cart/cartSlice.js`
 */
const ticketsSlice = createSlice({
  name: 'tickets',
  initialState: {
    all: [],
    postCheckoutQuestionResponses: [],
  },
  reducers: {
    fetchTickets(state, action) {
      state.fetchTicketsError = false;
      // TODO allow the application to load tickets for multiple events
      // simultaneously so that the user doesn't have to wait if they're
      // clicking back and forth between dashboard pages.
      state.all = action.payload;
    },
    fetchTicketsFailure(state) {
      state.fetchTicketsError = true;
    },
    fetchPostCheckoutQuestionResponses(state, action) {
      state.fetchPostCheckoutQuestionResponsesError = false;
      // TODO allow the application to load PostCheckoutQuestionResponses for multiple events
      // simultaneously so that the user doesn't have to wait if they're
      // clicking back and forth between dashboard pages.
      state.postCheckoutQuestionResponses = action.payload;
    },
    fetchPostCheckoutQuestionResponsesFailure(state) {
      state.fetchPostCheckoutQuestionResponsesError = true;
    },
  },
});

export const fetchTickets = apiActionFactory(
  ticketsSlice.actions.fetchTickets,
  fetchTicketsFromServer,
  ticketsSlice.actions.fetchTicketsFailure,
  true
);

export const fetchPostCheckoutQuestionResponses = apiActionFactory(
  ticketsSlice.actions.fetchPostCheckoutQuestionResponses,
  fetchPostCheckoutQuestionResponsesFromServer,
  ticketsSlice.actions.fetchPostCheckoutQuestionResponsesFailure,
  true
);

export default ticketsSlice.reducer;
