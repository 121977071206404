import React from 'react';
import { Row, Col } from 'react-bootstrap';
import BrowseBySubjectBox from './browse-by-subject/BrowseBySubjectBox';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faBalanceScale, faGuitar, faFileAlt, faFrog } from '@fortawesome/free-solid-svg-icons';
import { COURSE_SUBJECTS } from '../../../assets/constants/constants';
import homeStyles from '../Home.module.scss';
import styles from './BrowseBySubject.module.scss';

// Make the font awesome icons we're using for the subject boxes
// available globally.
// TODO write a test to ensure that all of the icons we need for
// the subject boxes are here.
library.add(faBalanceScale, faGuitar, faFileAlt, faFrog);

function BrowseBySubject() {
  return (
    <Row
      as="section"
      id="browse-by-subject"
      className={`${homeStyles.sectionContainer} ${styles.container}`}
    >
      <Col xs={12} md={{ span: 10, offset: 1 }}>
        <h2 className={`${homeStyles.sectionHeader} ${styles.header}`}>Browse by subject</h2>
        <Row>
          {COURSE_SUBJECTS.slice(0, 4).map((subject) => (
            <Col xs={6} md={4} xl={3} key={subject.id}>
              <BrowseBySubjectBox subject={subject} />
            </Col>
          ))}
        </Row>
      </Col>
    </Row>
  );
}

export default BrowseBySubject;
