import React, { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Col, Row, Container } from 'react-bootstrap';
import { differenceBy, filter, flatMap, maxBy } from 'lodash';
import { DateTime } from 'luxon';
import { useQueryParam, ArrayParam } from 'use-query-params';
import { COURSE_SUBJECTS } from '../../assets/constants/constants';
import { getDateTimeFromMillisOrISOString } from '../../assets/utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses } from '../../redux/features/courses/coursesSlice';
import {
  getCourses,
  getPublishedCourses,
  getUnpublishedCourses,
} from '../../redux/features/courses/coursesSelector';
import { getUserIsAdmin } from '../../redux/features/user/userSelector';
import SubjectFilterBadge from './courses/SubjectFilterBadge';
import CourseCard from './courses/CourseCard';
import styles from './Courses.module.scss';

export const courseIsUpcoming = (lastEventDate) => {
  return lastEventDate > DateTime.utc();
};

const selectCoursesBySubject = (courses, subjects) => {
  if (!subjects?.length) {
    return courses;
  }

  // Create a set containing all of the subjects
  const subjectsSet = new Set(subjects);

  return filter(courses, (course) => {
    // TODO remove `course.subject === 'unspecified'` once I've deployed this code and specified subjects for existing courses.
    return subjectsSet.has(course.subject) || course.subject === 'unspecified';
  });
};

const selectUpcomingCourses = (courses) => {
  return filter(courses, (course) => {
    // First, grab all of this course's events.
    const courseSessionEvents = flatMap(course.courseSessions, 'courseSessionEvents');

    // Then, find the event that happens last.
    const lastEvent = maxBy(courseSessionEvents, 'date');

    // This course is an "upcoming course" if the last event
    // takes place at some point in the future.
    // Note that we also count courses with no events as "upcoming," because
    // they are likely in the process of being created.
    return !lastEvent || courseIsUpcoming(getDateTimeFromMillisOrISOString(lastEvent.date));
  });
};

const COURSE_SUBJECTS_WITH_ALL = [
  {
    id: -1,
    name: 'All',
    queryString: 'all',
  },
  ...COURSE_SUBJECTS,
];

const Courses = () => {
  const dispatch = useDispatch();
  const userIsAdmin = useSelector(getUserIsAdmin);
  const courses = useSelector(getCourses);
  const publishedCourses = useSelector(getPublishedCourses);
  const unpublishedCourses = useSelector(getUnpublishedCourses);
  const [loading, setLoading] = useState(true);
  const [subjects] = useQueryParam('subject', ArrayParam);

  //// UNPUBLISHED COURSES ////
  const unpublishedCoursesFilteredBySubject = useMemo(() => {
    return selectCoursesBySubject(unpublishedCourses, subjects);
  }, [unpublishedCourses, subjects]);

  const upcomingUnpublishedCourses = useMemo(() => {
    return selectUpcomingCourses(unpublishedCoursesFilteredBySubject);
  }, [unpublishedCoursesFilteredBySubject]);

  const upcomingUnpublishedCoursesWithMessage = useMemo(() => {
    return filter(upcomingUnpublishedCourses, 'prePublicationMessage');
  }, [upcomingUnpublishedCourses]);

  //// PUBLISHED COURSES ////
  const publishedCoursesFilteredBySubject = useMemo(() => {
    return selectCoursesBySubject(publishedCourses, subjects);
  }, [publishedCourses, subjects]);

  const upcomingPublishedCourses = useMemo(() => {
    return selectUpcomingCourses(publishedCoursesFilteredBySubject);
  }, [publishedCoursesFilteredBySubject]);

  const pastPublishedCourses = useMemo(() => {
    return differenceBy(publishedCoursesFilteredBySubject, upcomingPublishedCourses, 'id');
  }, [publishedCoursesFilteredBySubject, upcomingPublishedCourses]);

  useEffect(() => {
    const doFetchCourses = async () => {
      setLoading(true);
      await dispatch(fetchCourses());
      setLoading(false);
    };

    doFetchCourses();
  }, [dispatch]);

  return (
    <>
      <Container className={`min-height-page-container pb-lg-5 ${styles.container}`}>
        <Helmet>
          <title>AskMe Learning | Courses</title>
        </Helmet>
        <Row className="mt-2 mt-sm-4 mt-lg-5 mb-5">
          <Col xs={12} className={`mb-2 ${styles.header}`}>
            <Row>
              <Col
                xs={12}
                xl="auto"
                className="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <h1 className={`${styles.headerText} main-header`}>Upcoming Courses</h1>
              </Col>
              <Col
                xs={12}
                xl="auto"
                className={`${styles.subjectBadgesContainer} d-flex align-items-center mt-2 mt-xl-0`}
              >
                {COURSE_SUBJECTS_WITH_ALL.map((subject) => (
                  <SubjectFilterBadge
                    key={subject.id}
                    name={subject.name}
                    queryString={subject.queryString}
                  />
                ))}
                {/* Add space after the last badge so that it doesn't touch the edge
                    when the user tries to scroll all the way to the right */}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </Col>
            </Row>
          </Col>

          {/* Upcoming Courses */}
          {upcomingPublishedCourses?.length > 0 && (
            <>
              {upcomingPublishedCourses.map((course) => (
                <CourseCard key={`course-card-${course.id}`} course={course} />
              ))}
            </>
          )}

          {/* Upcoming Courses */}
          {userIsAdmin && upcomingUnpublishedCourses?.length > 0 && (
            <>
              {upcomingUnpublishedCourses.map((course) => (
                <CourseCard key={`course-card-${course.id}`} course={course} unpublished={true} />
              ))}
            </>
          )}

          {/* This blank course card will just show a loading skeleton while
            the data is loading. */}
          {!courses?.length && loading && <CourseCard />}

          {/* Show an error message if the filter is too strict and we couldn't
            find any upcoming published courses in the given subject */}
          {courses?.length > 0 && !upcomingPublishedCourses?.length && !loading && (
            <Col xs={12} className={styles.noCoursesMessage}>
              {upcomingUnpublishedCoursesWithMessage?.length > 0 ? (
                <>
                  {/* Show a preview message for courses that are going to be published soon. */}
                  {upcomingUnpublishedCoursesWithMessage.map((course) => (
                    <div key={course.id}>{course.prePublicationMessage}</div>
                  ))}
                </>
              ) : (
                <>
                  {/* Otherwise, just show a generic error message */}
                  <p>
                    No upcoming courses were found for {subjects?.length > 1 ? 'these' : 'this'}{' '}
                    subject{subjects?.length > 1 && 's'}.
                  </p>
                  <p>Try expanding your search criteria, or check back later!</p>
                </>
              )}
            </Col>
          )}

          {/* Past Courses */}
          {pastPublishedCourses?.length > 0 && (
            <>
              <Col
                xs={12}
                className={`mb-2 ${(upcomingPublishedCourses?.length || loading) && 'mt-5'}`}
              >
                <h2 className={`${styles.pastCoursesHeaderText}`}>Past Courses</h2>
              </Col>
              {pastPublishedCourses.map((course) => (
                <CourseCard key={`course-card-${course.id}`} course={course} />
              ))}
            </>
          )}
        </Row>
      </Container>
    </>
  );
};

export default Courses;
