import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCalendarAlt, faTag, faClock } from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import Select from 'react-select';
import { find } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getTicketsInCart } from '../../../redux/features/cart/cartSelector';
import { removeTicketFromCart } from '../../../redux/features/cart/cartSlice';
import { ASKME_REACT_SELECT_THEME } from '../../../assets/constants/constants';
import {
  printDateTimePretty,
  getDateTimeFromMillisOrISOString,
  courseSessionEventIsOpen,
} from '../../../assets/utils/utils';
import { DateTime } from 'luxon';
import { ErrorBoundary } from '@sentry/react';
import ErrorBoundaryFallback from '../../common/ErrorBoundaryFallback';
import Video from '../../common/Video';
import ReadMore from '../../common/ReadMore';
import { trackCourseEvent } from '../../../assets/utils/GoogleAnalytics';
import AddToCartButton from './session-card/AddToCartButton';
import styles from './SessionCard.module.scss';

const getEventAsOption = (sessionEvent) => {
  return {
    value: sessionEvent,
    label: printDateTimePretty(sessionEvent?.date || 0),
  };
};

// TODO add full support for the `compact` parameter.
/**
 * @param compact If `compact` is set to `true`, this session card will maintain a column width of 4,
 *                but will show the location, date, etc... in a much more compact manner. Note that `compact`
 *                takes precedence over `extraWide`.
 * @param extraWide If `extraWide` is set to `true`, this session card will maintain a column width
 *                of 6 even on larger screens (instead of 4). Note that `compact` takes precedence over `extraWide`.
 *  */
const SessionCard = ({
  session,
  className,
  onEventSelected,
  selectedEvent,
  compact = false,
  extraWide = false,
  ...props
}) => {
  const dispatch = useDispatch();
  const ticketsInCart = useSelector(getTicketsInCart);

  // Update dateOptions iff session.courseSessionEvents changes.
  const dateOptions = useMemo(() => {
    if (!session?.courseSessionEvents?.length) return [];
    return session.courseSessionEvents?.map(getEventAsOption);
  }, [session?.courseSessionEvents]);

  const selectedEventIsInCart = useMemo(() => {
    return !!find(ticketsInCart, ['courseSessionEventId', selectedEvent?.id]);
  }, [ticketsInCart, selectedEvent]);

  const selectedEventHasAlreadyHappened = useMemo(() => {
    if (!selectedEvent) return false;
    return getDateTimeFromMillisOrISOString(selectedEvent.date) < DateTime.utc();
  }, [selectedEvent]);

  useEffect(() => {
    if (selectedEventIsInCart && selectedEvent && !courseSessionEventIsOpen(selectedEvent)) {
      dispatch(removeTicketFromCart({ courseSessionEventId: selectedEvent.id }));
    }
  }, [selectedEvent, selectedEventIsInCart, dispatch]);

  return (
    <ErrorBoundary fallback={ErrorBoundaryFallback()}>
      <Col
        xs={12}
        md={6}
        lg={extraWide ? 6 : 4}
        className={`${styles.cardContainer} mt-3 mt-lg-4 mt-xl-5 ${className} ${
          compact && 'equal-height'
        }`}
        {...props}
      >
        <Row className={`${styles.card} px-3 py-4 shadow`}>
          <Col xs={12}>
            <h4 className={compact ? styles.compactSessionNumber : undefined}>
              {session ? <>Session {session.displayOrder + 1}</> : <Skeleton />}
            </h4>
          </Col>
          <Col xs={12}>
            <h3 className={compact ? styles.compactSessionName : undefined}>
              {session?.name || <Skeleton />}
            </h3>
          </Col>
          {session?.youtubeVideoId && (
            <Col xs={12} className="mt-3">
              <Video
                youtubeVideoId={session.youtubeVideoId}
                onPlay={() => {
                  trackCourseEvent('Played Course Session Video', session.name);
                }}
              />
            </Col>
          )}
          {session?.description && (
            <Col xs={12} className={session?.youtubeVideoId ? 'mt-4' : 'mb-2'}>
              <ReadMore text={session.description} length={200} />
            </Col>
          )}
          {compact ? (
            <>
              {(session.zoomLink || session.location) && (
                <Col xs="auto" className={`${styles.compactInfo} mt-2 h5`}>
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-3" />
                  {session.zoomLink ? 'Zoom' : session.location}
                </Col>
              )}
              <Col xs="auto" className={`${styles.compactInfo} mt-2 h5`}>
                <FontAwesomeIcon icon={faClock} className="mr-3" />
                {session.length}
              </Col>
              {!selectedEventHasAlreadyHappened && session?.price && (
                <Col xs="auto" className={`${styles.compactInfo} mt-2 h5`}>
                  <FontAwesomeIcon icon={faTag} className="mr-3" />${session.price}
                </Col>
              )}
              <Col xs="auto" className={`${styles.compactInfo} mt-2 h5`}>
                <FontAwesomeIcon icon={faCalendarAlt} className="mr-3" />
                {printDateTimePretty(selectedEvent?.date)}
              </Col>
            </>
          ) : (
            <>
              <Col xs={12} className="mt-3 h5">
                {session ? (
                  <>
                    <span>
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-3" />
                      Location:
                    </span>
                    <span className="ml-2">{session.zoomLink ? 'Zoom' : session.location}</span>
                  </>
                ) : (
                  <Skeleton />
                )}
              </Col>
              <Col xs={12} className="mt-2 h5">
                {session ? (
                  <>
                    <span>
                      <FontAwesomeIcon icon={faClock} className="mr-3" />
                      Length:
                    </span>
                    <span className="ml-2">{session.length}</span>
                  </>
                ) : (
                  <Skeleton />
                )}
              </Col>
              {!selectedEventHasAlreadyHappened && session?.price && (
                <Col xs={12} className="mt-2 h5">
                  <span>
                    <FontAwesomeIcon icon={faTag} className="mr-3" />
                    Price:
                  </span>
                  <span className="ml-2">${session.price}</span>
                </Col>
              )}
              <Col xs={12} className={`${styles.dateSelectorLabel} mt-2 h6`}>
                {session ? (
                  <>
                    <FontAwesomeIcon icon={faCalendarAlt} className="mr-3" />
                    Select date:
                  </>
                ) : (
                  <Skeleton />
                )}
              </Col>
              <Col xs={12} className="mt-2">
                {session ? (
                  <Select
                    placeholder="Select date..."
                    value={getEventAsOption(selectedEvent)}
                    isSearchable={false}
                    onChange={(option) => {
                      trackCourseEvent('Selected Course Session Event', option.value.id);
                      onEventSelected(option.value);
                    }}
                    // FIXME fix the theme's colors
                    theme={ASKME_REACT_SELECT_THEME}
                    className={styles.dateSelector}
                    options={dateOptions}
                  />
                ) : (
                  <Skeleton height={38} />
                )}
              </Col>
            </>
          )}
          {selectedEventHasAlreadyHappened && session && (
            <Col xs={12} className="mt-3 mb-2 text-center">
              <strong className="color-secondary">This session has already taken place.</strong>
            </Col>
          )}
          {((selectedEvent && !selectedEventHasAlreadyHappened) || !session) && (
            <>
              <Col
                xs={12}
                className={`${styles.availabilityLabel} mt-3 h6 text-center color-terciary font-weight-bold`}
              >
                {session ? (
                  <>
                    {selectedEvent?.ticketsAvailable} of {selectedEvent?.ticketsCap} spots available
                  </>
                ) : (
                  <Skeleton />
                )}
              </Col>
              <AddToCartButton
                session={session}
                selectedEvent={selectedEvent}
                selectedEventIsInCart={selectedEventIsInCart}
              />
            </>
          )}
          {/* This div fills up any remaining space so that if the cards are set to be equal-height,
              the content inside won't spread out to fill the space. */}
          <div className={styles.fillerDiv}></div>
        </Row>
      </Col>
    </ErrorBoundary>
  );
};

export default SessionCard;
