import axios from 'axios';
import { getMeaningfulErrorMessage } from '../../redux/utils';

export const createCustomerOnServer = async (payload) => {
  if (!payload.hasOwnProperty('email')) {
    throw new Error(
      "Missing Parameter: createCustomerOnServer requires that the payload contain the customer's <email>"
    );
  }

  try {
    const response = await axios.post('/api/customers', payload);
    return response.data;
  } catch (error) {
    throw new Error(
      getMeaningfulErrorMessage(error, 'Error creating Customer via Stripe. Please try again.')
    );
  }
};
