import React, { useMemo } from 'react';
import { find, first, floor, forEach } from 'lodash';
import { Row, Col } from 'react-bootstrap';
import { ErrorBoundary } from '@sentry/react';
import ErrorBoundaryFallback from '../../common/ErrorBoundaryFallback';
import CheckoutReceiptCourseItem from './CheckoutReceiptCourseItem';
import QuestionIcon from '../../common/QuestionIcon';
import styles from './CheckoutReceipt.module.scss';

// Computes the price of all of the items in the cart, before any
// bundle discounts, taxes, etc...
const getPriceSubtotal = (coursesFiltered) => {
  if (!coursesFiltered?.length) {
    return 0;
  }

  return coursesFiltered.reduce((sum, course) => {
    // Get the raw total of all of the course session prices.
    // Note that this number will be zero for courses that don't support individual session pricing.
    const rawCourseSessionsPriceTotal = course.courseSessions.reduce((sessionSum, session) => {
      return sessionSum + parseFloat(session.price || 0) * session.courseSessionEvents.length;
    }, 0);

    // If this course supports individual session pricing, and the user
    // isn't purchasing it via a subscription, then add the combined session prices to the total.
    if (rawCourseSessionsPriceTotal && !course.purchasedViaSubscription) {
      return sum + rawCourseSessionsPriceTotal;
    }

    // Otherwise, let's use the session / bundle pricing to compute the total.
    const subscriptionProduct = first(course.subscriptionProducts);
    if (rawCourseSessionsPriceTotal === 0) {
      // If the course is being purchased via a subscription,
      // let's check to make sure that this course actually supports subscriptions.
      if (!subscriptionProduct) {
        throw Error("This course doesn't support subscriptions.");
      }

      // If the user is buying a subscription, then just add the weekly
      // subscription price to the price total.
      if (course.purchasedViaSubscription) {
        return sum + parseFloat(subscriptionProduct.price);
      }

      // If the user is buying the course bundle, then add the full
      // subscription price to the price total so that they can see their savings.
      return (
        sum +
        parseFloat(subscriptionProduct.price) *
        subscriptionProduct.numberOfBillingPeriodsAfterWhichToCancel
      );
    }

    // This line should never be reached, but eslint wants it.
    return sum;
  }, 0);
};

/**
 * Computes the price of all of the items in the cart, taking into
 * account bundle discounts, subscriptions, etc...
 *
 * @param {*} coursesFiltered A recursively filtered list of all courses, sessions, and events that the user has bought tickets for.
 * @param {*} allCourses An unfiltered list of all available courses.
 *
 * @returns The total price of all of the items in the cart, as a number.
 */
export const getPriceTotal = (coursesFiltered, allCourses, appliedCoupons) => {
  // A running counter of the total price of the items in the cart.
  let priceTotal = 0;

  let couponsActive = [];

  // First, get the courses for which we're buying tickets.
  forEach(coursesFiltered, (course) => {
    // Now, count the total number of sessions for this course.
    const currentCourseUnfiltered = find(allCourses, ['id', course.id]);
    if (!currentCourseUnfiltered) {
      throw Error(
        'getPriceTotal was called with incompatible inputs. `coursesFiltered` must be a subset of `allCourses`'
      );
    }
    const allSessionsForCurrentCourse = currentCourseUnfiltered.courseSessions;
    const totalNumberOfSessions = allSessionsForCurrentCourse.length;

    // And count the number of sessions for which this user
    // is purchasing tickets (again, for this course).
    const numberOfSessionsInCart = course.courseSessions.length;

    // Check to see if the user is purchasing a subscription for this course.
    // If they are, then let's use subscription pricing.
    if (course.purchasedViaSubscription) {
      // Don't allow people to buy bundles & subscriptions to the same course.
      if (totalNumberOfSessions !== numberOfSessionsInCart) {
        throw Error('You may not buy a subscription and a bundle for the same course.');
      }

      // Check to make sure that this course supports subscriptions.
      const subscriptionProduct = first(course.subscriptionProducts);
      if (!subscriptionProduct) {
        throw Error("This course doesn't support subscriptions.");
      }

      // If everything checks out, add the subscription price to the
      // price total and skip ahead to the next course.
      priceTotal += parseFloat(subscriptionProduct.price);
      return;
    }

    // Now figure out how many bundles & individula sessions the user is purchasing.
    // Note that users may be purchasing more than one course bundle
    // if they have enough tickets in their cart.
    // Note also that we currently assume all sessions cost
    // the same amount of money. If we didn't make this assumption, then
    // we'd have to find the session with the minimum number of tickets,
    // which we may want do in the future but don't need to do right now.
    const numberOfBundlesToPurchase = floor(numberOfSessionsInCart / totalNumberOfSessions);
    const numberOfFullPriceSessionsToPurchase = numberOfSessionsInCart % totalNumberOfSessions;

    // Now, compute the price (numberOfBundlesToPurchase * bundle_price +
    // numberOfFullPriceSessionsToPurchase * session_price)
    const priceOfASingleCourseSession = allSessionsForCurrentCourse[0].price;
    const bundleTotal = numberOfBundlesToPurchase * course.bundlePrice;
    const individualSessionsTotal =
      numberOfFullPriceSessionsToPurchase * priceOfASingleCourseSession;
    let coursePriceTotal = bundleTotal + individualSessionsTotal;
    // Now, after all is calculated, we apply coupons to the course total
    if (appliedCoupons) {
      for (let coupon of appliedCoupons) {
        if (coupon.validCourseId === course.id) {
          let amountOff = coursePriceTotal * (1 - parseFloat(coupon.multiplier));
          couponsActive.push({
            code: coupon.code,
            amountOff: amountOff
          });
          coursePriceTotal *= parseFloat(coupon.multiplier)
        }
      }
    }

    priceTotal += coursePriceTotal;
  });

  return [priceTotal, couponsActive];
};

const CheckoutReceipt = ({ allCourses, coursesFiltered, appliedCoupons, checkoutIsInProgress = false }) => {
  console.log("Applied coupons: ", appliedCoupons);
  const priceSubtotal = useMemo(() => {
    return getPriceSubtotal(coursesFiltered);
  }, [coursesFiltered]);

  const [priceTotal, couponSavingsData] = useMemo(() => {
    return getPriceTotal(coursesFiltered, allCourses, appliedCoupons);
  }, [coursesFiltered, allCourses, appliedCoupons]);

  const atLeastOneCourseUsesSubscriptionPricing = useMemo(() => {
    return !!find(coursesFiltered, 'purchasedViaSubscription');
  }, [coursesFiltered]);

  let totalCouponSavings = 0;
  for (let couponData of couponSavingsData) {
    totalCouponSavings += couponData.amountOff;
  }

  let bundleSavings = priceSubtotal - priceTotal - totalCouponSavings;

  return (
    <ErrorBoundary fallback={ErrorBoundaryFallback()}>
      <Row>
        <Col xs={12} lg={{ span: 10, offset: 1 }}>
          {coursesFiltered.map((course, i) => (
            <CheckoutReceiptCourseItem
              key={`checkout-receipt-course-item-${course.id}`}
              course={course}
              photoIsVisible={i === 0}
              className={coursesFiltered.length > 1 && 'mb-1'}
            />
          ))}

          {/*
            If the user is getting a bundle discount, show the "Subtotal" component
            and the "Bundle Discount" component.
        */}
          {priceSubtotal - priceTotal > 0.01 && (
            <>
              {/* Price Subtotal */}
              <Row className={`${styles.priceSubtotal} mt-4`}>
                {/* This column will fill the remaining space since its sibling is set to 'auto' size */}
                <Col>Subtotal</Col>
                <Col xs="auto">${priceSubtotal.toFixed(2)}</Col>
              </Row>

              {/* Bundle Savings */}
              {bundleSavings > 0 && (
                <Row className={`${styles.priceBundleSavings} mt-1`}>
                  {/* This column will fill the remaining space since its sibling is set to 'auto' size */}
                  <Col>Bundle savings</Col>
                  <Col xs="auto">- ${(bundleSavings).toFixed(2)}</Col>
                </Row>
              )}


              {/* Coupon Savings */}
              {couponSavingsData.map(data => {
                return (
                  <Row className={`${styles.priceBundleSavings} mt-1`}>
                    {/* This column will fill the remaining space since its sibling is set to 'auto' size */}
                    <Col>{data.code}</Col>
                    <Col xs="auto">- ${(data.amountOff).toFixed(2)}</Col>
                  </Row>
                )
              })}
            </>
          )}

          {/* Price Total */}
          <Row className={`${styles.priceTotal} mt-3 font-weight-bold`}>
            {/* This column will fill the remaining space since its sibling is set to 'auto' size */}
            <Col>
              Total
              {atLeastOneCourseUsesSubscriptionPricing && (
                <>
                  {checkoutIsInProgress ? (
                    <>
                      &nbsp;due&nbsp;today
                      <QuestionIcon content="For subscription-based courses, you'll be charged for the first week today. Then, if you like the course (we're confident that you will!), you'll be charged for each subsequent week two days before the week begins. (For instance, for a course beginning on a Monday, you'd be charged now, then on the Saturday before Week 2, then on the Saturday before Week 3, and so on)." />
                    </>
                  ) : (
                    <>
                      &nbsp;paid&nbsp;today
                      <QuestionIcon content="For subscription-based courses, you have been charged for the first week today. If you like the course (we're confident that you will!), you'll be charged for each subsequent week two days before the week begins. (For instance, for a course beginning on a Monday, you'd be charged on the Saturday before Week 2, then on the Saturday before Week 3, and so on)." />
                    </>
                  )}
                </>
              )}
            </Col>
            <Col xs="auto">${priceTotal.toFixed(2)}</Col>
          </Row>
        </Col>
      </Row>
    </ErrorBoundary>
  );
};

export default CheckoutReceipt;
