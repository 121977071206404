import React, { Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import LoadingPage from '../components/layout/LoadingPage';
import Home from '../components/pages/Home';
import { withTracker } from '../assets/utils/GoogleAnalytics';

// TODO Load the routes lazily in order to make the home page load super quickly.
import Course from '../components/pages/Course';
import Courses from '../components/pages/Courses';
import Checkout from '../components/pages/Checkout';
import CheckoutComplete from '../components/pages/CheckoutComplete';
import CourseSessionMaterials from '../components/pages/CourseSessionMaterials';
import Dashboard from '../components/pages/Dashboard';
import DashboardCourses from '../components/pages/DashboardCourses';
import DashboardStudent from '../components/pages/DashboardStudent';
import DashboardStudentCourse from '../components/pages/DashboardStudentCourse';
import DashboardCourseSessionEvents from '../components/pages/DashboardCourseSessionEvents';
import DashboardCourseSessionEvent from '../components/pages/DashboardCourseSessionEvent';
import SignIn from '../components/pages/SignIn';
import Legal from '../components/pages/Legal';
import ErrorPage from '../components/pages/ErrorPage';
import FAQ from '../components/pages/FAQ';

const HomeWithTracker = withTracker(Home);
const CoursesWithTracker = withTracker(Courses);
const CourseWithTracker = withTracker(Course);
const CheckoutWithTracker = withTracker(Checkout);
const CheckoutCompleteWithTracker = withTracker(CheckoutComplete);
const CourseSessionMaterialsWithTracker = withTracker(CourseSessionMaterials);
const SignInWithTracker = withTracker(SignIn);
const LegalWithTracker = withTracker(Legal);
const DashboardWithTracker = withTracker(Dashboard);
const DashboardCoursesWithTracker = withTracker(DashboardCourses);
const DashboardStudentWithTracker = withTracker(DashboardStudent);
const DashboardStudentCourseWithTracker = withTracker(DashboardStudentCourse);
const DashboardCourseSessionEventsWithTracker = withTracker(DashboardCourseSessionEvents);
const DashboardCourseSessionEventWithTracker = withTracker(DashboardCourseSessionEvent);
const FAQWithTracker = withTracker(FAQ);

const AppRoutes = ({ isAuthenticated, isAdmin, isInstructor }) => {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Switch>
        <PublicRoute
          exact
          path="/"
          component={HomeWithTracker}
          isAuthenticated={isAuthenticated}
          redirectTo="/dashboard"
        />
        <Route exact path="/index" component={HomeWithTracker} />
        <Route exact path="/courses" component={CoursesWithTracker} />
        <Route exact path="/courses/:activeCourseId" component={CourseWithTracker} />
        <Route
          exact
          path="/courses/:activeCourseId/course-sessions/:activeCourseSessionId/materials"
          component={CourseSessionMaterialsWithTracker}
        />
        <Route exact path="/checkout" component={CheckoutWithTracker} />
        <Route path="/checkout/complete" component={CheckoutCompleteWithTracker} />
        {/* Users see just a single "Sign In" page for simplicity, which handles both sign ups and log ins */}
        <Route exact path="/signin" component={SignInWithTracker} />
        <Route exact path="/legal" component={LegalWithTracker} />
        <Route exact path="/faq" component={FAQWithTracker} />
        <PrivateRoute
          exact
          path="/dashboard/courses"
          component={DashboardCoursesWithTracker}
          isAuthenticated={isAuthenticated}
        />
        <Route
          exact
          path="/dashboard/student/course/:activeCourseId"
          component={DashboardStudentCourseWithTracker}
          isAuthenticated={isAuthenticated}
        />
        <Route
          exact
          path="/dashboard/student/"
          component={DashboardStudentWithTracker}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path="/dashboard/courses/:activeCourseId"
          component={DashboardCourseSessionEventsWithTracker}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          exact
          path="/dashboard/courses/:activeCourseId/course-sessions/:activeCourseSessionId/course-session-events/:activeCourseSessionEventId"
          component={DashboardCourseSessionEventWithTracker}
          isAuthenticated={isAuthenticated}
        />
        <PrivateRoute
          path="/dashboard"
          component={DashboardWithTracker}
          isAuthenticated={isAuthenticated}
          isInstructor={isInstructor}
          isAdmin={isAdmin}
        />
        {/* Show an error page if none of the above routes match */}
        <Route component={withTracker(ErrorPage)} />
      </Switch>
    </Suspense>
  );
};

export default AppRoutes;
