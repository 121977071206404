import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCourses } from '../../redux/features/courses/coursesSelector';
import {
  getAppliedCoupons,
  getFilteredCoursesForTicketsInCart,
  getTicketLocks,
} from '../../redux/features/cart/cartSelector';
import Countdown, { zeroPad } from 'react-countdown';
import { releaseTicketLocks } from '../../redux/features/cart/cartSlice';
import { useHistory } from 'react-router';
import CheckoutForm from './checkout/CheckoutForm';
import CheckoutReceipt from './checkout/CheckoutReceipt';
import { ErrorBoundary } from '@sentry/react';
import ErrorBoundaryFallback from '../common/ErrorBoundaryFallback';
import styles from './Checkout.module.scss';

// Fifteen minutes
const TICKET_LOCK_LIFESPAN = 1000 * 60 * 15;
// For testing
// const TICKET_LOCK_LIFESPAN = 5000;

const getTicketExpirationTime = (ticketLocks) => {
  if (ticketLocks?.length && ticketLocks[0]?.dateCreated) {
    // Convert to epoch ms for the <Countdown /> component to use.
    return new Date(ticketLocks[0].dateCreated).valueOf() + TICKET_LOCK_LIFESPAN;
  }
  return undefined;
};

const countdownRenderer = ({ minutes, seconds }) => {
  if (minutes === 0 && seconds === 0) {
    return <></>;
  }

  return (
    <span>
      {minutes}:{zeroPad(seconds)}
    </span>
  );
};

const Checkout = () => {
  // Used when switching back to main page after ticket locks expire.
  const history = useHistory();

  const dispatch = useDispatch();
  const allCourses = useSelector(getCourses);
  const appliedCoupons = useSelector(getAppliedCoupons);
  const coursesInCartFiltered = useSelector(getFilteredCoursesForTicketsInCart);
  const ticketLocks = useSelector(getTicketLocks);
  const [ticketExpirationTime, setTicketExpirationTime] = useState(() =>
    getTicketExpirationTime(ticketLocks)
  );
  const [cartHasExpired, setCartHasExpired] = useState(false);

  useEffect(() => {
    if (ticketLocks?.length) {
      const newTicketExpirationTime = getTicketExpirationTime(ticketLocks);
      if (newTicketExpirationTime && newTicketExpirationTime !== ticketExpirationTime) {
        setTicketExpirationTime(newTicketExpirationTime);
      }
    }
  }, [ticketLocks, ticketExpirationTime]);

  const onCountdownComplete = () => {
    // Release the ticketLocks on the server (note that this action is not strictly
    // necessary, as the server automatically prunes expired ticket locks. However,
    // if we delete them ourselves, then they'll be deleted more quickly).
    dispatch(releaseTicketLocks());

    // (Actually, I don't think we should clear the cart) Clear the tickets from redux.
    // dispatch(clearTickets());

    // Show a non-dismissable message modal.
    setCartHasExpired(true);
  };

  const onCountdownMount = ({ completed }) => {
    // Calls the onCountdownComplete function if the cart has already
    // expired when the page initially loads.
    if (completed) {
      onCountdownComplete();
    }
  };

  return (
    <>
      <Helmet>
        <title>AskMe Learning | Checkout</title>
      </Helmet>
      {/* Cart Expiration Modal */}
      <ErrorBoundary fallback={ErrorBoundaryFallback()}>
        <Modal
          show={cartHasExpired}
          size="lg"
          centered
          className={styles.expirationModal}
          onHide={() => { }}
        >
          <Modal.Body className={`${styles.expirationModalMessage} text-center px-5`}>
            Your cart has expired. Please try again.
          </Modal.Body>
          <Modal.Footer className="justify-content-center">
            {/* TODO send users to a dedicated "Cart" page */}
            <div
              className={`${styles.expirationModalButton} askme-btn-terciary d-inline-block`}
              onClick={() => history.push('/courses')}
            >
              Go to the AskMe home page
              {/* <div className={`${styles.expirationModalButton} askme-btn-terciary d-inline-block`} onClick={history.goBack}> */}
              {/* Go to the course page */}
              <FontAwesomeIcon icon={faLongArrowAltRight} className="ml-3" />
            </div>
          </Modal.Footer>
        </Modal>
      </ErrorBoundary>

      {/* Checkout Page */}
      <Container fluid className={`${styles.checkoutContainer}`}>
        <Row className="text-center my-2 mt-lg-5 mb-md-4 mb-xl-5">
          <Col xs={12}>
            <h1 className={`${styles.pageTitle} d-inline-block`}>Checkout</h1>
            <h3 className={`${styles.countdownTimer} d-inline-block ml-4 text-left`}>
              {!!ticketExpirationTime && (
                <>{cartHasExpired ? <span>expired</span> : <span>expires in&nbsp;</span>}</>
              )}
              <Countdown
                date={ticketExpirationTime}
                onMount={onCountdownMount}
                onComplete={onCountdownComplete}
                zeroPadTime={1}
                renderer={countdownRenderer}
              />
              {/* <span className='text-monospace'>&nbsp;left</span> */}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={{ span: 10, offset: 1 }}>
            <Row>
              <Col
                xs={{ span: 12, order: 2 }}
                md={{ span: 5, order: 1 }}
                className="pl-lg-5 pl-xl-3"
              >
                <CheckoutForm />
              </Col>
              <Col xs={{ span: 12, order: 1 }} md={{ span: 7, order: 2 }} className="mb-4 mb-md-0">
                <CheckoutReceipt
                  allCourses={allCourses}
                  coursesFiltered={coursesInCartFiltered}
                  appliedCoupons={appliedCoupons}
                  checkoutIsInProgress={true}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Checkout;
