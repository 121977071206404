import { first } from 'lodash';
import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Testimonial from './Testimonial';
// import styles from './Testimonials.module.scss';

const Testimonials = ({ testimonials, ...props }) => {
  if (testimonials?.length === 1) {
    return <Testimonial testimonial={first(testimonials)} {...props} />;
  } else if (testimonials?.length > 1) {
    <Row {...props}>
      {/* TODO implement for real (i.e. make this element
        a carousel so that only one testimonial shows at a time)  */}
      {testimonials.map((testimonial) => (
        <Col xs={12} key={`testimonial-${testimonial.id}`}>
          <Testimonial testimonial={testimonial} {...props} />
        </Col>
      ))}
    </Row>;
  }

  return null;
};

export default Testimonials;
