import React, { useEffect } from 'react';
import ReactGA from 'react-ga';

export const initializeGoogleAnalytics = function () {
  ReactGA.initialize('UA-181889529-1', { testMode: process.env.NODE_ENV === 'test' });
};

export const getStrippedPathname = (pathname) => {
  // TODO strip out parameters & whatnot
  return pathname;
};

/**
 * From ReactGA Community Wiki Page https://github.com/react-ga/react-ga/wiki/React-Router-v4-withTracker
 */
export const withTracker = (WrappedComponent, trackLocationInsteadOfPath = false) => {
  const trackPage = (page) => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
  };

  const HOC = (props) => {
    useEffect(() => {
      if (!trackLocationInsteadOfPath) {
        trackPage(props.match.path);
      }
    }, [props.match.path]);

    useEffect(() => {
      if (trackLocationInsteadOfPath) {
        const strippedPathname = getStrippedPathname(props.location.pathname);
        trackPage(strippedPathname);
      }
    }, [props.location.pathname]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

const trackEventFactory = (category) => {
  return (action, label, value) => {
    // console.log('event tracked', { category, action, label, value });
    // console.log('event tracked', category, action, label, value);  // For Safari
    ReactGA.event({
      category,
      action,
      label,
      value,
    });
  };
};

/** @params (action) */
export const trackNavbarEvent = trackEventFactory('Navbar');

/** @params (action, label, value) */
export const trackCourseEvent = trackEventFactory('Course');

/** @params (action, label, value) */
export const trackCheckoutEvent = trackEventFactory('Checkout');

/** @params (action, label, value) */
export const trackCourseMaterialsEvent = trackEventFactory('Course Materials');

/** @params (action, label, value) */
export const trackCheckoutCompleteEvent = trackEventFactory('Checkout Complete');
