import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';
import styles from './Testimonial.module.scss';

const Testimonial = ({ testimonial, inline = true, className = '', ...props }) => {
  if (!testimonial) return null;

  const byLine = (
    <span className={styles.authorName}>
      &mdash;&nbsp;{testimonial.authorFirstName}&nbsp;
      {testimonial.authorLastName && testimonial.authorLastName.charAt(0)}
      {testimonial.authorLastName && '.'}
      {testimonial.authorDescription && `, ${testimonial.authorDescription}`}
    </span>
  );

  return (
    <Row className={`${styles.container} ${className}`} {...props}>
      <Col xs={12}>
        <span className={`${styles.quoteIcon} ${styles.begin}`} aria-label="Begin quote">
          <FontAwesomeIcon icon={faQuoteLeft} />
        </span>
        {testimonial.text}
        <span className={`${styles.quoteIcon} ${styles.end}`} aria-label="End quote">
          <FontAwesomeIcon icon={faQuoteRight} />
        </span>
        {inline && byLine}
      </Col>
      {!inline && (
        <Col xs={12} className={styles.byLineSeparate}>
          {byLine}
        </Col>
      )}
    </Row>
  );
};

export default Testimonial;
