import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import '@fortawesome/fontawesome-svg-core/styles.css';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './redux/store';
import axios from 'axios';
import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_API_KEY } from './assets/constants/constants';
import * as Sentry from '@sentry/react';

// TODO Maybe import StripeJS globally so that we can make full use of its
// fraud-detection features. Not sure if it's necessary to do a global import, though.
// import '@stripe/stripe-js';

// Disable FontAwesome's auto-add-css feature, which goes
// against Askme's strict Content Security Policy.
// This code must come before any FontAwesome API calls.
fontAwesomeConfig.autoAddCss = false;

// Uncomment this line if you want to purge the Redux store
// during development / due to an unexpected bug / etc...
// persistor.purge();

// Load Stripe
const stripePromise = loadStripe(STRIPE_API_KEY);

// Configure axios to always use localhost:8000 as the
// baseURL during development (and not, for instance, localhost:3000)
// By specifying port 8000 as the baseURL for API requests,
// we can develop on localhost:3000 and still access to the API.
// Note that POST requests that require CSRF tokens still won't work.
if (process.env.NODE_ENV === 'development') {
  axios.defaults.baseURL = 'http://localhost:8000';
}

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';

// Initialize Sentry
Sentry.init({
  dsn: 'https://63b39e5a5ab7403a8398d7eea570f400@o552160.ingest.sentry.io/5677337',
  // Either 'development' or 'production'
  environment: process.env.NODE_ENV,
});

// Render the App
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Elements stripe={stripePromise}>
        <App />
      </Elements>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
