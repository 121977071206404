import React from 'react';
import BrowseBySubject from './home/BrowseBySubject';
import Welcome from './home/Welcome';
import OurStory from './home/OurStory';
import WhoWeAre from './home/WhoWeAre';
import FastFacts from './home/FastFacts';
import TestimonialSquares from './home/TestimonialSquares';
import CallToAction from './home/CallToAction';
import Container from 'react-bootstrap/Container';
// import styles from './Home.module.scss';

const Home = () => {
  return (
    <Container fluid as="main">
      <Welcome />
      <BrowseBySubject />
      <OurStory />
      <WhoWeAre />
      <FastFacts />
      <TestimonialSquares />
      <CallToAction />
    </Container>
  );
};

export default Home;
