import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Button, Col, Container, Form, FormControl, InputGroup, Modal, Row } from 'react-bootstrap';
import { sortBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveCourse, getCourseSessionEventsForActiveCourse } from '../../redux/features/courses/coursesSelector';
import {
  fetchCourse,
  fetchCourseSessions,
  setActiveCourse,
} from '../../redux/features/courses/coursesSlice';
import CourseSessionEventsTable from './dashboard/CourseSessionEventsTable';
import DashboardHeader from './dashboard/DashboardHeader';
import styles from './DashboardCourseSessionEvents.module.scss';
import { toast } from 'react-toastify';
import axios from 'axios';

const DashboardCourse = () => {
  const { activeCourseId } = useParams();
  const dispatch = useDispatch();
  const courseSessionEvents = useSelector(getCourseSessionEventsForActiveCourse);
  const activeCourse = useSelector(getActiveCourse);
  // Update the active course ID in redux, and pull the course data from the server.
  useEffect(() => {
    const activeCourseIdInt = activeCourseId ? parseInt(activeCourseId) : undefined;

    if (activeCourseIdInt) {
      // We should fetch the course here so that the breadcrumb component
      // has access to the course name.
      dispatch(fetchCourse({ id: activeCourseIdInt }));
      dispatch(fetchCourseSessions({ courseId: activeCourseIdInt }));
    }

    dispatch(setActiveCourse({ id: activeCourseIdInt }));

    return () => {
      dispatch(setActiveCourse({ id: undefined }));
    };
  }, [activeCourseId, dispatch]);

  const courseSessionEventsSorted = useMemo(() => {
    return sortBy(courseSessionEvents, 'date');
  }, [courseSessionEvents]);


  let [showModal, setShowModal] = useState(false);

  function openModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }

  function CouponModal(props) {
    console.log(props.show);

    let handleSubmit = (event) => {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form instanceof HTMLElement) {
        const couponName = form.querySelector("#couponCode").value;
        const percentOff = parseInt(form.querySelector("#percentOff").value);
        if (couponName && percentOff && !isNaN(percentOff) && percentOff > 0 && percentOff <= 100) {
          console.log(couponName, percentOff, activeCourseId);
          axios.post("/api/coupons/create", {
            name: couponName,
            percentOff: percentOff,
            courseId: parseInt(activeCourseId)
          }).then(response => {
            console.log(response.prototype);
            toast.success("Created coupon code " + couponName)
            closeModal()
          }).catch(error => {
            toast.error(error.response.data)
          })
        } else {
          toast.error("Invalid form data")
        }
      }
    }

    return (
      <Modal backdrop="static" show={props.show} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add a Coupon</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>

          <Modal.Body>

            <Form.Label htmlFor="couponCode">Coupon Name <small>(Example: PHYSICS25)</small></Form.Label>
            <InputGroup className="mb-3">
              <FormControl
                id="couponCode"
                required
                type="text"
                placeholder="Coupon Code"
                aria-label="Coupon code"
                aria-describedby="couponCode"
              />
            </InputGroup>


            <Form.Label htmlFor="percentOff">Discount Amount</Form.Label>
            <InputGroup className="mb-3">
              <FormControl
                required
                type="number"
                min="1"
                max="100"
                placeholder="Percent off the coupon grants"
                aria-label="Percent off"
                aria-describedby="percentOff"
                id="percentOff"
              />
              <InputGroup.Text id="percentOff">% off</InputGroup.Text>
            </InputGroup>

            <Form.Label htmlFor="validFor">Valid For</Form.Label>
            <InputGroup className="mb-3">
              <FormControl
                defaultValue={activeCourse?.name}
                aria-label="Course coupon is valid for"
                aria-describedby="validFor"
                disabled
                style={{ fontWeight: 'bold' }}
              />
            </InputGroup>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>Close</Button>
            <Button type="submit">Add Coupon</Button>
          </Modal.Footer>
        </Form>

      </Modal>
    )
  }


  return (
    <Container className={`min-height-page-container pb-3 ${styles.container}`}>
      <CouponModal show={showModal}></CouponModal>
      <DashboardHeader pageTitle="Course Session Events" />
      <Row>
        <Col className={styles.buttonRow}>
          <Button variant="outline-success" onClick={openModal}>Manage Coupons</Button>
        </Col>
      </Row>
      <CourseSessionEventsTable courseSessionEvents={courseSessionEventsSorted} />
    </Container>
  );
};


export default DashboardCourse;
