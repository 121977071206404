import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { getModernImageSrc } from '../../common/ModernImage';
import WhoWeAreBio from './who-we-are/WhoWeAreBio';
import homeStyles from '../Home.module.scss';
import styles from './WhoWeAre.module.scss';

const AskeyPhoto = getModernImageSrc('./home/headshots/Askey');
const JeffPhoto = getModernImageSrc('./home/headshots/Jeff');
const IsaacPhoto = getModernImageSrc('./home/headshots/Isaac');
const TeachersPhoto = getModernImageSrc('./home/headshots/TeacherCircles');

function WhoWeAre() {
  return (
    <Row
      as="section"
      id="who-we-are"
      className={`${homeStyles.sectionContainer} ${styles.container}`}
    >
      <Col xs={12} md={{ span: 10, offset: 1 }}>
        <h2 className={`${homeStyles.sectionHeader} ${styles.header}`}>Who we are</h2>
        <Row>
          <Col xs={12} xl={{ span: 8, offset: 2 }}>
            <WhoWeAreBio
              name="David Askey"
              title="Director"
              bio="Askey teaches Physics and ponders the universe and the people in it . . . and he should be grading right now."
              photo={AskeyPhoto}
            />
            <WhoWeAreBio
              name="Jeff Terry"
              title="Lead Software Engineer"
              bio="Jeff is a software engineer with over a decade of experience. Pizza is his favorite vegetable."
              photo={JeffPhoto}
            />
            <WhoWeAreBio
              name="Isaac Yates"
              title="Software Engineer"
              bio="Isaac is a software engineer with a passion for game-design. He also makes a mean cherry tomato pasta sauce."
              photo={IsaacPhoto}
            />
            <WhoWeAreBio
              name="Experienced, award-winning teachers with a cumulative 100+ years of classroom experience."
              photo={TeachersPhoto}
              className={styles.teachersBio}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default WhoWeAre;
