import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styles from './CallToAction.module.scss';
import homeStyles from '../Home.module.scss';

function CallToAction() {
  return (
    <Row as="section" className={`${homeStyles.sectionContainer} ${styles.container}`}>
      <Col xs={12} md={{ span: 10, offset: 1 }}>
        <NavLink
          exact
          to="/courses"
          className={`${styles.findMyClassBtn} askme-btn-primary-inverted thick d-inline-block`}
        >
          Find my class
        </NavLink>
      </Col>
    </Row>
  );
}

export default CallToAction;
