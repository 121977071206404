import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import rootReducer from './rootReducer';

const persistConfig = {
  key: 'root',
  storage,
};

export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: getDefaultMiddleware({
    serializableCheck: {
      // Ignore these action types
      ignoredActions: ['persist/PERSIST'],
    },
  }),
});

// The persistStore makes it so that the Redux store persists
// between page reloads, browser restarts, etc...
export const persistor = persistStore(store);

// The root `state` object is structured like so:
// {
//         user: {
//             fetchUserError: false,
//             current: {...}
//         },
//         instructors: {
//             all: []
//         },
//         etc...
// }
