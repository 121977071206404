import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getUserIsAdmin, getUserIsInstructor } from '../../../redux/features/user/userSelector';
import DashboardBreadcrumbs from './DashboardBreadcrumbs';

const DashboardHeader = ({ pageTitle, className }) => {
  const userIsAdmin = useSelector(getUserIsAdmin);
  const userIsInstructor = useSelector(getUserIsInstructor);

  return (
    <Row className={`${className} headerRow`}>
      <Col xs={12} className="mt-4 mb-0">
        <DashboardBreadcrumbs />
      </Col>
      <Col xs={12} className="mt-0 mt-lg-2 mb-2">
        <h1>
          {userIsAdmin && 'Admin'}
          {!userIsAdmin && userIsInstructor && 'Instructor'} Dashboard
          {pageTitle ? ` - ${pageTitle}` : ''}
        </h1>
      </Col>
    </Row>
  );
};

export default DashboardHeader;
