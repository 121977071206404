import React, { useEffect, useMemo } from 'react';
import { map, flatMap, orderBy, sumBy, min, max } from 'lodash';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMyCourses } from '../../redux/features/courses/coursesSlice';
import { getMyCourses } from '../../redux/features/courses/coursesSelector';
import { getDateTimeFromMillisOrISOString } from '../../assets/utils/utils';
import CoursesTable from './dashboard/CoursesTable';
import DashboardHeader from './dashboard/DashboardHeader';
import styles from './DashboardCourses.module.scss';
import { getUserIsAdmin, getUserIsInstructor } from '../../redux/features/user/userSelector';

const DashboardCourses = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMyCourses());
  }, [dispatch]);

  const myCourses = useSelector(getMyCourses);

  const userIsInstructor = useSelector(getUserIsInstructor);
  const userIsAdmin = useSelector(getUserIsAdmin);

  const coursesAnnotated = useMemo(() => {
    return map(myCourses, (course) => {
      // Calculate the totalTicketsAvailable and totalTicketsCap.
      const courseSessionEvents = flatMap(course.courseSessions, 'courseSessionEvents');
      const totalTicketsAvailable = sumBy(courseSessionEvents, 'ticketsAvailable');
      const totalTicketsCap = sumBy(courseSessionEvents, 'ticketsCap');

      // Calculate the start and end dates
      const courseSessionEventDates = map(courseSessionEvents, 'date');
      const startDate = getDateTimeFromMillisOrISOString(min(courseSessionEventDates));
      const endDate = getDateTimeFromMillisOrISOString(max(courseSessionEventDates));

      return { ...course, totalTicketsAvailable, totalTicketsCap, startDate, endDate };
    });
  }, [myCourses]);

  const coursesAnnotatedAndSorted = useMemo(() => {
    return orderBy(coursesAnnotated, 'endDate', 'desc');
  }, [coursesAnnotated]);

  return (
    <Container className={`min-height-page-container pb-3 ${styles.container}`}>
      <DashboardHeader pageTitle={`${userIsInstructor && !userIsAdmin ? 'My ' : ''}Courses`} />
      <CoursesTable courses={coursesAnnotatedAndSorted} />
    </Container>
  );
};

export default DashboardCourses;
