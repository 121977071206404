import { createSelector } from '@reduxjs/toolkit';
import { find } from 'lodash';

export const getUser = (state) => state.user.current || {};

export const getUserIsAdmin = createSelector(
  [getUser],
  (user) => !!find(user?.groups || [], (group) => group.name === 'admins')
);

export const getUserIsInstructor = createSelector(
  [getUser],
  (user) => !!find(user?.groups || [], (group) => group.name === 'instructors')
);
