import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getCourseSessionsForPurchasedTickets } from '../../../redux/features/cart/cartSelector';
import CourseSessionInstructionCard from './CourseSessionInstructionCard';
// import styles from './CheckoutConfirmationInstructionCards.module.scss';

const CheckoutConfirmationInstructionCards = () => {
  const courseSessionsPurchased = useSelector(getCourseSessionsForPurchasedTickets);
  if (!courseSessionsPurchased?.length > 0) return null;

  return (
    <>
      <Row>
        <Col xs={12}>
          {courseSessionsPurchased.map((courseSession) => (
            <CourseSessionInstructionCard
              key={`course-session-instruction-card-${courseSession.id}`}
              className="mt-3"
              courseSession={courseSession}
            />
          ))}
        </Col>
      </Row>
    </>
  );
};

export default CheckoutConfirmationInstructionCards;
