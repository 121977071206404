// TODO: This page could end up being bigger later on.
// for now, the router sends /dashboard to /dashboard/courses
// (look at DashboardCourses obj for that page)

import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

export default function Dashboard({ isAuthenticated, isAdmin, isInstructor }) {
  const history = useHistory();

  useEffect(() => {
    if (isAdmin || isInstructor) history.push('/dashboard/courses');
    else history.push('/dashboard/student');
  }, [history, isAdmin, isInstructor]);

  return (
    <div className="min-height-page-container text-center my-5">
      {/* <Row><Col><h3>Dashboard Overview</h3></Col></Row>
      <Row>
        <Col className="mx-auto float-center"><Button onClick={()=>{history.push('/dashboard/courses')}}>View Courses</Button></Col>
      </Row> */}
    </div>
  );
}
