import { createSelector } from '@reduxjs/toolkit';
import { filter, find, first, flatMap, map, reject, reverse, sortBy, sumBy } from 'lodash';

export const getCourseSessionEventsFromCourseSessions = (courseSessionsForActiveCourse) => {
  return flatMap(courseSessionsForActiveCourse, (courseSession) => {
    return map(courseSession.courseSessionEvents, (courseSessionEvent) => {
      // Replace the courseSession ids with the full courseSession object.
      const courseSessionWithoutNestedEvent = {
        ...courseSession,
      };
      delete courseSessionWithoutNestedEvent.courseSessionEvents;
      return { ...courseSessionEvent, courseSession: courseSessionWithoutNestedEvent };
    });
  });
};

// Sorted (descending) by the date of the first event.
export const getCourses = (state) =>
  reverse(
    sortBy(state.courses.all || [], (course) => {
      const firstSession = course.courseSessions ? first(course.courseSessions) : undefined;
      const firstEvent = firstSession ? first(firstSession.courseSessionEvents) : undefined;
      return firstEvent?.date;
    })
  );
export const getMyCourses = (state) =>
  reverse(
    sortBy(state.courses.mine || [], (course) => {
      const firstSession = course.courseSessions ? first(course.courseSessions) : undefined;
      const firstEvent = firstSession ? first(firstSession.courseSessionEvents) : undefined;
      return firstEvent?.date;
    })
  );
export const getCourseSessions = (state) => state.courses.sessions || [];
export const getMyCourseSessions = (state) => state.courses.mySessions || [];
// We sort the postCheckoutQuestions when we put them into the store, so we need not sort them here.
export const getPostCheckoutQuestions = (state) => state.courses.postCheckoutQuestions || [];
export const getFetchCourseError = (state) => !!state.courses.fetchCourseError;
export const getFetchCoursesError = (state) => !!state.courses.fetchCoursesError;
export const getFetchCourseSessionsError = (state) => !!state.courses.fetchCourseSessionsError;
export const getFetchPostCheckoutQuestionsError = (state) =>
  !!state.courses.fetchPostCheckoutQuestionsError;
const getActiveCourseId = (state) => state.courses.activeCourseId;
const getActiveCourseSessionEventId = (state) => state.courses.activeCourseSessionEventId;

export const getPublishedCourses = createSelector([getCourses], (courses) =>
  filter(courses, 'published')
);

export const getUnpublishedCourses = createSelector([getCourses], (courses) =>
  reject(courses, 'published')
);

export const getActiveCourse = createSelector(
  [getCourses, getActiveCourseId],
  (courses, activeCourseId) => find(courses, ['id', activeCourseId])
);

export const getCourseSessionsForActiveCourse = createSelector(
  [getCourseSessions, getActiveCourseId],
  (courseSessions, activeCourseId) =>
    sortBy(filter(courseSessions, ['course', activeCourseId]), 'displayOrder')
);

// export const getMyCourseSessions = createSelector(
//   [getCourseSessions, getActiveCourseId],
//   (courseSessions, activeCourseId) =>
//     sortBy(filter(courseSessions, ['course', activeCourseId]), 'displayOrder')
// );

export const getCourseSessionEventsForActiveCourse = createSelector(
  [getCourseSessionsForActiveCourse],
  getCourseSessionEventsFromCourseSessions
);

export const getActiveCourseSessionEvent = createSelector(
  [getCourseSessionEventsForActiveCourse, getActiveCourseSessionEventId],
  (courseSessionEvents, activeCourseSessionEventId) =>
    find(courseSessionEvents, ['id', activeCourseSessionEventId])
);

export const getTotalTicketsAvailableForActiveCourse = createSelector(
  [getCourseSessionEventsForActiveCourse],
  (courseSessionEventsForActiveCourse) =>
    sumBy(courseSessionEventsForActiveCourse, 'ticketsAvailable')
);

export const getTotalTicketsCapForActiveCourse = createSelector(
  [getCourseSessionEventsForActiveCourse],
  (courseSessionEventsForActiveCourse) => sumBy(courseSessionEventsForActiveCourse, 'ticketsCap')
);
