import React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getCourses } from '../../redux/features/courses/coursesSelector';
import {
  getAppliedCoupons,
  getCheckoutFormData,
  getCourseSessionsForPurchasedTickets,
  getRecursivelyFilteredCoursesForPurchasedTickets,
} from '../../redux/features/cart/cartSelector';
import PostCheckoutForm from './checkout-complete/PostCheckoutForm';
import CheckoutConfirmationInstructionCards from './checkout-complete/CheckoutConfirmationInstructionCards';
import CheckoutReceipt from './checkout/CheckoutReceipt';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint, faCheck, faCheckDouble, faEnvelope } from '@fortawesome/free-solid-svg-icons';
// import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { executeOnEnter } from '../../assets/utils/utils';
import { trackCheckoutCompleteEvent } from '../../assets/utils/GoogleAnalytics';
import styles from './CheckoutComplete.module.scss';

const printReceipt = () => {
  trackCheckoutCompleteEvent('Clicked Print Receipt');
  window.print();
};

const CheckoutComplete = () => {
  const location = useLocation();
  const { path } = useRouteMatch();
  const allCourses = useSelector(getCourses);
  const checkoutFormData = useSelector(getCheckoutFormData);
  const coursesPurchased = useSelector(getRecursivelyFilteredCoursesForPurchasedTickets);
  const courseSessionsPurchased = useSelector(getCourseSessionsForPurchasedTickets);

  return (
    <>
      <Helmet>
        <title>AskMe Learning | Order Confirmation</title>
      </Helmet>
      <Container fluid className={`${styles.checkoutContainer}`}>
        <Row className="text-center my-2 mt-lg-5 mb-md-4 mb-xl-5">
          <Col xs={12}>
            <h1 className={styles.pageTitle}>Order Confirmation</h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} xl={{ span: 10, offset: 1 }}>
            <Row>
              {/* Instructions */}
              <Col
                xs={{ span: 12, order: 1 }}
                md={{ span: 6, order: 1 }}
                lg={{ span: 5, order: 1 }}
                className="mb-3 mb-lg-2 pl-lg-5 pl-xl-3"
              >
                <Row className="d-none d-md-flex">
                  <Col xs={12}>
                    <h2 className="mb-1">Congratulations!</h2>
                  </Col>
                </Row>

                <Row className="align-items-center mt-md-2">
                  <Col xs={2} sm={1} md={2} className={`text-right ${styles.informationIcon}`}>
                    <FontAwesomeIcon icon={faCheck} className="mr-3" />
                  </Col>
                  <Col xs={10} sm={11} md={10} className={`${styles.spotConfirmationText}`}>
                    Your {courseSessionsPurchased.length > 1 ? 'spots have' : 'spot has'} been
                    reserved.
                  </Col>
                </Row>

                <Row className="align-items-center mt-1">
                  <Col xs={2} sm={1} md={2} className={`text-right ${styles.informationIcon}`}>
                    <FontAwesomeIcon icon={faEnvelope} className="mr-3" />
                  </Col>
                  <Col xs={10} sm={11} md={10} className={`${styles.emailConfirmationText}`}>
                    A confirmation email has been sent to {checkoutFormData.parentEmail}.
                  </Col>
                </Row>

                {!location.pathname.endsWith('with-questions') && (
                  <Row className="align-items-center mt-1">
                    <Col xs={2} sm={1} md={2} className={`text-right ${styles.informationIcon}`}>
                      <FontAwesomeIcon icon={faCheckDouble} className="mr-3" />
                    </Col>
                    <Col xs={10} sm={11} md={10} className={`${styles.emailConfirmationText}`}>
                      Your answers have been received.
                    </Col>
                  </Row>
                )}

                <Switch>
                  <Route exact path={`${path}/with-questions`} component={PostCheckoutForm} />
                  <Route component={CheckoutConfirmationInstructionCards} />
                </Switch>
              </Col>

              {/* Receipt */}
              <Col
                xs={{ span: 12, order: 2 }}
                md={{ span: 6, order: 2 }}
                lg={{ span: 7, order: 2 }}
                className="mb-4 mb-md-0"
              >
                <CheckoutReceipt allCourses={allCourses} coursesFiltered={coursesPurchased} appliedCoupons={checkoutFormData.appliedCoupons} />
              </Col>

              {/* "Print this page" button */}
              {!location.pathname.endsWith('with-questions') && (
                <Col
                  xs={{ span: 12, order: 3 }}
                  md={6}
                  lg={5}
                  className="text-center mt-lg-2 d-print-hidden"
                >
                  <span
                    className={`${styles.printThisPageText} cursor-pointer`}
                    tabIndex="0"
                    onClick={printReceipt}
                    onKeyPress={(event) => executeOnEnter(event, printReceipt)}
                  >
                    <FontAwesomeIcon icon={faPrint} className="mr-3" />
                    Print this page
                  </span>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CheckoutComplete;
