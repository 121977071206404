import { useEffect } from 'react';
import { Button, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useDispatch, useSelector } from 'react-redux';
import { getMyCourses } from '../../redux/features/courses/coursesSelector';
import { fetchMyCourses } from '../../redux/features/courses/coursesSlice';
import ModernImage, { getModernImageSrc } from '../common/ModernImage';
import './DashboardStudent.scss';
import { DateTime } from 'luxon';
const DashboardStudent = () => {
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMyCourses());
  }, [dispatch]);

  const myCourses = useSelector(getMyCourses);

  function getLatestEventOfCourseSession(courseSession) {
    let list = [...courseSession.courseSessionEvents].sort(
      (x, y) => Date.parse(x.date) - Date.parse(y.date)
    );
    if (list.length === 0) return null;
    return list[list.length - 1];
  }

  function getSortedCourseSessions(course) {
    let now = new Date();

    let sessionsThatHaventHappened = [];
    for (let session of course.courseSessions) {
      for (let event of session.courseSessionEvents) {
        let startDate = new Date(event.date);
        let diffInMs = startDate.getTime() - now.getTime();
        //If you are looking at the website, and there has been a session in the last 4 hours,
        //you probably want to see that one
        if (diffInMs > -4 * 1000 * 60 * 60) {
          sessionsThatHaventHappened.push(session);
        }
      }
    }
    sessionsThatHaventHappened = sessionsThatHaventHappened.sort((a, b) => {
      let latestEventA = getLatestEventOfCourseSession(a);
      let latestEventB = getLatestEventOfCourseSession(b);
      return Date.parse(latestEventA.date) - Date.parse(latestEventB.date);
    });

    return sessionsThatHaventHappened;
  }

  function getNextSessionEventOfCourse(course) {
    let session = getSortedCourseSessions(course)[0];
    return getLatestEventOfCourseSession(session) || {};
  }

  function getNextSessionDateStringOfCourse(course) {
    let sortedSessions = getSortedCourseSessions(course);
    if (!sortedSessions.length) return <></>;
    return (
      <>
        <b>Next Session:</b>{' '}
        {sortedSessions[0].name}, {futureDateToHumanString(new Date(getNextSessionEventOfCourse(course).date))} {''}

      </>
    );
  }

  return (
    <Container className={`min-height-page-container pb-3 container`}>
      <h1>Student Dashboard - My Courses</h1>
      <div className="cardsWrapper">
        {myCourses.map((course) => {
          return (
            <div className="courseCard">
              <div className="courseCardHeader">
                <div className="courseCardImageWrapper">
                  {course.photoPath ? (
                    <ModernImage
                      fluid
                      src={getModernImageSrc(course.photoPath)}
                      alt={course.name}
                      className={`d-inline-block w-100 courseCardImage`}
                    />
                  ) : (
                    <Skeleton height={70} />
                  )}
                </div>
                <div className="courseCardTitleWrapper">
                  <h2>{course.name}</h2>
                  <p>{getNextSessionDateStringOfCourse(course)}</p>
                </div>
              </div>
              <div className="courseCardBody">
                <div className="buttonRow">
                  <Link to={'/dashboard/student/course/' + course.id}>
                    <Button>View Course</Button>
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
        {myCourses.length === 0 ? (
          <div>
            <br />
            <h5>It doesn't look like you've purchased tickets for any courses. Visit our <a href="/courses">courses page</a> to find a class for you!</h5>
            <p>(Not seeing courses even though you have a ticket? You may have purchased tickets using a different e-mail address than the one you signed in with.
              You will still get e-mails to that address to let you access your courses)</p>
          </div>
        )
          : <></>}
      </div>
    </Container>
  );
};

export default DashboardStudent;

// This seems like a function that shouldn't be written,
// like it should be provided by a library
// TODO: remove this method in favor of a library, maybe something like moment.js?
export const futureDateToHumanString = (dateObj) => {
  if (!(dateObj instanceof Date)) {
    throw new Error('Illegal argument to futureDateToHumanString');
  }

  let asDateTime = DateTime.fromJSDate(dateObj);
  return asDateTime.toRelativeCalendar() + " at " + asDateTime.toLocaleString(DateTime.TIME_SIMPLE);
};
