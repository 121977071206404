import React from 'react';
import { Table } from 'react-bootstrap';
import QRCode from 'qrcode.react';
import { toast } from 'react-toastify';
import { printDateRangeString } from '../../../assets/utils/utils';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../../assets/constants/constants';
import dashboardStyles from './DashboardStyles.module.scss';
import AskMeTippy from '../../common/AskMeTippy';
import styles from './CoursesTable.module.scss';
import { floor } from 'lodash';

const copyQRCodeToClipboard = (event) => {
  // eslint-disable-next-line
  if (!ClipboardItem || !navigator.clipboard?.write) {
    throw Error('This browser is not able to copy the QR Code to the clipboard.');
  }

  // This method does not work on Firefox or older versions of other browsers.
  event.target.toBlob((blob) => {
    // eslint-disable-next-line
    const item = new ClipboardItem({ 'image/png': blob });
    navigator.clipboard.write([item]).then(
      () => {
        toast.success('The QR code was copied to your clipboard!');
      },
      () => {
        toast.error(
          'Could not copy the QR code to your clipboard. Your browser may not support this feature. Please try again using Google Chrome.'
        );
      }
    );
  });
};

const tableHeaders = [
  'Name',
  'Dates',
  'Bundle Price',
  '# Sessions',
  'QR Code',
  '# Tickets Sold',
  '# Spots',
];

const CoursesTable = ({ courses }) => {
  // const courseIds = useMemo(() => {
  //   return map(courses, 'id');
  // }, [courses]);

  // // Cache the QR Codes in case the SVG generator algorithm is expensive.
  // const QRCodes = useMemo(() => {
  //   if (!courseIds) return {};
  //   console.log('regenerating qr codes');
  //   const courseIdMap = keyBy(courseIds);
  //   return mapValues(courseIdMap, courseId => {
  //     return <QRCode value={`${BASE_URL}/courses/${courseId}`} className={styles.qrcode} />
  //   });
  //   // return keyBy(QRCodes, 'courseId');
  // }, [courseIds]);

  return (
    <Table responsive className={dashboardStyles.table}>
      <thead>
        <tr>
          {tableHeaders.map((header) => (
            <th key={`courses-header-${header}`}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {courses.map((course) => {
          return (
            <tr key={`courses-table-row-${course.id}`}>
              {/* "Name" */}
              <td>
                <Link
                  to={`/dashboard/courses/${course.id}`}
                  className={`clear-link-styling ${dashboardStyles.tableLink}`}
                >
                  {course.name}
                </Link>
              </td>

              {/* "Dates" */}
              <td>{printDateRangeString(course.startDate, course.endDate, true)}</td>

              {/* "Bundle Price" */}
              <td>${floor(course.bundlePrice)}</td>

              {/* "# of Sessions" */}
              <td>{course?.courseSessions?.length}</td>

              {/* "QR Code" */}
              <td>
                <AskMeTippy content="Click the QR Code to copy it to your clipboard.">
                  <div className="d-inline-block cursor-pointer">
                    <QRCode
                      value={`${BASE_URL}/courses/${course.id}`}
                      size={256}
                      className={styles.qrcode}
                      onClick={copyQRCodeToClipboard}
                    />
                  </div>
                </AskMeTippy>
                {/* {QRCodes[course.id]} */}
              </td>

              {/* "Tickets Sold" */}
              <td>{course.totalTicketsCap - course.totalTicketsAvailable}</td>

              {/* "Tickets Available" */}
              <td>{course.totalTicketsCap}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default CoursesTable;
