import React from 'react';
import { Row, Col } from 'react-bootstrap';
import ModernImage, { getModernImageSrc } from '../../common/ModernImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { printDateTimeWithWeekdayCompactPretty } from '../../../assets/utils/utils';
import { ErrorBoundary } from '@sentry/react';
import ErrorBoundaryFallback from '../../common/ErrorBoundaryFallback';
import styles from './CheckoutReceiptCourseItem.module.scss';
import { round } from 'lodash';

/**
 * @param {} course -- The course should be recursively filtered so that it only includes sessions & events in the user's cart. It should also be annotated with `purchasedViaSubscription` if appropriate. (Note: The `getFilteredCoursesForTicketsInCart` and `getRecursivelyFilteredCoursesForPurchasedTickets` selectors return exactly what we need here.)
 */
const CheckoutReceiptCourseItem = ({ course, photoIsVisible = true, className = '' }) => {
  if (!course) return null;

  return (
    <ErrorBoundary fallback={ErrorBoundaryFallback()}>
      <Row className={className}>
        {/* Course Header */}
        <Col xs={12} className="text-center">
          {/* 
          TODO also allow courses to use a photo that an admin has uploaded
          to S3 (included in the course object as `photo`).
        */}
          {course.photoPath && photoIsVisible && (
            <ModernImage
              fluid
              src={getModernImageSrc(course.photoPath)}
              alt={course.name}
              className="d-none d-md-inline-block d-print-none"
            />
          )}
        </Col>
        <Col xs={12} className="mt-3">
          <Row>
            <Col as="h3" className={styles.courseName}>
              {course.name}
            </Col>
            {course.subscriptionProducts?.length > 0 && (
              <Col xs="auto" className={styles.courseSubscriptionPrice}>
                ${round(parseFloat(course.subscriptionProducts[0].price))}/
                {course.subscriptionProducts[0].billingPeriod}
              </Col>
            )}
          </Row>
        </Col>

        {/* Session Information */}
        <Col xs={12}>
          {course.courseSessions.map((session) => (
            <Row
              key={`checkout-receipt-course-item-session-${session.id}`}
              className={`${styles.session} mt-2`}
            >
              {/* Course Session Information */}
              <Col xs={12}>
                <Row>
                  {/* This column will fill the remaining space since its sibling is set to 'auto' size */}
                  <Col>
                    Session {session.displayOrder + 1}: {session.name}
                  </Col>
                  {session.price && <Col xs="auto">${session.price}</Col>}
                </Row>

                {/* Course Session Event Information */}
                {session.courseSessionEvents.map((event, i) => (
                  <Row
                    key={`checkout-receipt-course-item-session-event-${event.id}`}
                    xs={12}
                    className={`${styles.event}`}
                  >
                    <Col xs={12}>
                      <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                      {printDateTimeWithWeekdayCompactPretty(event.date)}
                    </Col>
                    {/* Only show the location if two things are true:
                        1. The user has purchased tickets for less than 6 sessions for this course.
                        2. The current event is the last child for this session.
                     */}
                    {course.courseSessions.length < 6 &&
                      i === session.courseSessionEvents.length - 1 && (
                        <Col xs={12}>
                          <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                          {session.zoomLink ? 'Zoom' : session.location}
                        </Col>
                      )}
                  </Row>
                ))}
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </ErrorBoundary>
  );
};

export default CheckoutReceiptCourseItem;
