import { sortBy } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';

export const getTickets = (state) => state.tickets.all || [];
export const getPostCheckoutQuestionResponses = (state) =>
  state.tickets.postCheckoutQuestionResponses || [];

// Sort the responses by ID (after sorting by name, of course) so that if a
// student has bought multiple tickets for the same event, we display the
// post-checkout question responses in the proper order.
export const getTicketsSorted = createSelector([getTickets], (tickets) =>
  sortBy(tickets, ['studentLastName', 'studentFirstName', 'id'])
);

// Sort the responses by ID so that if a student has bought multiple tickets
// for the same event, we display the responses in the order they were submitted.
export const getPostCheckoutQuestionResponsesSorted = createSelector(
  [getPostCheckoutQuestionResponses],
  (postCheckoutQuestionResponses) => sortBy(postCheckoutQuestionResponses, 'id')
);
