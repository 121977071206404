import axios from 'axios';
import { getMeaningfulErrorMessage } from '../../redux/utils';

export const createSubscriptionOnServer = async (payload) => {
  if (
    !payload.hasOwnProperty('email') ||
    !payload.hasOwnProperty('studentFirstName') ||
    !payload.hasOwnProperty('studentLastName') ||
    !payload.hasOwnProperty('ticketLocks') ||
    !payload.hasOwnProperty('postCheckoutQuestionsVerificationHash') ||
    !payload.hasOwnProperty('customerId') ||
    !payload.hasOwnProperty('appliedCoupons') ||
    !payload.hasOwnProperty('paymentMethodId') ||
    !payload.hasOwnProperty('priceId')
  ) {
    throw new Error(
      'Missing Parameter: createSubscriptionOnServer requires that the payload contain the <email>, <studentFirstName>, <studentLastName>, <ticketLocks>, <postCheckoutQuestionsVerificationHash>, <customerId>, <paymentMethodId>, and <priceId> for the subscription'
    );
  }

  try {
    const response = await axios.post('/api/subscription-schedules', payload);
    return response.data;
  } catch (error) {
    throw new Error(
      getMeaningfulErrorMessage(error, 'Error creating Subscription. Please try again.')
    );
  }
};

/**
 * Not yet implemented. We'll need to figure out a secure way of
 * making sure that the user actually has permission to delete this subscription.
 *
 * @param {*} payload
 * @returns
 */
export const deleteSubscriptionOnServer = async (payload) => {
  if (!payload.hasOwnProperty('scheduleId')) {
    throw new Error(
      'Missing Parameter: deleteSubscriptionOnServer requires that the payload contain the <scheduleId>'
    );
  }

  try {
    return await axios.delete(`/api/subscriptions/${payload.scheduleId}`);
  } catch (error) {
    throw new Error(
      getMeaningfulErrorMessage(error, 'Error deleting Subscription. Please try again.')
    );
  }
};
