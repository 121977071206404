import React from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './ErrorPage.module.scss';

function ErrorPage({ message }) {
  return (
    <Container fluid className={`${styles.container} min-height-page-container`}>
      <Helmet>
        <title>404 Error</title>
      </Helmet>
      <Row className="text-center mt-xl-5">
        <Col className="mt-5">
          <div className="h1">{message || 'Page Not Found'}</div>
        </Col>
      </Row>
      {!message && (
        <Row className="text-center">
          <Col>
            <div className={styles.hugeIcon}>
              {/* <FontAwesomeIcon icon={faExclamationCircle} /> */}
              404
            </div>
          </Col>
        </Row>
      )}
    </Container>
  );
}

export default ErrorPage;
