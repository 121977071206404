import { useMemo } from 'react';
import { useLocation } from 'react-router';

// TODO write tests for this hook
/** useNavbarIsFixed
 *
 * @returns True if and only if the user is on the home page.
 */
export function useNavbarIsFixed() {
  const location = useLocation();
  const navbarIsFixed = useMemo(() => location.pathname !== '/' && location.pathname !== '/index', [
    location.pathname,
  ]);
  return navbarIsFixed;
}
