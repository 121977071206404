import React, { useMemo } from 'react';
import { useQueryParam, ArrayParam } from 'use-query-params';
import { filter } from 'lodash';
import styles from './SubjectFilterBadge.module.scss';

// TODO write tests for this component
const SubjectFilterBadge = ({ name, queryString, className = '', ...props }) => {
  const [subjects, setSubjects] = useQueryParam('subject', ArrayParam);

  const subjectIsSelected = useMemo(() => {
    if (!subjects?.length) {
      return queryString === 'all';
    }

    return subjects.includes(queryString);
  }, [subjects, queryString]);

  return (
    <button
      className={`${styles.badge} ${subjectIsSelected && styles.selected} ${className}`}
      onClick={() => {
        if (queryString === 'all') {
          setSubjects([]);
        } else if (!subjects?.length) {
          setSubjects([queryString]);
        } else if (subjects.includes(queryString)) {
          setSubjects(filter(subjects, (subject) => subject !== queryString));
        } else {
          setSubjects([...subjects, queryString]);
        }
      }}
      {...props}
    >
      {name}
    </button>
  );
};

export default SubjectFilterBadge;
