import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchPostCheckoutQuestionResponses,
  fetchTickets,
} from '../../redux/features/tickets/ticketsSlice';
import {
  getPostCheckoutQuestionResponsesSorted,
  getTicketsSorted,
} from '../../redux/features/tickets/ticketsSelector';
import { getPostCheckoutQuestions } from '../../redux/features/courses/coursesSelector';
import {
  cloneDeep,
  drop,
  filter,
  groupBy,
  head,
  isEmpty,
  isUndefined,
  keyBy,
  map,
  mapValues,
  reject,
} from 'lodash';
import {
  fetchPostCheckoutQuestions,
  setActiveCourse,
  setActiveCourseSessionEvent,
} from '../../redux/features/courses/coursesSlice';
import DashboardHeader from './dashboard/DashboardHeader';
import CourseSessionEventTable from './dashboard/CourseSessionEventTable';
import styles from './DashboardCourseSessionEvent.module.scss';

const DashboardTicketOverview = () => {
  const dispatch = useDispatch();
  const { activeCourseId, activeCourseSessionId, activeCourseSessionEventId } = useParams();
  const tickets = useSelector(getTicketsSorted);
  const postCheckoutQuestionResponses = useSelector(getPostCheckoutQuestionResponsesSorted);
  const allPostCheckoutQuestions = useSelector(getPostCheckoutQuestions);

  const activeCourseIdInt = useMemo(() => {
    return activeCourseId ? parseInt(activeCourseId) : undefined;
  }, [activeCourseId]);

  const activeCourseSessionIdInt = useMemo(() => {
    return activeCourseSessionId ? parseInt(activeCourseSessionId) : undefined;
  }, [activeCourseSessionId]);

  const activeCourseSessionEventIdInt = useMemo(() => {
    return activeCourseSessionEventId ? parseInt(activeCourseSessionEventId) : undefined;
  }, [activeCourseSessionEventId]);

  // Fetch all of the data we need for the table (tickets, questions, and question responses).
  useEffect(() => {
    if (activeCourseIdInt && activeCourseSessionEventIdInt) {
      dispatch(fetchPostCheckoutQuestions({ courseId: activeCourseIdInt }));
      dispatch(fetchTickets({ courseSessionEventId: activeCourseSessionEventIdInt }));
      dispatch(
        fetchPostCheckoutQuestionResponses({ courseSessionEventId: activeCourseSessionEventIdInt })
      );
    }

    // Set the active courseSessionEvent here so that later,
    // we can store tickets for multiple courseSessionEvents at one time.
    dispatch(setActiveCourseSessionEvent({ id: activeCourseSessionEventIdInt }));
    dispatch(setActiveCourse({ id: activeCourseIdInt }));

    return () => {
      dispatch(setActiveCourseSessionEvent({ id: undefined }));
      dispatch(setActiveCourse({ id: undefined }));
    };
  }, [dispatch, activeCourseIdInt, activeCourseSessionEventIdInt]);

  // Get a list of all of the supplementary questions that are associated with this event.
  const postCheckoutQuestions = useMemo(() => {
    if (!activeCourseIdInt || !activeCourseSessionIdInt || isEmpty(allPostCheckoutQuestions)) {
      return [];
    }
    return filter(allPostCheckoutQuestions, (question) => {
      // For session-scoped questions, only show the question if it belongs to the active session.
      if (question.courseSession) {
        return question.courseSession === activeCourseSessionIdInt;
      }
      // For course-scoped questions, show the question if it belongs to the active course.
      return question.course === activeCourseIdInt;
    });
  }, [allPostCheckoutQuestions, activeCourseIdInt, activeCourseSessionIdInt]);

  ////// To prepare for appending the post-checkout question responses to the //////
  ////// list of tickets, we must do the following:                           //////
  // Convert the list of post-checkout question responses to a dictionary, like so:
  // preliminaryResult = {
  //    studentId1: [response1, response2, ...]
  //    studentId2: [response1, response2, ...]
  //    ...
  // }
  // Each array will probably only contain one response per question, but if a student
  // buys multiple tickets for the same event (a weird edge case), the array may contain
  // 2n, 3n, 4n, ..., jn elements, where n is the number of questions and j is the number
  // of tickets the student has bought for this event. Complicating this matter further,
  // students may only answer some of the questions. So we'll also transform the inner arrays
  // into dictionaries grouped by the questionIds, as seen below.
  // finalResult = {
  //    studentId1: {
  //                  questionId1: [response1, response2, ...]
  //                  questionId2: [response1, response2, ...]
  //                  questionId3: [response1, response2, ...]
  //                  ...
  //                }
  //    studentId2: {
  //                  questionId1: [response1, response2, ...]
  //                  questionId2: [response1, response2, ...]
  //                  questionId3: [response1, response2, ...]
  //                  ...
  //                }
  //    ...
  // }
  // This data structure will allow us to correctly map the response data to the tickets.
  const postCheckoutQuestionResponsesDictionary = useMemo(() => {
    if (isEmpty(postCheckoutQuestionResponses)) return {};
    const preliminaryResult = groupBy(postCheckoutQuestionResponses, 'student');
    const finalResult = mapValues(preliminaryResult, (responseArray) =>
      groupBy(responseArray, 'question')
    );
    return finalResult;
  }, [postCheckoutQuestionResponses]);

  // Now, let's add the response data to those tickets!
  const ticketsAnnotated = useMemo(() => {
    if (isEmpty(tickets)) return;
    let mutablePostCheckoutQuestionResponseDictionary = cloneDeep(
      postCheckoutQuestionResponsesDictionary
    );
    const ticketsWithPostCheckoutQuestionResponses = map(tickets, (ticket) => {
      // All data inside this mapping function belongs to a single student (& a single ticket for that matter).
      // In most cases, the responsesToThisQuestionForAllTickets arrays will
      // contain only one element for each question the student has answered.
      // However, if a student has bought duplicate tickets, it can contain
      // a bunch of elements. Hence the challenge.
      let responsesForAllTicketsDictionary =
        mutablePostCheckoutQuestionResponseDictionary[ticket.student] || {};
      const responsesForThisTicket = map(postCheckoutQuestions, (question) => {
        // Pull out one response for each question.
        // Note that we mutate the mutablePostCheckoutQuestionResponseDictionary here,
        // so that the next ticket can pull out the next response, etc...
        const responsesToThisQuestionForAllTickets = responsesForAllTicketsDictionary[question.id];
        if (responsesToThisQuestionForAllTickets?.length) {
          // We'll store the first response so that we can return it.
          const currentResponse = head(responsesToThisQuestionForAllTickets);

          // Then, we'll drop that response from the mutablePostCheckoutQuestionResponseDictionary.
          responsesForAllTicketsDictionary[question.id] = drop(
            responsesToThisQuestionForAllTickets
          );

          // Return the response we stored!
          return currentResponse;
        }
        // If we get here, then the student didn't answer this question.
        return;
      });
      const responsesForThisTicketPruned = reject(responsesForThisTicket, isUndefined);
      const responsesForThisTicketDictionary = keyBy(responsesForThisTicketPruned, 'question');
      return {
        ...ticket,
        postCheckoutQuestionResponseDictionary: responsesForThisTicketDictionary,
      };
    });
    return ticketsWithPostCheckoutQuestionResponses;
  }, [tickets, postCheckoutQuestions, postCheckoutQuestionResponsesDictionary]);

  return (
    <Container className={`min-height-page-container pb-3 ${styles.container}`}>
      <DashboardHeader pageTitle="Course Session Event" />
      <CourseSessionEventTable
        postCheckoutQuestions={postCheckoutQuestions}
        tickets={ticketsAnnotated}
      />
    </Container>
  );
};

export default DashboardTicketOverview;
