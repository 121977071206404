import React, { useEffect } from 'react';
import './App.scss'; // Goes before AppRoutes and other components so that the stylesheets cascade correctly.
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ToastContainer, Slide } from 'react-toastify';
import { ErrorBoundary } from '@sentry/react';
import ErrorBoundaryFallback from './components/common/ErrorBoundaryFallback';
import { initializeGoogleAnalytics } from './assets/utils/GoogleAnalytics';
import { askmeSummaryLong } from './assets/constants/constants';
import { getCookie } from 'react-use-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUser } from './redux/features/user/userSlice';
import AppRoutes from './routes/routes';
import ScrollToTop from './routes/ScrollToTop';
import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import { getUserIsInstructor, getUserIsAdmin } from './redux/features/user/userSelector';
const App = () => {
  const dispatch = useDispatch();
  const isAuthenticated = getCookie('isAuthenticated') === 'true';
  let isInstructor = false;
  let isAdmin = false;

  // Initialize Google Analytics when the component first loads.
  useEffect(() => {
    initializeGoogleAnalytics();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      // ISAAC -- this is how you fetch stuff from the API
      // The way I've designed fetchUser means that it will fetch the user from
      // the backend API, then it will store the returned value in the
      // Redux store. You can then access it in any component via
      // `const currentUser = useSelector(getUser);`
      dispatch(fetchUser());
    }
  }, [dispatch, isAuthenticated]);

  // const currentUser = useSelector(getUser);

  isInstructor = useSelector(getUserIsInstructor)
  isAdmin = useSelector(getUserIsAdmin);
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <ScrollToTop />
        <div className="App">
          <Helmet>
            <meta name="description" content={askmeSummaryLong} />
          </Helmet>
          <ErrorBoundary fallback={ErrorBoundaryFallback({ className: 'py-2 fixed-top h4' })}>
            <Navbar isAuthenticated={isAuthenticated} />
          </ErrorBoundary>
          <ErrorBoundary
            fallback={ErrorBoundaryFallback({ className: 'min-height-page-container pt-5 my-0' })}
          >
            <AppRoutes
              isAuthenticated={isAuthenticated}
              isInstructor={isInstructor}
              isAdmin={isAdmin}
            />
          </ErrorBoundary>
          <Footer />
          <ErrorBoundary fallback={ErrorBoundaryFallback()}>
            <ToastContainer
              position="top-center"
              autoClose={10000}
              transition={Slide}
              newestOnTop={false}
              hideProgressBar
              closeOnClick
              pauseOnFocusLoss
              pauseOnHover
              limit={5}
            />
          </ErrorBoundary>
        </div>
      </QueryParamProvider>
    </Router>
  );
};

export default App;
