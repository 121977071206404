import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { faChalkboardTeacher, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faGrinBeam } from '@fortawesome/free-regular-svg-icons';
import FastFact from './fast-facts/FastFact';
import homeStyles from '../Home.module.scss';
import styles from './FastFacts.module.scss';

function FastFacts() {
  return (
    <Row as="section" className={`${homeStyles.sectionContainer} ${styles.container}`}>
      <Col xs={12} md={{ span: 10, offset: 1 }}>
        <Row>
          {/* TODO add a photo to the right of the fast facts */}
          <Col xs={12} lg={{ span: 10, offset: 2 }} xl={{ span: 9, offset: 3 }}>
            {/* TODO replace these numbers with real numbers */}
            <FastFact icon={faChalkboardTeacher} number="350+" unit="courses taught" />
            <FastFact icon={faUsers} number="5,000+" unit="students engaged" />
            <FastFact icon={faGrinBeam} number="95%" unit="satisfaction rate" />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default FastFacts;
