import React from 'react';
import { useDispatch } from 'react-redux';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck } from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import { trackCourseEvent } from '../../../../assets/utils/GoogleAnalytics';
import { courseSessionEventIsOpen } from '../../../../assets/utils/utils';
import { addTicketToCart, removeTicketFromCart } from '../../../../redux/features/cart/cartSlice';
import styles from './AddToCartButton.module.scss';

function AddToCartButton({ session, selectedEvent, selectedEventIsInCart }) {
  const dispatch = useDispatch();

  const addEventToCart = (courseId, courseSessionId, courseSessionEventId, courseSessionEvent) => {
    if (courseSessionEventIsOpen(courseSessionEvent)) {
      trackCourseEvent('Added Course Session Event To Cart', courseSessionEventId);
      dispatch(addTicketToCart({ courseId, courseSessionId, courseSessionEventId }));
    }
  };

  const removeEventFromCart = (courseSessionEventId) => {
    trackCourseEvent('Removed Course Session Event From Cart', courseSessionEventId);
    dispatch(removeTicketFromCart({ courseSessionEventId }));
  };

  // If the selectedEvent has no price, then don't allow the user to add it
  // to their cart individually (a null price means they likely have to either
  // buy a course bundle or a course subscription).
  if (!session?.price) {
    return null;
  }

  return (
    <Col xs={12} className="mt-3 mb-2 text-center">
      {session ? (
        <>
          {selectedEvent?.ticketsAvailable > 0 ? (
            <>
              {selectedEventIsInCart ? (
                <button
                  type="button"
                  className={`askme-btn w-100 ${styles.button}`}
                  onClick={() => removeEventFromCart(selectedEvent?.id)}
                >
                  <FontAwesomeIcon icon={faCheck} className="mr-3" />
                  Added to cart
                </button>
              ) : (
                <button
                  type="button"
                  className={`askme-btn-inverted w-100 ${styles.button}`}
                  onClick={() =>
                    addEventToCart(session.course, session.id, selectedEvent.id, selectedEvent)
                  }
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-3" />
                  Add to cart
                </button>
              )}
            </>
          ) : (
            <strong className="color-secondary">
              This session is currently full.
              <br />
              Check again later to see if any spots become available.
            </strong>
          )}
        </>
      ) : (
        <Skeleton height={38} />
      )}
    </Col>
  );
}

export default AddToCartButton;
