import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faLink,
  faMapMarkerAlt,
  faCalendarDay,
} from '@fortawesome/free-solid-svg-icons';
import { first } from 'lodash';
import { printAddressPretty, printDateTimeWithWeekdayPretty } from '../../../assets/utils/utils';
import { BASE_URL } from '../../../assets/constants/constants';
import { ErrorBoundary } from '@sentry/react';
import ErrorBoundaryFallback from '../../common/ErrorBoundaryFallback';
import ReadMore from '../../common/ReadMore';
import styles from './CourseSessionInstructionCard.module.scss';

function getCourseSessionMaterialsLink(courseId, courseSessionId, courseSessionMaterialsId) {
  return `${BASE_URL}/courses/${courseId}/course-sessions/${courseSessionId}/materials?pwd=${courseSessionMaterialsId}`;
}

const CourseSessionInstructionCard = ({ courseSession, className, ...props }) => {
  return (
    <ErrorBoundary fallback={ErrorBoundaryFallback()}>
      <Row className={`${styles.instructionCard} shadow-sm p-3 ${className}`} {...props}>
        <Col xs={12} className={`font-weight-bold`}>
          <h6 className="my-0">{courseSession.name}</h6>
        </Col>

        {courseSession.courseSessionEvents.length > 0 && (
          <Col>
            <FontAwesomeIcon icon={faCalendarDay} className="mr-3" />
            {/*
              We can just select the first event in the list, because it should be the only event!
              TODO eventually users should be able to buy tickets for multiple events
              for the same session. When they can, we'll have to change this component a bit.
            */}
            {printDateTimeWithWeekdayPretty(first(courseSession.courseSessionEvents).date)}
          </Col>
        )}

        {courseSession.courseSessionMaterials?.length > 0 && (
          <Col xs={12}>
            <>
              <FontAwesomeIcon icon={faLink} className="mr-3" />
              {/* TODO: make this link a button for copying, not an actual link */}
              Materials:{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getCourseSessionMaterialsLink(
                  courseSession.course,
                  courseSession.id,
                  courseSession.courseSessionMaterials[0]
                )}
              >
                {getCourseSessionMaterialsLink(
                  courseSession.course,
                  courseSession.id,
                  courseSession.courseSessionMaterials[0]
                )}
              </a>
              {/* TODO add a copy button */}
            </>
          </Col>
        )}

        <Col xs={12}>
          {courseSession.zoomLink?.url ? (
            <>
              <FontAwesomeIcon icon={faLink} className="mr-3" />
              {/* TODO: make the zoom link a button for copying, not an actual link */}
              Zoom Link:{' '}
              <a target="_blank" rel="noopener noreferrer" href={courseSession.zoomLink.url}>
                {courseSession.zoomLink.url}
              </a>
              {/* TODO add a copy button */}
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-3" />
              {printAddressPretty(courseSession.address)}
            </>
          )}
        </Col>

        {/* TODO remove courseSession.instructions !== '_' 
            after migrating placeholder instructions to null instructions */}
        {courseSession.instructions && courseSession.instructions !== '_' && (
          <Col xs={12}>
            <FontAwesomeIcon icon={faInfoCircle} className="mr-3" />
            <ReadMore text={courseSession.instructions} length={180} displayInline={true} />
          </Col>
        )}
      </Row>
    </ErrorBoundary>
  );
};

export default CourseSessionInstructionCard;
