import React from 'react';
import AskMeTippy from './AskMeTippy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './IconWithTooltip.scss';

const IconWithTooltip = ({ icon, content, className = '', ...props }) => {
  return (
    <AskMeTippy content={content}>
      <span
        className={'ml-2 cursor-pointer ' + className}
        aria-label="Click for more information"
        tabIndex="0"
        {...props}
      >
        <FontAwesomeIcon icon={icon} />
      </span>
    </AskMeTippy>
  );
};

export default IconWithTooltip;
