import axios from 'axios';
import { getMeaningfulErrorMessage } from '../../redux/utils';

export const fetchUserFromServer = async function () {
  try {
    let response = await axios.get('/api/users/current');
    return response.data;
  } catch (error) {
    throw new Error(getMeaningfulErrorMessage(error, 'Error fetching user'));
  }
};
