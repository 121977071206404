import React from 'react';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import { map, isEmpty } from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { printDateTimePretty } from '../../../assets/utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTickets } from '../../../redux/features/tickets/ticketsSlice';
import { getUser, getUserIsAdmin } from '../../../redux/features/user/userSelector';
import AskMeTippy from '../../common/AskMeTippy';
import dashboardStyles from './DashboardStyles.module.scss';
import styles from './CourseSessionEventsTable.module.scss';

const tableHeaders = ['Name', 'Date', 'Location', 'Tickets Sold', 'Total Spots', 'Copy Emails'];

const CourseSessionEventsTable = ({ courseSessionEvents }) => {
  const { activeCourseId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const userIsAdmin = useSelector(getUserIsAdmin);

  const copyStudentEmailsToClipboard = async (courseSessionEventId) => {
    // Grab the emails from each ticket in this sessions
    const tickets = await dispatch(fetchTickets({ courseSessionEventId }));
    const emails = map(tickets, 'email');

    if (isEmpty(emails)) {
      toast.error('Could not copy the emails to the clipboard.');
      return;
    }

    // Write the emails to the clipboard.
    copy(emails);
    toast.success('Copied the emails to the clipboard!');
  };

  const copyZoomLinkToClipboard = async (courseSession) => {
    // Fetch the full courseSession object from the
    if (!courseSession) {
      // Any error here means that we've accidentally stopped pulling the
      // course session within a component that uses this component (probably DashboardCourseSessionEvents.js)
      toast.error(
        'Failed to copy the Zoom link to the clipboard. Please contact the webmaster to resolve this problem.'
      );
      return;
    }

    // Write the emails to the clipboard.
    copy(courseSession.zoomLink.url);
    toast.success('Copied the Zoom link to the clipboard!');
  };

  return (
    <Table responsive className={dashboardStyles.table}>
      <thead>
        <tr>
          {tableHeaders.map((header) => (
            <th key={`course-session-event-table-header-${header}`}>{header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {courseSessionEvents.map((courseSessionEvent) => {
          const userIsAdminOrTeachesCourse =
            userIsAdmin || user.id === courseSessionEvent.courseSession.instructor;
          return (
            <tr key={`course-session-event-table-${courseSessionEvent.id}`}>
              {/* Session name */}
              <td>
                <Link
                  to={`/dashboard/courses/${activeCourseId}/course-sessions/${courseSessionEvent.courseSession.id}/course-session-events/${courseSessionEvent.id}`}
                  className={`clear-link-styling ${dashboardStyles.tableLink} ${
                    userIsAdminOrTeachesCourse ? '' : 'disabled'
                  }`}
                  onClick={(e) => {
                    if (!userIsAdminOrTeachesCourse) {
                      e.preventDefault();
                    }
                  }}
                >
                  {courseSessionEvent.courseSession.name}
                </Link>
              </td>

              {/* Date*/}
              <td>{printDateTimePretty(courseSessionEvent.date)}</td>

              {/* Location */}
              <td>
                {courseSessionEvent.courseSession.zoomLink ? (
                  <AskMeTippy
                    content={'Copy the Zoom link for this event to the clipboard.'}
                    disabled={!userIsAdminOrTeachesCourse}
                  >
                    <button
                      className={`${styles.copyButton} d-inline-block`}
                      onClick={() => copyZoomLinkToClipboard(courseSessionEvent.courseSession)}
                      disabled={!userIsAdminOrTeachesCourse}
                    >
                      {userIsAdminOrTeachesCourse && (
                        <FontAwesomeIcon icon={faCopy} className="mr-2" />
                      )}
                      Zoom
                    </button>
                  </AskMeTippy>
                ) : (
                  courseSessionEvent.courseSession.address
                )}
              </td>

              {/* Tickets Sold */}
              <td>{courseSessionEvent.ticketsCap - courseSessionEvent.ticketsAvailable}</td>

              {/* Tickets Available */}
              <td>{courseSessionEvent.ticketsCap}</td>

              {/* Copy Emails */}
              <td>
                <AskMeTippy
                  content={'Copy the emails of the attendees of this event to the clipboard.'}
                >
                  <button
                    className={`${styles.copyButton} d-inline-block`}
                    onClick={() => copyStudentEmailsToClipboard(courseSessionEvent.id)}
                    disabled={!userIsAdminOrTeachesCourse}
                  >
                    {userIsAdminOrTeachesCourse && (
                      <FontAwesomeIcon icon={faCopy} className="mr-2" />
                    )}
                    Emails
                  </button>
                </AskMeTippy>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default CourseSessionEventsTable;
