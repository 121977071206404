import { padStart, isString } from 'lodash';
import { DateTime } from 'luxon';

// Put random JavaScript utility functions in here if
// they are likely to be used by different components.

export const executeOnEnter = (event, callback) => {
  if (event.which === 13 || event.keyCode === 13 || event.key === 'Enter') {
    callback(event);
  }
};

export const printAddressPretty = (address) => {
  if (!address) return '';
  return `${address.street_number} ${address.route}, ${address.locality.name}, ${address.locality.state.name} ${address.locality.postal_code}`;
};

export const getDateTimeFromMillisOrISOString = (date) => {
  if (!date) {
    return;
  } else if (isString(date)) {
    return DateTime.fromISO(date);
  } else {
    return DateTime.fromMillis(date);
  }
};

export const printDatePretty = (date) => {
  const dt = getDateTimeFromMillisOrISOString(date);
  if (dt) return dt.toLocaleString({ month: 'long', day: 'numeric' });
};

export const printDateCompactPretty = (date) => {
  const dt = getDateTimeFromMillisOrISOString(date);
  if (dt) return dt.toLocaleString({ month: 'numeric', day: 'numeric' });
};

export const printDateTimePretty = (date) => {
  const dt = getDateTimeFromMillisOrISOString(date);
  if (dt)
    return dt.toLocaleString({ month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit' });
};

export const printTimePretty = (date) => {
  const dt = getDateTimeFromMillisOrISOString(date);
  if (dt) return dt.toLocaleString({ hour: 'numeric', minute: '2-digit' });
};

export const printDateWithWeekdayPretty = (date) => {
  const dt = getDateTimeFromMillisOrISOString(date);
  if (dt) return dt.toLocaleString({ weekday: 'long', month: 'long', day: 'numeric' });
};

export const printDateTimeWithWeekdayPretty = (date) => {
  const dt = getDateTimeFromMillisOrISOString(date);
  if (dt)
    return dt.toLocaleString({
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    });
};

export const printDateTimeWithWeekdayCompactPretty = (date) => {
  const dt = getDateTimeFromMillisOrISOString(date);
  if (dt)
    return dt.toLocaleString({
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
    });
};

export const printDateRangeString = (startDate, endDate, compact = false) => {
  if (!startDate || !endDate) return <></>;
  return (
    <>
      {startDate.toLocaleString({ month: compact ? 'short' : 'long', day: 'numeric' })}
      &nbsp;-&nbsp;
      {startDate.month === endDate.month
        ? endDate.toLocaleString({ day: 'numeric' })
        : endDate.toLocaleString({ month: compact ? 'short' : 'long', day: 'numeric' })}
    </>
  );
};

export const printDigit = (number) => {
  switch (number) {
    case 0:
      return 'zero';
    case 1:
      return 'one';
    case 2:
      return 'two';
    case 3:
      return 'three';
    case 4:
      return 'four';
    case 5:
      return 'five';
    case 6:
      return 'six';
    case 7:
      return 'seven';
    case 8:
      return 'eight';
    case 9:
      return 'nine';
    default:
      return '';
  }
};

// E.g. prints 8m 34s
export const printDurationPretty = (
  durationInMilliseconds,
  showDecimals = false,
  padMinutes = false,
  verbose = false
) => {
  const secondsDescription = verbose ? ' seconds' : 's';
  const minutesDescription = verbose ? ' minutes' : 'm';
  const hoursDescription = verbose ? ' hours' : 'h';
  let hours = Math.floor(durationInMilliseconds / 3600000);
  durationInMilliseconds %= 3600000;
  let minutes = Math.floor(durationInMilliseconds / 60000);
  durationInMilliseconds %= 60000;
  let seconds = Math.floor(durationInMilliseconds / 1000);
  let decimalString = secondsDescription;
  if (showDecimals) {
    decimalString =
      '.' + padStart(Math.round((durationInMilliseconds % 1000) / 10), 2, '0') + secondsDescription;
  }
  if (hours) {
    minutes = padStart(minutes + minutesDescription, padMinutes ? 3 : 2, '0');
    seconds = padStart(seconds, 2, '0');
    return hours + hoursDescription + ' ' + minutes + ' ' + seconds + decimalString;
  } else {
    minutes = padStart(minutes + minutesDescription, padMinutes ? 3 : 2, '0');
    seconds = padStart(seconds, 2, '0');
    return minutes + ' ' + seconds + decimalString;
  }
};

export const courseSessionEventIsOpen = (event) => {
  if (!event) return false;
  return (
    event.ticketsAvailable > 0 && getDateTimeFromMillisOrISOString(event.date) > DateTime.utc()
  );
};

export const scrollWithOffset = (offset = 0) => {
  return function (element) {
    const yCoordinate = element.getBoundingClientRect().top + window.pageYOffset;
    window.scrollTo({ top: yCoordinate + offset, behavior: 'smooth' });
  };
};
