import axios from 'axios';
import { groupBy, map, reduce, toPairs } from 'lodash';
import * as Sentry from '@sentry/react';
import { createCustomerOnServer } from '../customers/CustomersAPI';
import {
  createSubscriptionOnServer,
  deleteSubscriptionOnServer,
} from '../subscriptions/SubscriptionsAPI';
import { updateTicketLocks } from '../../redux/features/cart/cartSlice';
import { DateTime } from 'luxon';

/**
 * Purchases all of the tickets in ticketLocks via Stripe's PaymentIntents API.
 *
 * @param {*} stripe A reference to the stripe object obtained via the useStripe hook, etc...
 * @param {*} paymentMethod A Stripe payment method or an object containing {card, billingDetails: {email}}.
 * @param {*} paymentIntentPostBody The data to include in the body of the POST /api/payment-intents request.
 * @returns The response received after successfully calling stripe.confirmCardPayment.
 */
export const purchaseTicketsOutright = async (
  stripe,
  paymentMethod,
  {
    ticketLocks,
    appliedCoupons,
    email,
    studentFirstName,
    studentLastName,
    postCheckoutQuestionsVerificationHash,
    stripeCustomerId,
  }
) => {
  // First, create a PaymentIntent for this purchase via the AskMe backend.
  const paymentIntentResponse = await axios.post('/api/payment-intents', {
    ticketLocks,
    appliedCoupons,
    email,
    studentFirstName,
    studentLastName,
    postCheckoutQuestionsVerificationHash,
    stripeCustomerId,
  });
  const paymentIntent = paymentIntentResponse.data;

  // Place the one-off payment via Stripe.
  const result = await stripe.confirmCardPayment(paymentIntent.clientSecret, {
    payment_method: paymentMethod,
  });

  // Check to make sure that the one-off payment went through.
  // If it didn't, let's show an error message and exit.
  if (result.error) {
    throw Error(result.error.message);
  }

  return result;
};

/**
 * Creates a subscription and executes the first payment.
 *
 * @param {*} stripe A reference to the stripe object obtained via the useStripe hook, etc...
 * @param {*} cardElement A reference to the cardElement provided by Stripe.
 * @param {*} ticketLocks The ticketLocks for all of the events that the user is subscribing to.
 * @param {*} subscriptionProductPriceIds
 * @param {*} email
 * @param {*} studentFirstName
 * @param {*} studentLastName
 * @param {*} postCheckoutQuestionsVerificationHash
 * @returns [paymentMethodId, customer]
 */
export const purchaseSubscriptions = async (
  dispatch,
  stripe,
  cardElement,
  ticketLocks,
  subscriptionProductPriceIds,
  appliedCoupons,
  email,
  studentFirstName,
  studentLastName,
  postCheckoutQuestionsVerificationHash
) => {
  // Create the payment method for the subscriptions.
  const createPaymentMethodResponse = await stripe.createPaymentMethod({
    type: 'card',
    card: cardElement,
    billing_details: {
      email,
    },
  });

  if (!createPaymentMethodResponse?.paymentMethod?.id) {
    throw Error('Could not process this payment method. Please try again');
  }

  /*
  Group the tickets together by course, so that we can make one call
  to POST /api/subscription-schedules for each course.
  ticketLocksSubscribedToGroupedByCourse will look like:
  {
    courseId1: [ticketLock1, ticketLock2, ...],
    courseId2: [ticketLock1, ticketLock2, ...],
    ...
  }
  */
  const ticketLocksSubscribedToGroupedByCourse = groupBy(ticketLocks, 'course');

  // Then, create a Stripe Customer.
  const customer = await createCustomerOnServer({ email });

  // Map the courses + ticketLocks to a set of createSubscriptionCalls.
  // createSubscriptionCalls is a list of Promises that will call
  // POST /api/subscription-schedules once for each course.
  const createSubscriptionCalls = map(
    toPairs(ticketLocksSubscribedToGroupedByCourse),
    ([courseId, currentTicketLocks]) => {
      const currentPriceId = subscriptionProductPriceIds.get(courseId);
      const createSubscriptionPromise = createSubscriptionOnServer({
        ticketLocks: currentTicketLocks,
        customerId: customer.id,
        paymentMethodId: createPaymentMethodResponse.paymentMethod.id,
        priceId: currentPriceId,
        appliedCoupons,
        email,
        studentFirstName,
        studentLastName,
        postCheckoutQuestionsVerificationHash,
      });

      return createSubscriptionPromise;
    }
  );

  const createSubscriptionResponses = await Promise.allSettled(createSubscriptionCalls);

  const numberOfRejectedRequests = reduce(
    createSubscriptionResponses,
    (prev, response) => {
      if (response.status === 'rejected') {
        return prev + 1;
      }
      return prev;
    },
    0
  );

  // Check for rejected requests.
  if (numberOfRejectedRequests > 0) {
    if (
      createSubscriptionCalls.length > 1 &&
      createSubscriptionCalls.length !== numberOfRejectedRequests
    ) {
      // TODO cancel the subscriptions that succeeded.
      // If some subscriptions failed & some didn't, then we need to send an alert to the site admins.
      // Let's send errors to Sentry so that admins can manually
      // create the failed subscriptions & figure out what went wrong.
      // TODO I'm not sure Sentry's JS client supports captureException anymore.
      Sentry.captureException(
        `Failed to create one or more subscriptions.\nResponses: ${createSubscriptionResponses}\nEmail: ${email}\nStudent First Name: ${studentFirstName}\nStudent Last Name: ${studentLastName}\nTicket Locks Subscribed To (grouped by course): ${ticketLocksSubscribedToGroupedByCourse}`
      );
    } else {
      // If all subscriptions failed, then let's show an error message and stop the payment flow.
      throw Error('Failed to create one or more subscriptions. Please try again.');
    }
  }

  // Update the ticket locks in case the user needs a couple
  // more seconds / minutes to complete the purchase.
  const renewedTicketLocks = map(ticketLocks, (ticketLock) => ({
    ...ticketLock,
    dateCreated: DateTime.now(),
  }));
  dispatch(updateTicketLocks(renewedTicketLocks));

  // If all of the requests succeeded, then we need to execute the
  // payment intents created for the initial charges.
  const confirmCardPaymentCalls = map(createSubscriptionResponses, (response) => {
    // Place the payments via Stripe.
    return stripe.confirmCardPayment(response.value.firstWeekPaymentIntent.client_secret, {
      payment_method: createPaymentMethodResponse.paymentMethod.id,
    });
  });
  const cardPaymentResponses = await Promise.allSettled(confirmCardPaymentCalls);

  let paymentFailedErrorMessage;
  const numberOfRejectedCardPayments = reduce(
    cardPaymentResponses,
    (prev, response) => {
      if (response.value?.error) {
        paymentFailedErrorMessage = response.value.error.message;
        return prev + 1;
      }
      return prev;
    },
    0
  );

  // Check for rejected card payment requests.
  if (numberOfRejectedCardPayments > 0) {
    if (
      confirmCardPaymentCalls.length > 1 &&
      confirmCardPaymentCalls.length !== numberOfRejectedCardPayments
    ) {
      // If some card payments failed & some didn't, then we need to send an alert to the site admins.
      // Let's send errors to Sentry so that admins can manually
      // re-create the failed payments & figure out what went wrong.
      // TODO I'm not sure Sentry's JS client supports captureException anymore.
      Sentry.captureException(
        `Failed to confirm one or more card payments.\nResponses: ${createSubscriptionResponses}\nEmail: ${email}\nStudent First Name: ${studentFirstName}\nStudent Last Name: ${studentLastName}\nTicket Locks Subscribed To (grouped by course): ${ticketLocksSubscribedToGroupedByCourse}`
      );
      // Note that this code should really only run in a worst-case scenario (e.g. Stripe
      // completed some of the payments, but failed to complete some other more-or-less identical
      // payments), at which point the admin should just try to re-execute the failed payments manually.
      // This code may run if a user hits their credit limit, for instance, between one purchase and the next.
      throw Error(
        'One or more charges may have failed. Please contact AskMe Learning to make sure all of your payments went through.'
      );
    } else {
      // If all card payments failed, then let's show an error message and stop the payment flow.
      // Let's also cancel all of the subscriptions we just created.
      // (First, we need to think of a secure way to delete subscriptions).
      const cancelSubscriptionCalls = map(createSubscriptionResponses, (response) =>
        deleteSubscriptionOnServer({ scheduleId: response.value.subscriptionSchedule.id })
      );
      // TODO make sure the deletions succeeded and try again if necessary
      // (not likely, but possible).
      await Promise.allSettled(cancelSubscriptionCalls);
      throw Error(
        `Failed to complete the card payment (Reason: ${paymentFailedErrorMessage}). Please try again.`
      );
    }
  }

  return [createPaymentMethodResponse.paymentMethod.id, customer];
};
