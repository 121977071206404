import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import './AskMeTippy.scss';

// A custom-styled tooltip component built on top of Tippy. See the Tippy docs for usage information.
const AskMeTippy = ({ children, theme, ...props }) => {
  return (
    <Tippy theme={theme || 'askme'} {...props}>
      {children}
    </Tippy>
  );
};

export default AskMeTippy;
