import React, { useMemo } from 'react';
import { first, flatMap, map, max, min, round } from 'lodash';
import {
  getDateTimeFromMillisOrISOString,
  printDateRangeString,
  printDigit,
} from '../../../assets/utils/utils';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import Skeleton from 'react-loading-skeleton';
import { ErrorBoundary } from '@sentry/react';
import ErrorBoundaryFallback from '../../common/ErrorBoundaryFallback';
import ModernImage, { getModernImageSrc } from '../../common/ModernImage';
import { courseIsUpcoming } from '../Courses';
import styles from './CourseCard.module.scss';

const CourseCard = ({ course, unpublished = false, className, ...props }) => {
  const courseSessionEventDates = useMemo(() => {
    const courseSessionEvents = flatMap(course?.courseSessions || [], 'courseSessionEvents');
    return map(courseSessionEvents, 'date');
  }, [course?.courseSessions]);

  const startDate = useMemo(() => {
    if (!courseSessionEventDates?.length) {
      return;
    }

    return getDateTimeFromMillisOrISOString(min(courseSessionEventDates));
  }, [courseSessionEventDates]);

  const endDate = useMemo(() => {
    if (!courseSessionEventDates?.length) {
      return;
    }

    return getDateTimeFromMillisOrISOString(max(courseSessionEventDates));
  }, [courseSessionEventDates]);

  const perSessionPrice = useMemo(() => {
    if (course?.courseSessions?.length > 0 && course.courseSessions[0].price) {
      return `$${round(course.courseSessions[0].price)} / session`;
    }
    if (course?.subscriptionProducts?.length > 0) {
      const periodicPrice = round(course.subscriptionProducts[0].price);
      const billingPeriod = course.subscriptionProducts[0].billingPeriod;
      return `$${periodicPrice} / ${billingPeriod}`;
    }
    return null;
  }, [course]);

  // If an admin has forgotten to add a course session, render nothing (instead of crashing
  // later when we try to read key data from the first course session).
  if (course?.courseSessions?.length === 0) return null;

  return (
    <ErrorBoundary fallback={ErrorBoundaryFallback()}>
      <Link
        to={course ? `/courses/${course.id}` : '#'}
        className={`${styles.cardContainer} col-12 clear-link-styling mt-3 mt-xl-4 ${className}`}
        {...props}
      >
        <Row
          className={`${styles.card} ${
            unpublished && styles.unpublished
          } px-3 py-4 shadow align-items-center`}
        >
          <Col xs={12} lg={4}>
            {course?.photoPath ? (
              <ModernImage
                fluid
                src={getModernImageSrc(course.photoPath)}
                alt={course.name}
                className={`d-inline-block w-100`}
              />
            ) : (
              <Skeleton height={160} />
            )}
          </Col>
          <Col xs={12} lg={8}>
            {course?.courseSessions ? (
              <Row>
                <Col xs={12} className="mt-2 mt-lg-0">
                  <div className={`mr-3 mb-1 ${styles.courseName}`}>
                    {course.name}
                    {unpublished && ' (unpublished)'}
                  </div>
                  {/* <FontAwesomeIcon icon={faCalendarAlt} className='mr-3' /> */}
                  <div className={`mb-1 ${styles.courseDatesContainer}`}>
                    {printDateRangeString(startDate, endDate)}
                  </div>
                </Col>

                {/* show the first 4 sessions */}
                {course.courseSessions.slice(0, 2).map((session, i) => (
                  <Col key={`course-card-course-session-${session.id}`} xs={12}>
                    Session {i + 1}: {session.name}
                  </Col>
                ))}

                {/* show the last session, if there are more than 4 sessions */}
                {course.courseSessions.length > 4 ? (
                  <Col xs={12}>
                    {course.courseSessions.length !== 5 ? (
                      <strong className="d-block my-0">...</strong>
                    ) : (
                      ''
                    )}
                    Session {course.courseSessions.length}:{' '}
                    {course.courseSessions[course.courseSessions.length - 1].name}
                  </Col>
                ) : (
                  ''
                )}

                {/* {course?.testimonials?.length > 0 &&
                <Col xs={12} className={`${styles.testimonials} mt-2`}>
                  <Testimonials testimonials={course.testimonials} />
                </Col>
              } */}

                <Col xs={12} className="mt-1">
                  <Row>
                    <Col xs={5} className={`${styles.location} align-self-center`}>
                      <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-3" />
                      <span>
                        {first(course.courseSessions).zoomLink
                          ? 'Zoom'
                          : first(course.courseSessions).location}
                      </span>
                    </Col>
                    <Col xs={7} className="text-right">
                      {/* Only show prices for upcoming courses */}
                      {courseIsUpcoming(endDate) && (
                        <Row>
                          {perSessionPrice && (
                            <Col xs={12} className={styles.sessionPrice}>
                              {perSessionPrice}
                            </Col>
                          )}
                          {course.bundlePrice && (
                            <Col xs={12}>
                              ${round(parseFloat(course.bundlePrice))} for&nbsp;
                              {course.courseSessions.length > 2 &&
                              course.courseSessions.length < 10 ? (
                                <>all {printDigit(course.courseSessions.length)} sessions</>
                              ) : (
                                'bundle'
                              )}
                            </Col>
                          )}
                        </Row>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : (
              <Skeleton count={5} />
            )}
          </Col>
        </Row>
      </Link>
    </ErrorBoundary>
  );
};

export default CourseCard;
