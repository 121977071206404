import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import { find, first, isBoolean, map } from 'lodash';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchCourseSessions,
  fetchCourse,
  setActiveCourse,
} from '../../redux/features/courses/coursesSlice';
import {
  getActiveCourse,
  getCourseSessionsForActiveCourse,
  getFetchCourseError,
} from '../../redux/features/courses/coursesSelector';
import {
  addTicketToCart,
  removeTicketFromCart,
  obtainTicketLocks,
} from '../../redux/features/cart/cartSlice';
import { getTicketsInCart } from '../../redux/features/cart/cartSelector';
import { getUserIsAdmin } from '../../redux/features/user/userSelector';
import { courseSessionEventIsOpen } from '../../assets/utils/utils';
import SessionCard from './course/SessionCard';
import Testimonials from '../common/Testimonials';
import ErrorPage from './ErrorPage';
import { trackCourseEvent } from '../../assets/utils/GoogleAnalytics';
import SubscribeOrBundleButtonGroup from './course/SubscribeOrBundleButtonGroup';
import CheckoutButton from './course/CheckoutButton';
import styles from './Course.module.scss';
import Video from '../common/Video';

export const initiateCheckout = async (
  dispatch,
  history,
  ticketsInCart,
  setIsInitiatingCheckout = null,
  course = null
) => {
  if (!dispatch || !history) {
    throw new Error(
      'Usage error: `initiateCheckout` requires two parameters: `dispatch`, `history`'
    );
  }

  trackCourseEvent('Clicked Checkout', course?.name);
  if (setIsInitiatingCheckout) {
    setIsInitiatingCheckout(true);
  }

  if (!ticketsInCart?.length) {
    // TODO show a pretty error message.
    toast.error('In order to check out, you must have at least one item in your cart.');
    if (setIsInitiatingCheckout) {
      setIsInitiatingCheckout(false);
    }
    return;
  }

  // First, obtain the necessary ticket locks and put them in Redux.
  const response = await dispatch(obtainTicketLocks({ ticketsInCart }));

  // Finally, if we've gotten the ticket locks,
  // let's go to the checkout page!
  if (setIsInitiatingCheckout) {
    setIsInitiatingCheckout(false);
  }
  if (response?.length === ticketsInCart.length) {
    history.push('/checkout');
  }
};

const getDefaultSelectedCourseSessionEvents = (courseSessions, ticketsInCart) => {
  if (courseSessions) {
    return map(courseSessions, (session) => {
      const ticketForTheCurrentSession = find(ticketsInCart, ['courseSessionId', session.id]);

      // If the user has added a ticket for the current session to their cart,
      // then the selected event should be that ticket's event. Otherwise,
      // it should just be the first event.
      if (ticketForTheCurrentSession) {
        return find(session.courseSessionEvents, [
          'id',
          ticketForTheCurrentSession.courseSessionEventId,
        ]);
      } else {
        return first(session.courseSessionEvents);
      }
    });
  }
  return [];
};

const Course = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { activeCourseId } = useParams();
  const userIsAdmin = useSelector(getUserIsAdmin);
  const course = useSelector(getActiveCourse);
  const courseSessions = useSelector(getCourseSessionsForActiveCourse);
  const fetchCourseError = useSelector(getFetchCourseError);
  const ticketsInCart = useSelector(getTicketsInCart);
  const [selectedCourseSessionEvents, setSelectedCourseSessionEvents] = useState(() =>
    getDefaultSelectedCourseSessionEvents(courseSessions, ticketsInCart)
  );
  const [isInitiatingCheckout, setIsInitiatingCheckout] = useState(false);

  // Update the active course ID in redux, and pull the course data from the server.
  useEffect(() => {
    const activeCourseIdInt = activeCourseId ? parseInt(activeCourseId) : undefined;

    if (activeCourseIdInt) {
      dispatch(fetchCourse({ id: activeCourseIdInt }));
      dispatch(fetchCourseSessions({ courseId: activeCourseIdInt }));
    }

    dispatch(setActiveCourse({ id: activeCourseIdInt }));

    return () => {
      dispatch(setActiveCourse({ id: undefined }));
    };
  }, [activeCourseId, dispatch]);

  // Automatically select events that are in the cart.
  // This effect is necessary when the user refreshes the page or
  // revisits the page with events already in their cart.
  useEffect(() => {
    if (courseSessions?.length) {
      // TODO use a more efficient update algorithm here, and check
      // that this new code works if multiple CourseSessionEvents exist.
      setSelectedCourseSessionEvents(
        getDefaultSelectedCourseSessionEvents(courseSessions, ticketsInCart)
      );
    }
  }, [courseSessions, ticketsInCart]);

  // Prevent unauthorized users from unintentionally seeing unpublished courses.
  // We'll show them a 404 page instead.
  if (isBoolean(course?.published) && !course.published && !userIsAdmin) {
    return <ErrorPage />;
  }

  return (
    <>
      <Helmet>
        <title>AskMe Learning{course?.name ? ` | ${course.name}` : ''}</title>
      </Helmet>
      {fetchCourseError ? (
        <ErrorPage />
      ) : (
        <Container className={styles.container}>
          {/* Course Info */}
          <Row className="mt-2 mt-lg-5">
            {/* Title */}
            <Col xs={12}>
              <h1 className="main-header mb-0">{course?.name || <Skeleton />}</h1>
            </Col>

            {/* Video (if present) & Description */}
            <Col xs={12} className="mt-2 mt-lg-4">
              <Row className="align-items-center">
                {course?.youtubeVideoId && (
                  <Col xs={12} lg={5} className="mt-3 mb-2 my-lg-0">
                    <Video
                      youtubeVideoId={course.youtubeVideoId}
                      onPlay={() => {
                        trackCourseEvent('Played Course Video', course.name);
                      }}
                    />
                  </Col>
                )}

                <Col
                  xs={12}
                  lg={course?.youtubeVideoId ? 7 : 12}
                  className={`${styles.courseDescription} mt-3 mt-lg-0 px-4 pl-lg-3`}
                >
                  {course?.description || <Skeleton count={3} />}
                </Col>
              </Row>
            </Col>

            {/* Testimonials (if present) */}
            {course?.testimonials?.length > 0 && (
              <Col xs={12} className={`mt-2 mt-lg-4 mb-3 mb-lg-0 ${styles.testimonials}`}>
                <Testimonials testimonials={course.testimonials} />
              </Col>
            )}
          </Row>

          {/* Session Cards */}
          <Row>
            {/* TODO disable the `Add to cart` buttons within the SessionCards when
              the user clicks the `Checkout` button.
            */}
            {courseSessions?.length ? (
              <>
                {courseSessions.map((session, i) => (
                  <SessionCard
                    key={`course-session-card-${session?.id}`}
                    compact={courseSessions.length > 4}
                    extraWide={courseSessions.length === 2 || courseSessions.length === 4}
                    session={session}
                    selectedEvent={selectedCourseSessionEvents[i]}
                    onEventSelected={(event) => {
                      const ticketForCurrentCourseSession = find(ticketsInCart, [
                        'courseSessionId',
                        event.courseSession,
                      ]);
                      // If the user has added an event for this session to their cart,
                      // change the event in their cart to the one that they are selecting now.
                      if (ticketForCurrentCourseSession) {
                        dispatch(
                          removeTicketFromCart({
                            courseSessionEventId:
                              ticketForCurrentCourseSession.courseSessionEventId,
                          })
                        );
                        if (courseSessionEventIsOpen(event)) {
                          dispatch(
                            addTicketToCart({
                              courseId: course.id,
                              courseSessionId: event.courseSession,
                              courseSessionEventId: event.id,
                            })
                          );
                        }
                      }

                      // Update the component state with the newly selected event.
                      const newSelectedCourseSessionEvents = [...selectedCourseSessionEvents];
                      newSelectedCourseSessionEvents[i] = event;
                      setSelectedCourseSessionEvents(newSelectedCourseSessionEvents);
                    }}
                  />
                ))}
              </>
            ) : (
              <>
                {/* Blank SessionCards will show loading skeletons */}
                {/* TODO in the future, base the number of session cards here on the number
                    of session cards already loaded within the course object when available*/}
                <SessionCard />
                <SessionCard />
                <SessionCard />
              </>
            )}
          </Row>

          {/* Subscription, Bundle, and Checkout buttons */}
          <Row>
            <SubscribeOrBundleButtonGroup
              course={course}
              courseSessions={courseSessions}
              selectedCourseSessionEvents={selectedCourseSessionEvents}
              btnIsDisabled={isInitiatingCheckout}
            />
            <CheckoutButton
              subscriptionButtonIsVisible={course?.subscriptionProducts?.length > 0}
              initiateCheckout={() => {
                initiateCheckout(dispatch, history, ticketsInCart, setIsInitiatingCheckout, course);
              }}
              buttonIsDisabled={isInitiatingCheckout}
            />
          </Row>
        </Container>
      )}
    </>
  );
};

export default Course;
