import { createSlice } from '@reduxjs/toolkit';
import { apiActionFactory } from '../../utils';
import {
  fetchCourseFromServer,
  fetchCoursesFromServer,
  fetchCourseSessionsFromServer,
  fetchPostCheckoutQuestionsFromServer,
  fetchMyCoursesFromServer,
  fetchMyCourseSessionsFromServer
} from '../../../api/courses/CoursesAPI';
import { filter, find, map, reject, sortBy } from 'lodash';

const coursesSlice = createSlice({
  name: 'courses',
  initialState: {
    all: [],
    sessions: [],
    postCheckoutQuestions: [],
    mine: [],
    // It's not necessary to put `activeCourseId: undefined` here, I'm just doing it to document what the slice looks like
    activeCourseId: undefined,
  },
  reducers: {
    fetchCourses(state, action) {
      state.fetchCoursesError = false;
      const coursesWithSortedCourseSessions = map(action.payload || [], (course) => {
        const sortedCourseSessions = sortBy(course.courseSessions, 'displayOrder');
        return { ...course, courseSessions: sortedCourseSessions };
      });
      state.all = coursesWithSortedCourseSessions;
    },
    fetchCoursesFailure(state) {
      state.fetchCoursesError = true;
    },
    fetchMyCourses(state, action) {
      state.fetchCoursesError = false;
      const myCoursesWithSortedCourseSessions = map(action.payload || [], (course) => {
        const sortedCourseSessions = sortBy(course.courseSessions, 'displayOrder');
        return { ...course, courseSessions: sortedCourseSessions };
      });
      state.mine = myCoursesWithSortedCourseSessions;
    },
    fetchMyCoursesFailure(state) {
      state.fetchCourseError = true;
    },
    fetchCourse(state, action) {
      state.fetchCourseError = false;
      // First, remove the course if it's already here.
      state.all = filter(state.all || [], (course) => course.id !== action.payload.id);

      // Now, add the course we just got from the server.
      state.all.push(action.payload);
    },
    fetchCourseFailure(state) {
      state.fetchCourseError = true;
    },
    fetchCourseSessions(state, action) {
      state.fetchCourseSessionsError = false;
      // First, remove the course sessions for the active course if they're already here.
      state.sessions = filter(
        state.sessions || [],
        (courseSession) => courseSession.course !== action.payload.courseId
      );

      // Now, add the course sessions we just got from the server.
      const newSessions = map(action.payload.data, (courseSession) => {
        // Sort the courseSessionEvents by date.
        return {
          ...courseSession,
          courseSessionEvents: sortBy(courseSession.courseSessionEvents, 'date'),
        };
      });
      state.sessions.push(...newSessions);
    },
    fetchMyCourseSessions(state, action) {
      state.fetchCourseSessionsError = false;
      state.mySessions = action.payload || []
    },
    fetchCourseSessionsFailure(state) {
      state.fetchCourseSessionsError = true;
    },
    fetchMyCourseSessionsFailure(state) {
      state.fetchCourseSessionsError = true;
    },
    fetchPostCheckoutQuestions(state, action) {
      state.fetchPostCheckoutQuestionsError = false;

      // Do nothing if the payload is `null` or an empty array.
      if (!action.payload?.length) return;

      // First, remove all existing questions for the course whose questions we just fetched.
      state.postCheckoutQuestions = reject(state.postCheckoutQuestions || [], (question) =>
        find(action.payload, ['course', question.course])
      );

      // const newQuestions = [...state.postCheckoutQuestions, ...action.payload];

      // Now add all of the questions we just received.
      state.postCheckoutQuestions = sortBy(state.postCheckoutQuestions.concat(action.payload), [
        'course',
        'order',
      ]);
    },
    fetchPostCheckoutQuestionsFailure(state) {
      state.fetchPostCheckoutQuestionsError = true;
    },
    setActiveCourse(state, action) {
      state.activeCourseId = action.payload.id;
    },
    setActiveCourseSessionEvent(state, action) {
      state.activeCourseSessionEventId = action.payload.id;
    },
  },
});

export const fetchCourse = apiActionFactory(
  coursesSlice.actions.fetchCourse,
  fetchCourseFromServer,
  coursesSlice.actions.fetchCourseFailure,
  true
);

export const fetchCourses = apiActionFactory(
  coursesSlice.actions.fetchCourses,
  fetchCoursesFromServer,
  coursesSlice.actions.fetchCoursesFailure,
  true
);

export const fetchMyCourses = apiActionFactory(
  coursesSlice.actions.fetchMyCourses,
  fetchMyCoursesFromServer,
  coursesSlice.actions.fetchMyCoursesFailure,
  true
);

export const fetchCourseSessions = apiActionFactory(
  coursesSlice.actions.fetchCourseSessions,
  fetchCourseSessionsFromServer,
  coursesSlice.actions.fetchCourseSessionsFailure,
  true
);

export const fetchMyCourseSessions = apiActionFactory(
  coursesSlice.actions.fetchMyCourseSessions,
  fetchMyCourseSessionsFromServer,
  coursesSlice.actions.fetchMyCourseSessionsFailure,
  true
);

export const fetchPostCheckoutQuestions = apiActionFactory(
  coursesSlice.actions.fetchPostCheckoutQuestions,
  fetchPostCheckoutQuestionsFromServer,
  coursesSlice.actions.fetchPostCheckoutQuestionsFailure,
  true
);

export const { setActiveCourse, setActiveCourseSessionEvent } = coursesSlice.actions;

export default coursesSlice.reducer;
