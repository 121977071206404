import React from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Container } from 'react-bootstrap';
import './FAQ.scss';
import axios from 'axios';

class FAQEntry extends React.Component {
  render() {
    return (
      <>
        <Row>
          <Col
            xs={12}
            sm={{ span: 10, offset: 1 }}
            md={{ span: 8, offset: 2 }}
            xl={{ span: 6, offset: 3 }}
          >
            <h3>
              <span className="questionDropCap"></span> {this.props.question}
            </h3>
          </Col>
        </Row>
        <Row className="answerRow">
          <Col
            xs={12}
            sm={{ span: 10, offset: 1 }}
            md={{ span: 8, offset: 2 }}
            xl={{ span: 6, offset: 3 }}
            className="answerCol"
          >
            <p style={{ fontSize: '1.2rem' }}>
              <span className="questionDropCap"></span> {this.props.answer}
            </p>
          </Col>
        </Row>
      </>
    );
  }
}

class FAQ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      entries: [],
    };
  }

  componentDidMount() {
    axios.get('/api/faq-entries').then((response) => {
      this.setState({
        entries: response.data,
      });
    });
  }

  render() {
    return (
      <Container fluid id="faq-container" className="min-height-page-container">
        <Helmet>
          <title>AskMe Learning | FAQ</title>
        </Helmet>

        <Row>
          <Col
            xs={12}
            sm={{ span: 10, offset: 1 }}
            md={{ span: 8, offset: 2 }}
            xl={{ span: 6, offset: 3 }}
          >
            <h1>Frequently Asked Questions</h1>
            <hr />
          </Col>
        </Row>

        {this.state.entries.map((entry) => {
          return <FAQEntry question={entry.question} answer={entry.answer}></FAQEntry>;
        })}
      </Container>
    );
  }
}
export default FAQ;
