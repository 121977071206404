import { createSlice } from '@reduxjs/toolkit';
import { apiActionFactory } from '../../utils';
import {
  obtainTicketLocksFromServer,
  releaseTicketLocksFromServer,
} from '../../../api/tickets/TicketsAPI';
import { filter, find, reject } from 'lodash';

const initialState = {
  tickets: [],
  ticketLocks: [],
  obtainTicketLocksError: false,
  checkoutFormData: {},
  // We'll move the tickets from `tickets` to `purchasedTickets` when the user places a successful order.
  purchasedTickets: [],
  postCheckoutQuestionsVerificationHash: undefined,
  appliedCoupons: []
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addTicketToCart(state, action) {
      state.tickets.push({
        courseSessionEventId: action.payload.courseSessionEventId,
        courseSessionId: action.payload.courseSessionId,
        courseId: action.payload.courseId,
        subscription: action.payload.subscription,
      });
    },
    removeTicketFromCart(state, action) {
      state.tickets = filter(
        state.tickets,
        (ticket) => ticket.courseSessionEventId !== action.payload.courseSessionEventId
      );
    },
    clearTickets(state) {
      state.tickets = [];
    },
    updateTicketLocks(state, action) {
      // action.payload should be the list of ticketLocks to update.
      state.ticketLocks = reject(state.ticketLocks, (ticketLock) =>
        find(action.payload, ['id', ticketLock.id])
      );
      state.ticketLocks = state.ticketLocks.concat(action.payload);
    },
    obtainTicketLocks(state, action) {
      state.ticketLocks = action.payload;
    },
    obtainTicketLocksFailure(state) {
      state.obtainTicketLocksError = true;
    },
    releaseTicketLocks(state) {
      state.ticketLocks = []; //TODO: perhaps we want to release only some ticketlocks?
    },
    releaseTicketLocksFailure(state) {
      state.releaseTicketLocksFailure = true;
    },
    setCheckoutFormData(state, action) {
      state.checkoutFormData = action.payload;
    },
    clearCheckoutFormData(state) {
      state.checkoutFormData = {};
    },
    markTicketsInCartAsPurchased(state) {
      state.purchasedTickets = state.tickets;
      state.tickets = [];
      state.ticketLocks = [];
    },
    setPostCheckoutQuestionsVerificationHash(state, action) {
      state.postCheckoutQuestionsVerificationHash =
        action.payload.postCheckoutQuestionsVerificationHash;
    },
    applyCoupon(state, action) {
      let alreadyAdded = false;
      for (let coupon of state.appliedCoupons) {
        if (coupon.code === action.payload.code) alreadyAdded = true;
      }
      if (!alreadyAdded) state.appliedCoupons.push(action.payload);
    },
    clearCoupons(state) {
      state.appliedCoupons = []
    }
  },
});

export const obtainTicketLocks = apiActionFactory(
  cartSlice.actions.obtainTicketLocks,
  obtainTicketLocksFromServer,
  cartSlice.actions.obtainTicketLocksFailure,
  true
);

export const releaseTicketLocks = apiActionFactory(
  cartSlice.actions.releaseTicketLocks,
  releaseTicketLocksFromServer,
  cartSlice.actions.releaseTicketLocksFailure,
  false //TODO: this may be a blocking function in the future, it does nothing rn so set to false
);

export const {
  addTicketToCart,
  removeTicketFromCart,
  clearTickets,
  updateTicketLocks,
  setCheckoutFormData,
  clearCheckoutFormData,
  markTicketsInCartAsPurchased,
  setPostCheckoutQuestionsVerificationHash,
  applyCoupon,
  clearCoupons
} = cartSlice.actions;

export default cartSlice.reducer;
