import { useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { BASE_URL } from '../../assets/constants/constants';
import {
  getActiveCourse,
  getMyCourseSessions
} from '../../redux/features/courses/coursesSelector';
import {
  fetchCourse,
  fetchMyCourseSessions,
  setActiveCourse,
} from '../../redux/features/courses/coursesSlice';
import { futureDateToHumanString } from './DashboardStudent';
import styles from './DashboardStudentCourse.module.scss';
import { capitalize, isArray } from "lodash"
const DashboardStudentCourse = () => {
  const { activeCourseId } = useParams();

  let dispatch = useDispatch();

  const activeCourseIdInt = useMemo(() => {
    return activeCourseId ? parseInt(activeCourseId) : undefined;
  }, [activeCourseId]);
  let course = useSelector(getActiveCourse);

  //Hits the /api/mySessions route, which (should) only return sessions a student owns a ticket for
  let myCourseSessions = useSelector(getMyCourseSessions);
  myCourseSessions = myCourseSessions?.filter(e => e.course === course?.id || e.course === activeCourseIdInt);
  
  
  useEffect(() => {
    if (activeCourseIdInt) {
      dispatch(fetchCourse({ id: activeCourseIdInt }));
      dispatch(fetchMyCourseSessions());
    }

    dispatch(setActiveCourse({ id: activeCourseIdInt }));

    return () => {
      dispatch(setActiveCourse({ id: undefined }));
    };
  }, [dispatch, activeCourseIdInt]);


  function getLatestEventOfCourseSession(courseSession) {
    let eventList = [...courseSession.courseSessionEvents];
    if(eventList.length === 0) return {};
    let list = eventList.sort(
      (x, y) => Date.parse(x.date) - Date.parse(y.date)
    );
    return list[list.length - 1];
  }


  //From an array of sessions, remove any who started more than 4 hours before now
  //That 4 hours is in place because if you are coming to the dashboard a minute late
  //and are looking for the zoom link, it doesn't make sense to hide that session the second
  //the current time passes the start time. The length field is a string, so 4 hours is an arbitrary choice
  function filterSessionsThatAlreadyHappened(sessionList){
    let now = new Date();

    let sessionsThatHaventHappened = [];
    if (sessionList === [] || !isArray(sessionList)) return [];
    for (let session of sessionList) {
      for (let event of session.courseSessionEvents) {
        let startDate = new Date(event.date);
        let diffInMs = startDate.getTime() - now.getTime();
        //If you are looking at the website, and there has been a session in the last 4 hours,
        //you probably want to see that one
        if (diffInMs > -4 * 1000 * 60 * 60) {
          sessionsThatHaventHappened.push(session);
        }
      }
    }
    sessionsThatHaventHappened = sessionsThatHaventHappened.sort((a, b) => {
      let latestEventA = getLatestEventOfCourseSession(a);
      let latestEventB = getLatestEventOfCourseSession(b);
      return Date.parse(latestEventA.date) - Date.parse(latestEventB.date);
    });

    return sessionsThatHaventHappened;
  }

  // Sorts the course sessions you own a ticket for by the date
  // of each session's latest event (Assuming there's usually 1,
  // but this sorts the sessions chronologically, and ensures all the
  // events have been completed to mark a session as having already happened)
  function getSortedCourseSessions() {
    let now = new Date();

    let sessionsThatHaventHappened = [];
    if (myCourseSessions === [] || !isArray(myCourseSessions)) return [];
    for (let session of myCourseSessions) {
      for (let event of session.courseSessionEvents) {
        let startDate = new Date(event.date);
        let diffInMs = startDate.getTime() - now.getTime();
        //If you are looking at the website, and there has been a session in the last 4 hours,
        //you probably want to see that one
        if (diffInMs > -4 * 1000 * 60 * 60) {
          sessionsThatHaventHappened.push(session);
        }
      }
    }
    sessionsThatHaventHappened = sessionsThatHaventHappened.sort((a, b) => {
      let latestEventA = getLatestEventOfCourseSession(a);
      let latestEventB = getLatestEventOfCourseSession(b);
      return Date.parse(latestEventA.date) - Date.parse(latestEventB.date);
    });

    return sessionsThatHaventHappened;
  }

  let upcomingCourseSessionsSorted = getSortedCourseSessions();


  function getCourseSessionMaterialsLink(courseId, courseSessionId, courseSessionMaterialsId) {
    return `${BASE_URL}/courses/${courseId}/course-sessions/${courseSessionId}/materials?pwd=${courseSessionMaterialsId}`;
  }

  let UpcomingCourseCard = (props) => {
    let upcomingCourse = props.upcomingCourse || {};
    let upcomingSessions = props.upcomingSessions || [{}];
    console.log("using", upcomingSessions);

    if (upcomingSessions.length === 0) return <></>
    return (
      <div className={styles.upcomingSessionWrapper}>
        <h2>Upcoming Session</h2>
        <div className={styles.upcomingSessionCard}>
          <h4>
            <b>{upcomingSessions[0].name}</b>
          </h4>
          <p>
            {capitalize(futureDateToHumanString(
              new Date(upcomingSessions[0].courseSessionEvents[0].date)
            ))}
          </p>
          <hr />

          <div className={styles.zoomLinkWrapper}>
            {upcomingSessions[0]?.zoomLink?.url ? (
              <p>
                <b>Zoom Link: </b>{' '}
                {
                  <a
                    href={upcomingSessions[0]?.zoomLink?.url}
                    target="_blank"
                    rel="noreferrer"
                    style={{ wordWrap: 'break-word' }}
                  >
                    {upcomingSessions[0]?.zoomLink?.url}
                  </a>
                }{' '}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className={styles.materialsWrapper}>
            {upcomingSessions[0].courseSessionMaterials ? (
              upcomingSessions[0].courseSessionMaterials.map((materialId) => {
                return (
                  <p>
                    <b>Materials: </b>{' '}
                    <a
                      href={getCourseSessionMaterialsLink(
                        upcomingCourse.id,
                        upcomingSessions[0].id,
                        materialId
                      )}
                    >
                      Click to view
              </a>
                  </p>
                );
              })
            ) : (
              <></>
            )}
          </div>


          <div className={styles.instructionsWrapper}>
            {upcomingSessions[0].instructions ? (
              <p>
                <b>Instructions: </b> {upcomingSessions[0].instructions}{' '}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className={styles.descriptionWrapper}>
            {upcomingSessions[0].description ? (
              <p>
                <b>Description: </b>
                {upcomingSessions[0].description}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    )
  }


  // Returns an object to pass into future schedule
  // It contains a list of sessions the user owns (as ids)
  // And the list of all courseSession objects *contained within its parent course object*
  // This way, you see the whole schedule (and you only care about name and date,
  // so you can use the courseSessions inside the course object, instead of having full detail)
  // and you can highlight courses that you have a ticket for by its id's presence in the list
  // that is provided by this object
  function getSchedule() {
    let allSessionsForCourse = course?.courseSessions || [];
    allSessionsForCourse = filterSessionsThatAlreadyHappened(allSessionsForCourse);
    let sessionsIOwn = myCourseSessions;
    sessionsIOwn = filterSessionsThatAlreadyHappened(sessionsIOwn);
    return {
      allSessions: allSessionsForCourse,
      ownedIds: sessionsIOwn.map(e => e.id)
    }
  }

  let FutureSchedule = (props) => {
    let allSessions = props.sessionListObject.allSessions;
    let ownedIds = props.sessionListObject.ownedIds;
    return (
      <div className={styles.futureScheduleWrapper}>
        <h2>Session Schedule</h2>
        <div className={styles.futureScheduleCard}>
          {console.log(new Set(allSessions.map(e => e.id)), ownedIds)}
          {(new Set(allSessions.map(e => e.id))).size > ownedIds.length && (
            <p className={styles.weak}>(Sessions that you don't own a ticket for are greyed out)</p>
          )}
          {allSessions.map(a => {
            return <p className={ownedIds.includes(a.id) ? "" : styles.weak}><b>{a.name}</b> - {capitalize(futureDateToHumanString(new Date(a.courseSessionEvents[0].date)))}</p>
          })}

          {allSessions.length === 0 ? (
            <p>There are no upcoming sessions</p>
          ) : <></>}
          
        </div>
      </div>
    )
  }

  if (!course) return <></>
  return (
    <Container className={`min-height-page-container pb-3 ${styles.container}`}>
      <div className={styles.headerRow}>
        <h1><span className={styles.backplate}>{course.name} Dashboard</span></h1>
        <Link to="/dashboard"><span className={styles.backplate}>{"‹ Return"}</span></Link>
      </div>
      <hr />
      <div className={styles.main}>
        <UpcomingCourseCard upcomingCourse={course} upcomingSessions={upcomingCourseSessionsSorted}></UpcomingCourseCard>
        <FutureSchedule upcomingCourse={course} sessionListObject={getSchedule()}></FutureSchedule>
      </div>
    </Container >
  );
};

export default DashboardStudentCourse;
