import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const LoadingPage = () => {
  return (
    <Container fluid id="loading-page-container">
      <Row>
        <Col className="inner-page-container min-height-page-container"></Col>
      </Row>
    </Container>
  );
};

export default LoadingPage;
