import React from 'react';
import { Row, Col, Carousel } from 'react-bootstrap';
import { shuffle } from 'lodash';
import homeStyles from '../Home.module.scss';
import Testimonial from '../../common/Testimonial';
import styles from './TestimonialSquares.module.scss';
import axios from 'axios';

class TestimonialSquares extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonials: [
        {
          id: 0,
          text: "While they may seem hard, these standardized tests aren't all that daunting after prep. You learn that the tests are just big puzzles, and after these Prep Courses, they will be puzzles you'll already have most of the pieces for.",
          authorFirstName: 'Aiden',
          authorLastName: 'Martin',
          authorDescription: 'February 2021 AskMe Student',
        },
      ],
    };
  }

  componentDidMount() {
    axios.get('/api/testimonials').then((response) => {
      let shuffledData = shuffle(response.data);
      let randomTestimonials = [];
      for (let i = 0; i < Math.min(5, shuffledData.length); i++) {
        randomTestimonials[i] = shuffledData[i];
      }

      this.setState({
        testimonials: randomTestimonials,
      });
    });
  }

  render() {
    return (
      <Row as="section" className={`${homeStyles.sectionContainer} ${styles.container}`}>
        <Col xs={12} md={{ span: 10, offset: 1 }}>
          <h2 className={`${homeStyles.sectionHeader} ${styles.header}`}>What students say</h2>
          <Carousel interval={7500} controls={false}>
            {this.state.testimonials.map((testimonial) => (
              <Carousel.Item className={styles.testimonialContainer} key={testimonial.id}>
                <Testimonial
                  testimonial={testimonial}
                  className={styles.testimonialContainer}
                  inline={false}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    );
  }
}

export default TestimonialSquares;
