import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { throttle } from 'lodash';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';
import { trackCourseMaterialsEvent } from '../../../assets/utils/GoogleAnalytics';
import { fetchCourseSessionMaterialsFromServer } from '../../../api/courses/CoursesAPI';
import { ErrorBoundary } from '@sentry/react';
import ErrorBoundaryFallback from '../../common/ErrorBoundaryFallback';
import styles from './DownloadMaterialsForm.module.scss';

const INITIAL_FORM_VALUES =
  process.env.NODE_ENV === 'development' ? { email: 'email@domain.com' } : { email: '' };

const trackTypedEmail = () => trackCourseMaterialsEvent('Typed Email');
const trackTypedEmailThrottled = throttle(trackTypedEmail, 60000, {
  trailing: false,
});

function DownloadMaterialsForm({ courseSessionMaterialsId }) {
  const [redirectInProgress, setRedirectInProgress] = useState(false);

  async function downloadMaterials({ email }) {
    let response;
    try {
      response = await fetchCourseSessionMaterialsFromServer({
        id: courseSessionMaterialsId,
        email,
      });
    } catch (error) {
      toast.error(error.message);
      return;
    }

    if (!response?.materials) {
      toast.info(
        'The materials for this session will be available on March 29. Please check back at that time!'
      );
      // TODO put this message back in after March 29th.
      // toast.info('The materials for this session are not yet available. Please check again later.');
    } else {
      setRedirectInProgress(true);

      setTimeout(() => {
        setRedirectInProgress(false);
        window.location = response.materials;
      }, 250);
    }
  }

  return (
    <ErrorBoundary fallback={ErrorBoundaryFallback()}>
      <Formik
        initialValues={INITIAL_FORM_VALUES}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Please enter a valid email address')
            .required('This field is required'),
        })}
        onSubmit={downloadMaterials}
      >
        {({ errors, touched, isSubmitting, isValidating }) => (
          <Form>
            <h3 className={`${styles.instructions}`}>
              Please enter the email address you used to register for this session.
            </h3>

            <div className="text-center w-100">
              <Field
                name="email"
                type="email"
                id="email-input"
                className={`${styles.emailInput} form-control askme-input`}
                aria-label="Email address"
                disabled={isSubmitting || redirectInProgress}
                onInput={trackTypedEmailThrottled}
              />
              {errors.email && touched.email && (
                <div className="askme-input-error">{errors.email}</div>
              )}
            </div>

            <div className="text-center w-100">
              <button
                type="submit"
                className={`${styles.downloadMaterialsBtn} askme-btn-terciary thick`}
                disabled={isSubmitting || redirectInProgress}
                onClick={() => trackCourseMaterialsEvent('Clicked Download Materials')}
              >
                {(isSubmitting || redirectInProgress) && (
                  <div
                    className={`spinner-border mr-3 ${styles.downloadingMaterialsSpinner}`}
                    role="status"
                  ></div>
                )}
                {isSubmitting || redirectInProgress
                  ? 'Downloading Materials'
                  : 'Download Materials'}
                {!isSubmitting && !redirectInProgress && (
                  <FontAwesomeIcon icon={faLongArrowAltRight} className="ml-3" />
                )}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </ErrorBoundary>
  );
}

export default DownloadMaterialsForm;
