import React from 'react';
import { Row, Col } from 'react-bootstrap';
import styles from './OurStory.module.scss';
import homeStyles from '../Home.module.scss';

function OurStory() {
  return (
    <Row
      as="section"
      id="our-values"
      className={`${homeStyles.sectionContainer} ${styles.container}`}
    >
      <Col xs={12} md={{ span: 10, offset: 1 }}>
        <h2 className={`${homeStyles.sectionHeader} ${styles.header}`}>Our story</h2>
        <Row>
          <Col xs={12} md={{ span: 8, offset: 2 }} xl={{ span: 6, offset: 3 }}>
            <p>
              At AskMe Learning, we're on a mission to make creative, engaging, and personalized
              learning available to everyone. Growing out of its beginnings as the premier provider
              of test prep courses in the central Oklahoma area — and grounded in our expertise in
              K-12 education — AskMe is expanding this summer to include courses in a variety of
              subjects taught by award-winning teachers. Learners of all ages, backgrounds, and
              locations are allowed — and encouraged — to sign up now!
            </p>
          </Col>
          {/* TODO add a picture to the right side of this panel */}
        </Row>
      </Col>
    </Row>
  );
}

export default OurStory;
