// import colors from '../styles/_colors.scss';

// Summaries for the html metadata (i.e. Search Engines may display this text)
// askmeSummary also goes, by default, on the home page.
export const askmeSummary = 'Short, compelling description of what this website does';
export const askmeSummaryLong = 'TODO';

export const BASE_URL =
  process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://askmetutoring.org';
// export const BASE_URL = process.env.NODE_ENV === 'development' ? `https://${process.env.UNIQUE_SERVER_ID}.loca.lt` : 'https://askmetutoring.org';
// export const STRIPE_API_KEY = 'pk_test_51Hhz4FFdEGmCW2Fkxoom2ZPDT2LoMVjQM5Z4QppOC0RVUeeNuhRgYouA2W4qmwYzyMpfUaTEgcieX1XtjwJFhVyC00oAI0oX5c';
// TODO make sure I'm actually pulling these keys from the environment (when I have more time).
export const STRIPE_API_KEY =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_STRIPE_TEST_API_KEY ||
      'pk_test_51Hhz4FFdEGmCW2Fkxoom2ZPDT2LoMVjQM5Z4QppOC0RVUeeNuhRgYouA2W4qmwYzyMpfUaTEgcieX1XtjwJFhVyC00oAI0oX5c'
    : process.env.REACT_APP_STRIPE_API_KEY ||
      'pk_live_51Hhz4FFdEGmCW2FkCuhcgIXigsS6thavW3jxK5hliW7cFbKyEZ8XF42ZzqqbtEq2oOrPamePmoJvWNJlqKBHeXmU00qTUdyPEj';

const REACT_SELECT_THEME_COLORS = {
  primary: '#F36811',
  primary75: '#FC8A44',
  primary50: '#ffbd94',
  primary25: '#ffdac3',
  neutral0: '#f8fafa', // TODO these colors are close to the theme's colors, but they're not exactly the right ones.
  neutral5: '#fcf1e9', // TODO these colors are close to the theme's colors, but they're not exactly the right ones.
  neutral10: '#f9e3d3', // TODO these colors are close to the theme's colors, but they're not exactly the right ones.
  neutral20: '#f7d5bd', // TODO these colors are close to the theme's colors, but they're not exactly the right ones.
  neutral30: '#f4c7a7', // TODO these colors are close to the theme's colors, but they're not exactly the right ones.
  neutral40: '#f1b991', // TODO these colors are close to the theme's colors, but they're not exactly the right ones.
  neutral50: '#eeaa7a', // TODO these colors are close to the theme's colors, but they're not exactly the right ones.
  neutral60: '#eb9c64', // TODO these colors are close to the theme's colors, but they're not exactly the right ones.
  neutral70: '#e98e4e', // TODO these colors are close to the theme's colors, but they're not exactly the right ones.
  neutral80: '#e68038', // TODO these colors are close to the theme's colors, but they're not exactly the right ones.
  neutral90: '#e37222', // TODO these colors are close to the theme's colors, but they're not exactly the right ones.

  // 'primary': colors.themeSecondaryColor,
  // 'primary75': colors.themeSecondaryColorFaint,
  // 'primary50': colors.themeSecondaryColorFaint50,
  // 'primary25': colors.themeSecondaryColorFaint25,
  // 'neutral0': colors.themeSecondaryColor0,
  // 'neutral5': colors.themeSecondaryColor5,
  // 'neutral10': colors.themeSecondaryColor10,
  // 'neutral20': colors.themeSecondaryColor20,
  // 'neutral30': colors.themeSecondaryColor30,
  // 'neutral40': colors.themeSecondaryColor40,
  // 'neutral50': colors.themeSecondaryColor50,
  // 'neutral60': colors.themeSecondaryColor60,
  // 'neutral70': colors.themeSecondaryColor70,
  // 'neutral80': colors.themeSecondaryColor80,
  // 'neutral90': colors.themeSecondaryColor90,
};

export const ASKME_REACT_SELECT_THEME = (theme) => ({
  ...theme,
  borderRadius: 4,
  colors: {
    ...theme.colors,
    ...REACT_SELECT_THEME_COLORS,
  },
});

export const COURSE_SUBJECTS = [
  {
    id: 0,
    name: 'SAT Prep',
    queryString: 'sat',
    icon: 'file-alt',
  },
  {
    id: 1,
    name: 'ACT Prep',
    queryString: 'act',
    icon: 'file-alt',
  },
  {
    id: 3,
    name: 'Biology',
    queryString: 'biology',
    icon: 'frog',
  },
  {
    id: 2,
    name: 'Guitar',
    queryString: 'guitar',
    icon: 'guitar',
  },
  {
    id: 4,
    name: 'Physics',
    queryString: 'physics',
    icon: 'balance-scale',
  },
];
